import styled from 'styled-components';
// import {transitions} from 'polished';

// Colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

// Media
import {media} from '@matthahn/sally-ui/lib/libs/media';

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  height: ${({showResults}) => (showResults ? '80vh' : '56px')};
  margin-top: 10vh;
  max-height: calc(100vh - 30px);
  position: relative;
  overflow: hidden;
  ${'' /* ${transitions('height .2s ease-in-out')}; */}
  ${'' /* background: rgba(250, 250, 250, 0.8);
  backdrop-filter: blur(3px); */}
  background: ${white};
  box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  ${media.s`
    max-width: 100%;
    height: 100vh;
    margin-top: 0px;
    max-height: 100vh;
    box-shadow: unset;
    backdrop-filter: unset;
    border-radius: 0px;
    background: ${white};
  `}
`;

export default Container;
