const getAuthEndpoints = () => {
  try {
    const rawEndpoints = process.env.REACT_APP_AUTH_TOKENS;
    const endpoints = !!rawEndpoints ? JSON.parse(rawEndpoints) : {};
    return Object.entries(endpoints).map(([app, url]) => ({app, url}));
  } catch (error) {
    return [];
  }
};

export default getAuthEndpoints;
