import {REDUX_SET, REDUX_RESET} from './constants';

const DEFAULT_STATE = {
  rental: null,
  onComplete: () => {},
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case REDUX_SET:
      return {
        ...state,
        ...data,
      };

    case REDUX_RESET:
      return {...DEFAULT_STATE};

    default:
      return state;
  }
};
