// components
import Input from '@matthahn/sally-ui/lib/components/Input/Input';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// legacy components
import LoadMoreRow from '../../../components/components/atoms/LoadMoreRow/LoadMoreRow';
import RowLoader from '../../../components/components/atoms/RowLoader/RowLoader';

// local components
import Container from './components/Container';
import Search from './components/Search';

// local data
import columns from './data/columns';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import {phoneNumber} from '@matthahn/sally-fw/lib/type';

const PickDriverModal = ({
  drivers,
  loading,
  more,
  onClose,
  onMore,
  onSearch,
  onSelect,
  search,
  visible,
}) => (
  <Modal title="Select driver" visible={visible} onClose={onClose}>
    {(Content) => (
      <Content padding="none">
        <Container>
          <Search>
            <Input
              value={search}
              onChange={onSearch}
              preIcon="search1"
              placeholder="Search ..."
              size="large"
              flat
            />
          </Search>
          <Table
            columns={columns}
            loading={loading}
            noBorder
            noRadius
            smallNoResultsLabel
          >
            {(TableRow) =>
              [
                ...drivers.map((driver) => (
                  <TableRow key={driver.id} onClick={onSelect(driver)}>
                    {(TableColumn) => [
                      <TableColumn key="name" span={2}>
                        {driver.first_name} {driver.last_name}
                      </TableColumn>,
                      <TableColumn key="phone">
                        {!!driver.phone_number
                          ? phoneNumber(driver.phone_number).format()
                          : '-'}
                      </TableColumn>,
                      <TableColumn key="state">{driver.state}</TableColumn>,
                    ]}
                  </TableRow>
                )),
                drivers.length > 0 && loading && (
                  <TableRow key="loading">
                    {(TableColumn) => (
                      <TableColumn span={4}>
                        <RowLoader />
                      </TableColumn>
                    )}
                  </TableRow>
                ),
                !loading && more && (
                  <TableRow key="more">
                    {(TableColumn) => (
                      <TableColumn span={4} onClick={onMore}>
                        <LoadMoreRow />
                      </TableColumn>
                    )}
                  </TableRow>
                ),
              ].filter((row) => !!row)
            }
          </Table>
        </Container>
      </Content>
    )}
  </Modal>
);

PickDriverModal.propTypes = {
  drivers: PropTypes.array,
  loading: PropTypes.bool,
  more: PropTypes.bool,
  onClose: PropTypes.func,
  onMore: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  search: PropTypes.string,
  visible: PropTypes.bool,
};

export default PickDriverModal;
