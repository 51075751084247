import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const generalDealershipInfoPreparation = async ({
  validate = false,
  ...attributes
}) => {
  const dealership = await prepare(attributes, {
    field: 'api',
    required: validate
      ? [
          'city',
          'garage_address1',
          'garage_city',
          'garage_name',
          'garage_phone_number',
          'garage_state',
          'garage_vehicle_capacity',
          'garage_zipcode',
          'name',
          'phone_number',
          'state',
          'street_address1',
          'zipcode',
        ]
      : [],
  });
  return {
    ...dealership,
    email: dealership.email || '',
    reporting_email: dealership.reporting_email || '',
  };
};

export default generalDealershipInfoPreparation;
