// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch containers
import BranchAccidentInfoStepContainer from '../BranchAccidentInfoStepContainer/BranchAccidentInfoStepContainer';
import BranchBundlesStepContainer from '../BranchBundlesStepContainer/BranchBundlesStepContainer';
import BranchDocumentsStepContainer from '../BranchDocumentsStepContainer/BranchDocumentsStepContainer';
import BranchGasStepContainer from '../BranchGasStepContainer/BranchGasStepContainer';
import BranchGeneralInfoStepContainer from '../BranchGeneralInfoStepContainer/BranchGeneralInfoStepContainer';
import BranchRequirementsStepContainer from '../BranchRequirementsStepContainer/BranchRequirementsStepContainer';
import BranchSelectStepContainer from '../BranchSelectStepContainer/BranchSelectStepContainer';

// branch events
import showBranchEditorEvent from '../../events/showEditor.event.branch';
import showBranchSelectEvent from '../../events/showSelect.event.branch';
import showRentalFeesEditorEvent from '../../events/showRentalFeesEditor.event.branch';
import showWorkingHoursEditorEvent from '../../events/showWorkingHoursEditor.event.branch';

// branch permissions
import canEditBranchNamePermission from '../../permissions/canEditName.permission.branch';

// branch services
import saveChangedBranchAttributesService from '../../services/saveChangedAttributes.service.branch';

// components
import ManualFlow from '@matthahn/sally-ui/lib/components/ManualFlow/ManualFlow';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// insurancePolicy containers
import BranchInsurancePolicyStepContainer from '../../../insurancePolicy/containers/BranchInsurancePolicyStepContainer/BranchInsurancePolicyStepContainer';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// lib
import getTimeZoneFromBranch from '../../../libs/getTimeZoneFromBranch';

// local constants
import DEFAULT_STATE from './constants/defaultState.constant';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

class BranchEditorContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {...DEFAULT_STATE};

  stepChangeValidator = null;

  componentDidMount() {
    this.props.subscribe(showBranchEditorEvent.subscribe(this.show));
  }

  show = () => {
    const {minimized} = this.state;
    this.setState(
      minimized
        ? {visible: true, minimized: false}
        : {...DEFAULT_STATE, visible: true}
    );
  };

  close = (ignoreDisabled = false) => {
    const {visible, disabled} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;

    const state = {visible: false, minimized: false};

    notify({
      id: 'closeBranchCreationModal',
      title: 'Warning',
      icon: undefined,
      content:
        'Are you sure you want to close this window? All progress will be lost.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Close',
        onClick: () => this.setState(state),
      },
    });
  };

  finish = () => {
    this.setState({visible: false, minimized: false});
  };

  minimize = (ignoreDisabled = false) => {
    const {visible, disabled} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;
    this.setState({visible: false, minimized: true});
  };

  setStepChangeValidator = (validator) => {
    this.stepChangeValidator = validator;
  };

  previous = (data = {}) => {
    const {step} = this.state;
    const flows = this.flows();
    const index = flows.findIndex((f) => f.key === step);
    const nextStep = (!index ? flows[0] : flows[index - 1])?.key || null;
    this.onStep({data, step: nextStep});
  };

  next = (data = {}) => {
    const {step} = this.state;
    const flows = this.flows();
    const index = flows.findIndex((f) => f.key === step);
    const nextStep = index + 1 >= flows.length ? step : flows[index + 1].key;
    this.onStep({data, step: nextStep});
  };

  onStep = async ({step, data = {}}) => {
    const {branch} = this.state;
    if (!!data?.branch && !!data?.changedAttributes?.length) {
      this.setState({saving: true});
      try {
        const updatedBranch = await saveChangedBranchAttributesService({
          originalBranch: {...branch},
          updatedBranch: {...data.branch},
          changedAttributes: data.changedAttributes,
        });
        data.branch = updatedBranch;
        data.originalBranch = updatedBranch;
        this.stepChangeValidator = null;
      } catch (error) {
        const {message} = parseError(error);
        alert.error(message);
      }
      data.saving = false;
    }
    this.setState({...data, step});
  };

  setData = (data = {}) => {
    this.setState(data);
  };

  onSelectedStep = async (step) => {
    const {branch} = this.state;
    if (!branch || step === 'branch' || step === this.state.step) return;
    const state = {step};
    if (!!this.stepChangeValidator) {
      const {shouldContinue, data} = await this.stepChangeValidator();
      if (!shouldContinue) return;
      state.data = data;
    }
    this.onStep(state);
  };

  branchSelected = (data) => {
    this.setState({...data, step: 'general'});
  };

  disable = (disabled) => this.setState({disabled});

  flows = () => [
    {key: 'branch', title: 'Branch'},
    {key: 'general', title: 'General'},
    {key: 'insurance', title: 'Insurance'},
    {key: 'gas', title: 'GAS', size: 'large'},
    {key: 'documents', title: 'Documents', size: 'large'},
    {key: 'bundles', title: 'Bundles', size: 'large'},
    {key: 'requirements', title: 'Requirements', size: 'large'},
    {key: 'accidents', title: 'Accident'},
  ];

  content = (Content) => {
    const {visible, branch, originalBranch, step, saving} = this.state;

    if (!branch)
      return (
        <BranchSelectStepContainer
          Container={Content}
          onDisabled={this.disable}
          onNext={this.branchSelected}
          originalBranch={originalBranch}
        />
      );

    if (saving)
      return (
        <Content padding="none">
          <ModalLoader />
        </Content>
      );

    const props = {
      branch,
      Container: Content,
      goingBack: false,
      onBack: this.previous,
      onClose: this.close,
      onDisabled: this.disable,
      onFinish: this.finish,
      onNext: this.next,
      originalBranch,
      setData: this.setData,
      setStepChangeValidator: this.setStepChangeValidator,
      visible,
    };

    switch (step) {
      case 'general':
        return (
          <BranchGeneralInfoStepContainer
            canEditName={canEditBranchNamePermission()}
            {...props}
            onBack={null}
          />
        );
      case 'insurance':
        return <BranchInsurancePolicyStepContainer {...props} />;
      case 'gas':
        return <BranchGasStepContainer {...props} />;
      case 'documents':
        return <BranchDocumentsStepContainer {...props} />;
      case 'bundles':
        return <BranchBundlesStepContainer {...props} />;
      case 'requirements':
        return <BranchRequirementsStepContainer {...props} />;
      case 'accidents':
        return <BranchAccidentInfoStepContainer {...props} onNext={null} />;
      default:
        return <Content>What are you doing here?</Content>;
    }
  };

  selectBranch = () => {
    showBranchSelectEvent.publish();
  };

  openWorkingHourEditor = () => {
    const {branch} = this.state;
    showWorkingHoursEditorEvent.publish({
      object: {...branch},
      objectType: 'branch',
      timeZone: getTimeZoneFromBranch({branch}),
    });
    this.setState({visible: false, minimized: false});
  };

  openRentalFeesEditor = () => {
    const {branch} = this.state;
    showRentalFeesEditorEvent.publish({
      branch: {...branch},
    });
    this.setState({visible: false, minimized: false});
  };

  headerActions = () => {
    const {branch} = this.state;
    return [
      {
        icon: 'sharealt',
        tooltip: 'Copy from branch',
        onClick: this.selectBranch,
      },
      !!branch && {
        icon: 'minus',
        tooltip: 'Minimize',
        onClick: this.minimize,
      },
      !!branch && {
        icon: 'calendar',
        tooltip: 'Operating Hours Editor',
        onClick: this.openWorkingHourEditor,
      },
      !!branch && {
        icon: 'creditcard',
        tooltip: 'Rental Fees Editor',
        onClick: this.openRentalFeesEditor,
      },
    ].filter((action) => !!action);
  };

  render() {
    const {branch, hide, step, visible} = this.state;
    const flows = this.flows();
    const flow = flows.find((f) => f.key === step);
    return (
      <Fragment>
        <ManualFlow
          flows={flows}
          title={
            !!branch?.name ? `Edit ${branch.name}` : 'Select Branch to Edit'
          }
          subtitle={flow?.title || ''}
          visible={visible && !hide}
          onClose={this.close}
          step={step}
          size={flow?.size || 'medium'}
          onStep={this.onSelectedStep}
          headerActions={this.headerActions()}
        >
          {this.content}
        </ManualFlow>
      </Fragment>
    );
  }
}

export default subscriptionHOC(BranchEditorContainer);
