// layout components
import ContractInputField from '../../../../../layout/components/ContractInputField/ContractInputField';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class InputField extends Component {
  static propTypes = {
    required: PropTypes.func,
    onGeneration: PropTypes.func.isRequired,
  };

  state = {
    done: false,
    generating: false,
    value: '',
  };

  componentDidMount() {
    this.mounted = true;
    this.props.onGeneration({
      start: this.onGenerationStart,
      end: this.onGenerationEnd,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onGenerationStart = () => {
    if (!this.mounted) return;
    this.setState({generating: true});
  };

  onGenerationEnd = () => {
    if (!this.mounted) return;
    this.setState({generating: false});
  };

  change = (value) => {
    if (this.state.generating) return;
    const done = !!value.trim();
    this.setState({value});
    this.props.required(done);
  };

  render() {
    const {done, value, generating} = this.state;
    return (
      <ContractInputField
        disabled={done || generating}
        onChange={this.change}
        value={value}
      />
    );
  }
}

export default InputField;
