import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// types
import uppercase from '@matthahn/sally-fw/lib/type/types/uppercase.type';

export default attribute({
  type: TEXT,
  attribute: 'insurance_company_number',
  display: uppercase,
  input: uppercase,
  api: uppercase,
  label: {
    default: 'Insurance Company Number',
    short: 'Insurance Company Number',
  },
});
