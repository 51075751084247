// reducedDeductible lib
import generateReducedDeductibleKey from '../../../lib/generateKey.lib.reducedDeductible';

// types
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';

const convertToOptions = (options) =>
  [...options].map((option) => ({
    value: generateReducedDeductibleKey(option),
    label: `Deductible: $${amountType(
      option.deductible_amount
    ).format()} Rate: $${amountType(option.rate).format()}`,
  }));

export default convertToOptions;
