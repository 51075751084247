// paymentGroup attributes
import name from '@matthahn/sally-fn/lib/paymentGroup/attributes/name.attribute.paymentGroup';
import owner_email from '@matthahn/sally-fn/lib/paymentGroup/attributes/owner_email.attribute.paymentGroup';
import owner_first_name from '@matthahn/sally-fn/lib/paymentGroup/attributes/owner_first_name.attribute.paymentGroup';
import owner_last_name from '@matthahn/sally-fn/lib/paymentGroup/attributes/owner_last_name.attribute.paymentGroup';
import owner_phone_number from '@matthahn/sally-fn/lib/paymentGroup/attributes/owner_phone_number.attribute.paymentGroup';

const generateInitialState = () => ({
  visible: false,
  loading: false,
  name: name(''),
  owner_email: owner_email(''),
  owner_first_name: owner_first_name(''),
  owner_last_name: owner_last_name(''),
  owner_phone_number: owner_phone_number(''),
});

export default generateInitialState;
