import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

const generalBranchInfoPreparation = async ({
  validate = false,
  ...attributes
}) => {
  const branch = await prepare(attributes, {
    field: 'api',
    required: validate
      ? [
          'requires_emissions',
          'requires_fhv',
          'requires_rental_insurance_id',
          'requires_rideshare_inspection',
          'requires_tlc',

          // 'accidents_slack_channel',
          'city',
          'garage_address1',
          'garage_city',
          'garage_name',
          'garage_phone_number',
          'garage_state',
          'garage_vehicle_capacity',
          'garage_zipcode',
          // 'gas_slack_channel',
          'home_station',
          // 'markets_slack_channel',
          'minimum_deposit',
          'minimum_rental_rate',
          'name',
          // 'ops_slack_channel',
          // 'payments_slack_channel',
          'phone_number',
          'state',
          'street_address1',
          'tag',
          // 'tax_rate',
          'timezone_name',
          'toll_service',
          'weekly_safetyscore_budget',
          'zipcode',
        ]
      : [],
  });
  return {
    ...branch,
    email: branch.email || '',
  };
};

export default generalBranchInfoPreparation;
