import React from 'react';
import PropTypes from 'prop-types';

// components
import {
  Avatar,
  Button,
  Column,
  Heading,
  Icon,
  InputTyping,
  Modal,
  Row,
} from '@matthahn/sally-ui';

// local components
import Container from './components/Container';
import Loader from './components/Loader';
import Scroller from './components/Scroller';
import SearchContainer from './components/SearchContainer';
import UserContainer from './components/UserContainer';
import UserHeader from './components/UserHeader';
import UserName from './components/UserName';
import UserNameRow from './components/UserNameRow';
import UserPermissions from './components/UserPermissions';
import UserRow from './components/UserRow';
import UsersList from './components/UsersList';
import UsersListFlex from './components/UsersListFlex';

// permission components
import PermissionModifier from '../PermissionModifier/PermissionModifier';

// permission lib
import getUserName from '../../lib/getUserName.lib.permission';

const PermissionsModal = ({
  availablePermissions,
  loading,
  onChange,
  onClose,
  onSave,
  onSearch,
  onUserSelect,
  permissions,
  permissionTree,
  saving,
  search,
  user,
  users,
  visible,
}) => (
  <Modal
    title="Permission Control"
    icon="lock"
    size={!!user ? 'large' : 'small'}
    visible={visible}
    onClose={onClose}
  >
    {(Content) => (
      <Content padding="none">
        {loading ? (
          <Container>
            <Loader>
              <Icon icon="loading2" spin />
            </Loader>
          </Container>
        ) : (
          <Container>
            <UsersList fullWidth={!user}>
              <SearchContainer>
                <InputTyping
                  value={search}
                  onChange={onSearch}
                  placeholder="Search"
                  preIcon="search1"
                />
              </SearchContainer>
              <Scroller>
                <UsersListFlex>
                  {users.map((availableUser) => (
                    <UserRow
                      key={availableUser.id}
                      onClick={onUserSelect(availableUser)}
                    >
                      <Avatar
                        avatar={availableUser.username}
                        theme={availableUser.id === user?.id ? 'red' : 'grey'}
                        size="large"
                      />
                      <UserNameRow>
                        {availableUser?.username || '-'}
                        <UserName>{getUserName(availableUser)}</UserName>
                      </UserNameRow>
                    </UserRow>
                  ))}
                </UsersListFlex>
              </Scroller>
            </UsersList>
            {!!user && (
              <UserContainer>
                <UserHeader>
                  <Heading size="4">{getUserName(user)}</Heading>
                  <Button loading={saving} onClick={onSave}>
                    Save
                  </Button>
                </UserHeader>
                <Scroller>
                  <UserPermissions>
                    {Object.entries(permissionTree).map(
                      ([permissionGroup, groupPermissions]) => (
                        <Row key={permissionGroup}>
                          <Column>
                            <Row margin>
                              <Column>
                                <Heading size="4">{permissionGroup}</Heading>
                              </Column>
                            </Row>
                            <Row>
                              {groupPermissions.map((permission) => (
                                <Column
                                  size={1 / 2}
                                  margin
                                  key={permission.permission}
                                >
                                  <PermissionModifier
                                    onChange={onChange(permission.permission)}
                                    permission={permission}
                                    value={permissions[permission.permission]}
                                  />
                                </Column>
                              ))}
                            </Row>
                          </Column>
                        </Row>
                      )
                    )}
                  </UserPermissions>
                </Scroller>
              </UserContainer>
            )}
          </Container>
        )}
      </Content>
    )}
  </Modal>
);

PermissionsModal.propTypes = {
  availablePermissions: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onSearch: PropTypes.func,
  onUserSelect: PropTypes.func,
  permissions: PropTypes.object,
  permissionTree: PropTypes.object,
  saving: PropTypes.bool,
  search: PropTypes.string,
  user: PropTypes.object,
  users: PropTypes.array,
  visible: PropTypes.bool,
};

export default PermissionsModal;
