import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import {Link} from 'react-router-dom';
import Logo from '../../../../../layout/components/Logo/Logo';

const StyledLogo = styled(Logo)`
  height: 20px;
  cursor: pointer;
`;

const NavigationLogo = ({url, white}) => (
  <div>
    <Link to={url}>
      <StyledLogo color={white ? 'white' : 'red'} />
    </Link>
  </div>
);

NavigationLogo.propTypes = {
  url: PropTypes.string,
  white: PropTypes.bool,
};

export default NavigationLogo;
