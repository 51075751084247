import {REDUX_SET, REDUX_RESET} from './constants';

export const open = ({
  bundle = null,
  rentals = [],
  vehicles = [],
  drivers = [],
  accidents = [],
  name = null,
  date = null,
} = {}) => ({
  type: REDUX_SET,
  data: {
    visible: true,
    bundle,
    accidents,
    rentals,
    vehicles,
    drivers,
    name,
    date,
  },
});

export const close = () => ({type: REDUX_SET, data: {visible: false}});

export const reset = () => ({type: REDUX_RESET});
