import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkerGrey, red},
} = lib;

const SearchIconContainer = styled.div`
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({loading}) => (loading ? red : darkerGrey)};
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 20px;
  pointer-events: none;
`;

export default SearchIconContainer;
