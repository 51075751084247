import {TOGGLE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'toll_tag_picture_on_launch_required',
  label: {
    default: 'Toll Tag Picture Required in GAS',
    short: 'Toll Tag Picture Required in GAS',
  },
});
