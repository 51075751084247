// Lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// Onboard
import onboard from './onboard.type.gas';

export default {
  type: 'offboard',
  label: 'Returning',
  icon: 'enter',
  color: 'orange',
  state: 'active',
  canCheckout: false,
  referenceGas: ({gas}) => ({
    type: onboard.type,
    rental: fkOrId(gas.rental),
  }),
};
