// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder components
import ChangeOrderActionsModal from '../../components/ChangeOrderActionsModal/ChangeOrderActionsModal';

// changeOrder events
import showChangeOrderActionsEvent from '../../events/showActions.event.changeOrder';
import showSignChangeOrderContractEvent from '../../events/showSignChangeOrderContract.event.changeOrder';

// contract api
import getSignatureRequestByIdApi from '@matthahn/sally-fn/lib/contract/api/getSignatureRequestById.api.contract';
import notifyDriverByCsrIdApi from '@matthahn/sally-fn/lib/contract/api/notifyDriverByCsrId.api.contract';

// driver api
import getDriverByIdApi from '@matthahn/sally-fn/lib/driver/api/getByID.api.driver';
import textTosApi from '@matthahn/sally-fn/lib/driver/api/textTos.api.driver';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class ChangeOrderActionsContainer extends Component {
  static propTypes = {
    latestTos: PropTypes.object,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(showChangeOrderActionsEvent.subscribe(this.show));
  }

  state = {
    changeOrder: null,
    driver: null,
    loading: false,
    sendingText: false,
    sendingTos: false,
    signatureRequest: null,
    visible: false,
  };

  show = async ({changeOrder}) => {
    if (!changeOrder) return;
    this.setState({visible: true, loading: true, changeOrder});
    const [driver, signatureRequest] = await Promise.all([
      this.getDriver(changeOrder?.driver),
      this.getSignatureRequest(changeOrder?.contract_signing_request),
    ]);
    this.setState({loading: false, signatureRequest, driver});
  };

  getSignatureRequest = async (signatureRequestId) => {
    if (!signatureRequestId) return null;
    try {
      const signatureRequest = await getSignatureRequestByIdApi(
        signatureRequestId
      );
      return signatureRequest;
    } catch (error) {
      return null;
    }
  };

  getDriver = async (driverId) => {
    if (!driverId) return null;
    try {
      const driver = await getDriverByIdApi(driverId);
      return driver;
    } catch (error) {
      return null;
    }
  };

  hide = () => {
    const {loading, sendingText, sendingTos} = this.state;
    if (loading || sendingText || sendingTos) return;
    this.setState({visible: false});
  };

  requiresTosAgreement = () => {
    const {latestTos} = this.props;
    const {driver} = this.state;
    return !!latestTos && latestTos?.version !== driver?.current_tos;
  };

  actions = () => {
    const {sendingText, sendingTos} = this.state;
    const requiresTosAgreement = this.requiresTosAgreement();
    return [
      requiresTosAgreement && {
        icon: sendingTos ? 'mdi-loading mdi-spin' : 'mdi-file-certificate',
        label: 'Text TOS',
        onClick: this.sendTos,
        disabled: sendingTos,
      },
      {
        icon: 'mdi-draw',
        label: 'Sign',
        onClick: this.openChangeOrderContract,
        disabled: requiresTosAgreement,
      },
      {
        icon: sendingText ? 'mdi-loading mdi-spin' : 'mdi-message-text',
        label: 'Text to sign',
        onClick: this.sendText,
        disabled: sendingText || requiresTosAgreement || sendingTos,
      },
    ].filter((action) => !!action);
  };

  triggerAction = (action) => () => {
    const {loading, sendingTos} = this.state;
    if (loading || sendingTos || action.disabled) return;
    action.onClick();
  };

  openChangeOrderContract = () => {
    const {changeOrder, signatureRequest} = this.state;
    this.setState({visible: false});
    showSignChangeOrderContractEvent.publish({changeOrder, signatureRequest});
  };

  sendText = async () => {
    const {signatureRequest, sendingText, sendingTos} = this.state;
    if (sendingText || sendingTos) return;
    this.setState({sendingText: true});

    try {
      await notifyDriverByCsrIdApi(signatureRequest.id);
      this.setState({sendingText: false, visible: false});
      alert.success('Message sent');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({sendingText: false});
    }

    this.setState({sendingText: false});
  };

  sendTos = async () => {
    const {driver, sendingTos} = this.state;

    if (sendingTos) return;

    this.setState({sendingTos: true});

    try {
      await textTosApi(driver.id);
      this.setState({sendingTos: false, visible: false});
      alert.success('Message sent');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({sendingTos: false});
    }
  };

  render() {
    const {loading, visible, changeOrder} = this.state;
    return (
      <ChangeOrderActionsModal
        actions={this.actions()}
        loading={loading || !changeOrder}
        onAction={this.triggerAction}
        onClose={this.hide}
        requiresTosAgreement={this.requiresTosAgreement()}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({
  latestTos: state.termsOfService.latestTos,
}))(subscriptionHOC(ChangeOrderActionsContainer));
