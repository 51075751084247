import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// Components
import SpotlightWrapper from '../../components/SpotlightWrapper/SpotlightWrapper';
import Spotlight from '../../components/Spotlight/Spotlight';

// Constants
import DISPLAY_THRESHOLD from '../../constants/displayTreshold.constant.spotlight';

// Events
import focusOnSearchEvt from '../../events/focusOnSearch.event.spotlight';

// Routes
import driverRoute from '../../../components/pages/CustomerEditPage/route';
import vehicleRoute from '../../../components/pages/FleetEditPage/route';

// Lib
import windowScrollBlocker from '@matthahn/sally-ui/lib/libs/windowScrollBlocker';
import wait from '@matthahn/sally-fw/lib/lib/wait';
import filterUntil from '../../../libs/filterUntil';

// Types
import {phoneNumber} from '@matthahn/sally-fw/lib/type';

// spotlight containers
import SpotlightSync from '../SpotlightSync/SpotlightSync';

// spotlight constants
import SPOTLIGHT_RESET_DATE from '../../constants/spotlightResetDate.constant.spotlight';

// spotlight events
import onSearchInputEvent from '../../events/onSearchInput.event.spotlight';

// spotlight storages
import lastSpotlightResetStorage from '../../storage/lastSpotlightReset.storage.spotlight';

class SpotlightContainer extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    driversLoading: PropTypes.bool,
    vehicles: PropTypes.array,
    vehiclesLoading: PropTypes.bool,
    turnovers: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    visible: false,
    search: '',
    showResults: false,
    lastSearch: null,
  };

  drivers = [];
  vehicles = [];

  freeScroll = () => {};

  componentDidMount() {
    this.subscribers = [focusOnSearchEvt.subscribe(this.setVisible)];
    if (this.props.driversLoaded && this.props.vehiclesLoaded)
      this.saveHardResetDate();
  }

  componentDidUpdate(prevProps, prevState) {
    const previousDisplay = this.shouldDisplay(prevState.search);
    const currentDisplay = this.shouldDisplay(this.state.search);
    this.handleWindow(previousDisplay, currentDisplay);
    const entitiesLoaded = ['driversLoaded', 'vehiclesLoaded'].every(
      (prop) => this.props[prop]
    );
    if (entitiesLoaded) this.saveHardResetDate();
  }

  componentWillUnmount() {
    this.subscribers.forEach((unsubscribe) => unsubscribe());
  }

  subscribers = [];

  hardResetDateStored = false;

  saveHardResetDate = () => {
    if (this.hardResetDateStored) return;
    this.hardResetDateStored = true;
    lastSpotlightResetStorage.add(SPOTLIGHT_RESET_DATE());
  };

  setVisible = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false, search: '', showResults: false});
  };

  handleWindow(previousShow, currentShow) {
    if (!previousShow && currentShow) {
      this.freeScroll = windowScrollBlocker.add();
    } else if (previousShow && !currentShow && this.freeScroll) {
      this.freeScroll();
    }
  }

  shouldDisplay = (search = this.state.search) =>
    search.trim().length >= DISPLAY_THRESHOLD;

  onEscape = () => {
    if (!!this.state.search)
      return this.setState({search: '', showResults: false});
    this.clearAndClose();
  };

  onSearch = (search) => {
    this.setState({search}, () => this.searchForData(search));
  };

  searchForData = async (search) => {
    await wait(500);
    if (this.state.search !== search) return;
    if (search?.length > 2) onSearchInputEvent.publish(search);
    if (!this.shouldDisplay()) return this.setState({showResults: false});
    this.setState({showResults: true, lastSearch: new Date()});
  };

  loadDrivers = ({
    drivers = this.props.drivers,
    rawSearch = this.state.search,
  } = {}) => {
    const search = rawSearch.toLowerCase();
    return !this.shouldDisplay()
      ? []
      : filterUntil({
          list: drivers,
          match: (driver) => {
            const {
              created_at,
              first_name,
              id,
              last_name,
              modified_at,
              phone_number,
              ...driverAttributes
            } = driver;
            return [
              `${last_name}_${first_name}_${id}`,
              `${first_name} ${last_name}`,
              `${last_name} ${first_name}`,
              `+1${phone_number}`,
              `${phoneNumber(phone_number).format()}`,
              ...Object.entries(driverAttributes),
            ].some(
              (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
            );
          },
          stopWhen: ({list}) => list.length === 8,
        })
          .sort((a, b) => {
            const nameA = `${a.first_name} ${a.last_name}`;
            const nameB = `${b.first_name} ${b.last_name}`;
            if (nameA > nameB) return 1;
            if (nameA < nameB) return -1;
            return 0;
          })
          .slice(0, 10);
  };

  loadVehicles = ({
    vehicles = this.props.vehicles,
    rawSearch = this.state.search,
  } = {}) => {
    const search = rawSearch.toLowerCase();
    return !this.shouldDisplay()
      ? []
      : filterUntil({
          list: vehicles,
          match: (vehicle) => {
            const {created_at, id, modified_at, ...vehicleAttributes} = vehicle;
            return Object.values(vehicleAttributes).some(
              (attr) => !!attr && `${attr}`.toLowerCase().includes(search)
            );
          },
          stopWhen: ({list}) => list.length === 8,
        })
          .sort((a, b) => {
            if (a.plate > b.plate) return 1;
            if (a.plate < b.plate) return -1;
            return 0;
          })
          .slice(0, 10);
  };

  clearAndClose = () => {
    this.hide();
  };

  driverClick = (driver) => () => {
    this.clearAndClose();
    this.props.history.push(driverRoute(driver.id));
  };

  vehicleClick = (vehicle) => () => {
    this.clearAndClose();
    this.props.history.push(vehicleRoute(vehicle.id));
  };

  render() {
    const {driversLoading, vehiclesLoading} = this.props;
    const {search, showResults, visible} = this.state;
    return (
      <Fragment>
        {visible && (
          <SpotlightWrapper visible={visible}>
            <Spotlight
              drivers={this.loadDrivers()}
              loading={driversLoading || vehiclesLoading}
              onClose={this.hide}
              onDriver={this.driverClick}
              onEscape={this.onEscape}
              onSearch={this.onSearch}
              onVehicle={this.vehicleClick}
              search={search}
              showResults={showResults}
              vehicles={this.loadVehicles()}
              visible={visible}
            />
          </SpotlightWrapper>
        )}
        <SpotlightSync />
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state) => ({...state.spotlight}))(SpotlightContainer)
);
