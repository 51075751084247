import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Api
import updateUserApi from '@matthahn/sally-fn/lib/user/api/update.api.user';

// Actions
import {
  signOut,
  changeBranch as changeBranchAct,
} from '../../../../redux/authorization/actions';
import {latestCustomer} from '../../../../redux/ui/actions';
import {show as showUserSwitchAct} from '../../../containers/organisms/UserSwitchContainer/actions';

import {Navigation} from '../../organisms/Navigation';
import {Profile} from '../../organisms/Profile';
import {ChangePasswordContainer} from '../../../containers/organisms/ChangePasswordContainer';

// branch events
import showBranchEditorEvent from '../../../../branch/events/showEditor.event.branch';
import showBranchGeneratorEvent from '../../../../branch/events/showGenerator.event.branch';

// branch permissions
import branchEditorPermission from '../../../../branch/permissions/editor.permission.branch';

// Containers
// import {PinChangeContainer} from '../../../containers/organisms/PinChangeContainer';

// dealership events
import showDealershipEditorEvent from '../../../../dealership/events/showEditor.event.dealership';
import showDealershipGeneratorEvent from '../../../../dealership/events/showGenerator.event.dealership';

// dealership permissions
import dealershipEditorPermission from '../../../../dealership/permissions/editor.permission.dealership';

// Events
import focusOnSearchEvt from '../../../../spotlight/events/focusOnSearch.event.spotlight';

// Pages
import {page as pageAccident} from '../../../../accident/pages/AccidentPage';

import {
  icon as iconAccidents,
  label as labelAccidents,
  page as pageAccidents,
  route as routeAccidents,
} from '../../../../accident/pages/AccidentsPage';

import {
  icon as iconPaymentGroups,
  label as labelPaymentGroups,
  page as pagePaymentGroups,
  route as routePaymentGroups,
} from '../../../../paymentGroup/pages/PaymentGroupsPage';
import {page as pagePaymentGroup} from '../../../../paymentGroup/pages/PaymentGroupPage';
import paymentGroupPermission from '@matthahn/sally-fn/lib/paymentGroup/permissions/paymentGroup.permission.paymentGroup';

import {
  icon as iconDashboard,
  label as labelDashboard,
  page as pageDashboard,
  route as routeDashboard,
} from '../../../pages/DashboardPage';

import {
  icon as iconFleet,
  label as labelFleet,
  page as pageFleet,
  route as routeFleet,
} from '../../../pages/FleetPage';

import {page as pageFleetEdit} from '../../../pages/FleetEditPage';

import {
  icon as iconCustomers,
  label as labelCustomers,
  page as pageCustomers,
  route as routeCustomers,
} from '../../../pages/CustomersPage';

import {
  icon as iconVehicleTracking,
  label as labelVehicleTracking,
  page as pageVehicleTracking,
  route as routeVehicleTracking,
} from '../../../pages/VehicleTrackingPage';

import {
  icon as iconKibana,
  label as labelKibana,
  page as pageKibana,
  route as routeKibana,
} from '../../../pages/KibanaPage';

import {
  icon as iconGas,
  label as labelGas,
  page as pageGas,
  route as routeGas,
} from '../../../pages/GasPage';

import {
  icon as iconTolls,
  label as labelTolls,
  page as pageTolls,
  route as routeTolls,
} from '../../../pages/TollsPage';

import {
  icon as iconTickets,
  label as labelTickets,
  page as pageTickets,
  route as routeTickets,
} from '../../../pages/TicketsPage';

import {page as pageCustomerEdit} from '../../../pages/CustomerEditPage';

import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// permission events
import showPermissionsEvent from '../../../../permission/events/show.event.permission';

// permission permissions
import canUpdatePermissionsPermission from '../../../../permission/permissions/canUpdatePermissions.permission.permission';

// ticket events
import showTicketSummaryEvent from '../../../../ticket/events/showSummary.event.ticket';

// ticket lib
import countOfUnassignedAndUnchargedTickets from '../../../../ticket/lib/countOfUnassignedAndUnchargedTickets.lib.ticket';

class NavigationContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    dispatch: PropTypes.func,
    noOfImportantTickets: PropTypes.number,
    numberOfTolls: PropTypes.number,
    page: PropTypes.string,
    ticketSummary: PropTypes.object,
    userData: PropTypes.object,
    username: PropTypes.string,
  };

  state = {
    visibleProfileSection: false,
    visiblePinChange: false,
    visibleChangePasswordModal: false,
  };

  showProfileSection = () => this.setState({visibleProfileSection: true});

  hideProfileSection = () => this.setState({visibleProfileSection: false});

  showChangePasswordModal = () =>
    this.setState({
      visibleChangePasswordModal: true,
      visibleProfileSection: false,
    });

  hideChangePasswordModal = () =>
    this.setState({visibleChangePasswordModal: false});

  signOut = () => this.props.dispatch(signOut(false));

  getPages = () =>
    [
      {
        id: pageDashboard,
        icon: iconDashboard,
        url: routeDashboard(),
        page: labelDashboard,
        selected: this.props.page === pageDashboard,
      },
      {
        id: pageFleet,
        icon: iconFleet,
        url: routeFleet(),
        page: labelFleet,
        selected:
          this.props.page === pageFleet || this.props.page === pageFleetEdit,
      },
      {
        id: pageCustomers,
        icon: iconCustomers,
        url: routeCustomers(),
        page: labelCustomers,
        selected:
          this.props.page === pageCustomers ||
          this.props.page === pageCustomerEdit,
      },
      paymentGroupPermission({userData: this.props.userData}) && {
        id: pagePaymentGroups,
        icon: iconPaymentGroups,
        url: routePaymentGroups(),
        page: labelPaymentGroups,
        selected:
          this.props.page === pagePaymentGroups ||
          this.props.page === pagePaymentGroup,
      },
      {
        id: pageVehicleTracking,
        icon: iconVehicleTracking,
        url: routeVehicleTracking(),
        page: labelVehicleTracking,
        selected: this.props.page === pageVehicleTracking,
      },
      {
        id: pageKibana,
        icon: iconKibana,
        url: routeKibana(),
        page: labelKibana,
        selected: this.props.page === pageKibana,
      },
      {
        id: pageGas,
        icon: iconGas,
        url: routeGas(),
        page: labelGas,
        selected: this.props.page === pageGas,
      },
      {
        id: pageTolls,
        icon: iconTolls,
        url: routeTolls(),
        page: labelTolls,
        selected: this.props.page === pageTolls,
        badge: this.props.numberOfTolls,
      },
      {
        id: pageTickets,
        icon: iconTickets,
        url: routeTickets(),
        page: labelTickets,
        selected: this.props.page === pageTickets,
        badge: countOfUnassignedAndUnchargedTickets(this.props.ticketSummary),
      },
      {
        id: pageAccidents,
        icon: iconAccidents,
        url: routeAccidents(),
        page: labelAccidents,
        selected: [pageAccidents, pageAccident].includes(this.props.page),
      },
    ].filter((page) => !!page);

  onPage = (newPage) => {
    const {page, dispatch} = this.props;
    if (page === pageCustomerEdit && newPage.id === pageCustomers) {
      dispatch(latestCustomer());
    }
    if (newPage.id === pageTickets) {
      showTicketSummaryEvent.publish();
    }
  };

  showUserSwitch = () => {
    this.setState({visibleProfileSection: false});
    this.props.dispatch(showUserSwitchAct());
  };

  showPinChange = () =>
    this.setState({visiblePinChange: true, visibleProfileSection: false});

  hidePinChange = () => this.setState({visiblePinChange: false});

  onBranch = async (branchId) => {
    const {userData, dispatch} = this.props;

    if (!userData || branchId === `${fkOrId(userData?.sally_branch)}`) return;

    const previousBranchId = fkOrId(userData?.sally_branch);

    try {
      dispatch(changeBranchAct(branchId));
      await updateUserApi(userData.id, {sally_branch: branchId});
    } catch (error) {
      if (!previousBranchId) return;
      dispatch(changeBranchAct(previousBranchId));
    }
  };

  onSearch = () => focusOnSearchEvt.publish();

  canUpdatePermissions = () => canUpdatePermissionsPermission();

  showPermissions = () => {
    if (!this.canUpdatePermissions()) return;
    this.hideProfileSection();
    showPermissionsEvent.publish();
  };

  actions = () => {
    return [
      branchEditorPermission() && {
        icon: 'sharealt',
        key: 'createBranch',
        label: 'Create Branch',
        onClick: () => showBranchGeneratorEvent.publish(),
      },
      branchEditorPermission() && {
        icon: 'edit',
        key: 'editBranch',
        label: 'Edit Branch',
        onClick: () => showBranchEditorEvent.publish(),
      },
      dealershipEditorPermission() && {
        icon: 'car',
        key: 'createDealership',
        label: 'Create Dealership',
        onClick: () => showDealershipGeneratorEvent.publish(),
      },
      dealershipEditorPermission() && {
        icon: 'edit',
        key: 'editDealership',
        label: 'Edit Dealership',
        onClick: () => showDealershipEditorEvent.publish(),
      },
      this.canUpdatePermissions() && {
        icon: 'lock',
        key: 'permissions',
        label: 'Permission Control',
        onClick: this.showPermissions,
      },
      {
        icon: 'logout',
        key: 'signOut',
        label: 'Sign Out',
        onClick: this.signOut,
      },
    ].filter((action) => !!action);
  };

  render() {
    const {username, userData, branches} = this.props;
    const {
      visibleProfileSection,
      // visiblePinChange,
      visibleChangePasswordModal,
    } = this.state;
    return (
      <Fragment>
        <Navigation
          logoUrl={routeDashboard()}
          avatar={username}
          pages={this.getPages()}
          live={process.env.REACT_APP_ENV === 'production'}
          onAvatarClick={this.showProfileSection}
          onPage={this.onPage}
          onSearch={this.onSearch}
        />
        <Profile
          actions={this.actions()}
          visible={visibleProfileSection}
          branch={fkOrId(userData?.sally_branch)}
          branches={branches}
          username={username}
          onClose={this.hideProfileSection}
          onBranch={this.onBranch}
        />
        <ChangePasswordContainer
          username={username}
          visible={visibleChangePasswordModal}
          onHide={this.hideChangePasswordModal}
        />
        {/* <PinChangeContainer
          visible={visiblePinChange}
          onClose={this.hidePinChange}
        /> */}
      </Fragment>
    );
  }
}

export {NavigationContainer};

export default connect((state) => ({
  branches: state.branch.branches,
  noOfImportantTickets: state.ticket.noOfImportantTickets,
  numberOfTolls: state.toll.numberOfTolls,
  page: state.ui.page,
  ticketSummary: state.ticket.summary,
  userData: state.authorization.userData,
  username: state.authorization.username,
}))(NavigationContainer);
