// Branch
import getBranch from '../../branch/lib/getBranch.lib.branch';

// Gas
import sections from '@matthahn/sally-fn/lib/gas/sections/sections';

export const sectionsObject = ({vehicle = {}, gas = {}, all = false} = {}) =>
  [...sectionsList({vehicle, gas, all})].reduce(
    (combined, current) => ({...combined, [current.key]: {...current}}),
    {}
  );

export const sectionsList = ({vehicle = {}, gas = {}, all = false} = {}) => {
  const branch = getBranch(vehicle.sally_branch);
  const branchTypeSections = branch?.gas_config[gas.type];
  const branchSections = !!branchTypeSections
    ? Array.isArray(branchTypeSections)
      ? branchTypeSections
      : [branchTypeSections]
    : [];
  return [...sections]
    .filter((section) => all || branchSections.includes(section.key))
    .map((section) => {
      const status = section.check({vehicle, gas, branch});
      return {
        ...section,
        status,
      };
    });
};
