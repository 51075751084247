const STATES = [
  {
    value: 'NY',
    label: 'NY - New York',
  },
  {
    value: 'AK',
    label: 'AK - Alaska',
  },
  {
    value: 'AL',
    label: 'AL - Alabama',
  },
  {
    value: 'AR',
    label: 'AR - Arkansas',
  },
  {
    value: 'AS',
    label: 'AS - American Samoa',
  },
  {
    value: 'AZ',
    label: 'AZ - Arizona',
  },
  {
    value: 'CA',
    label: 'CA - California',
  },
  {
    value: 'CO',
    label: 'CO - Colorado',
  },
  {
    value: 'CT',
    label: 'CT - Connecticut',
  },
  {
    value: 'DC',
    label: 'DC - District of Columbia',
  },
  {
    value: 'DE',
    label: 'DE - Delaware',
  },
  {
    value: 'FL',
    label: 'FL - Florida',
  },
  {
    value: 'GA',
    label: 'GA - Georgia',
  },
  {
    value: 'GU',
    label: 'GU - Guam',
  },
  {
    value: 'HI',
    label: 'HI - Hawaii',
  },
  {
    value: 'IA',
    label: 'IA - Iowa',
  },
  {
    value: 'ID',
    label: 'ID - Idaho',
  },
  {
    value: 'IL',
    label: 'IL - Illinois',
  },
  {
    value: 'IN',
    label: 'IN - Indiana',
  },
  {
    value: 'KS',
    label: 'KS - Kansas',
  },
  {
    value: 'KY',
    label: 'KY - Kentucky',
  },
  {
    value: 'LA',
    label: 'LA - Louisiana',
  },
  {
    value: 'MA',
    label: 'MA - Massachusetts',
  },
  {
    value: 'MD',
    label: 'MD - Maryland',
  },
  {
    value: 'ME',
    label: 'ME - Maine',
  },
  {
    value: 'MI',
    label: 'MI - Michigan',
  },
  {
    value: 'MN',
    label: 'MN - Minnesota',
  },
  {
    value: 'MO',
    label: 'MO - Missouri',
  },
  {
    value: 'MS',
    label: 'MS - Mississippi',
  },
  {
    value: 'MT',
    label: 'MT - Montana',
  },
  {
    value: 'NC',
    label: 'NC - North Carolina',
  },
  {
    value: 'ND',
    label: 'ND - North Dakota',
  },
  {
    value: 'NE',
    label: 'NE - Nebraska',
  },
  {
    value: 'NH',
    label: 'NH - New Hampshire',
  },
  {
    value: 'NJ',
    label: 'NJ - New Jersey',
  },
  {
    value: 'NM',
    label: 'NM - New Mexico',
  },
  {
    value: 'NV',
    label: 'NV - Nevada',
  },
  {
    value: 'OH',
    label: 'OH - Ohio',
  },
  {
    value: 'OK',
    label: 'OK - Oklahoma',
  },
  {
    value: 'OR',
    label: 'OR - Oregon',
  },
  {
    value: 'PA',
    label: 'PA - Pennsylvania',
  },
  {
    value: 'PR',
    label: 'PR - Puerto Rico',
  },
  {
    value: 'RI',
    label: 'RI - Rhode Island',
  },
  {
    value: 'SC',
    label: 'SC - South Carolina',
  },
  {
    value: 'SD',
    label: 'SD - South Dakota',
  },
  {
    value: 'TN',
    label: 'TN - Tennessee',
  },
  {
    value: 'TX',
    label: 'TX - Texas',
  },
  {
    value: 'UT',
    label: 'UT - Utah',
  },
  {
    value: 'VA',
    label: 'VA - Virginia',
  },
  {
    value: 'VI',
    label: 'VI - Virgin Islands',
  },
  {
    value: 'VT',
    label: 'VT - Vermont',
  },
  {
    value: 'WA',
    label: 'WA - Washington',
  },
  {
    value: 'WI',
    label: 'WI - Wisconsin',
  },
  {
    value: 'WV',
    label: 'WV - West Virginia',
  },
  {
    value: 'WY',
    label: 'WY - Wyoming',
  },
];

export default STATES;
