// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch components
import BranchGeneralInfoStep from '../../components/BranchGeneralInfoStep/BranchGeneralInfoStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// branch preparations
import generalBranchInfoPreparation from '../../preparations/generalInfo.preparation.branch';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import ATTRIBUTES_TO_COPY from './constants/attributesToCopy.constant';

// local lib
import generateAttributes from './lib/generateAttributes.lib';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class BranchGeneralInfoStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    canEditName: PropTypes.bool,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    ...generateAttributes(),
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      ...generateAttributes(branch),
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = Object.keys(generateAttributes(branch));
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    const preparedBranch = [...ATTRIBUTES_TO_COPY].reduce(
      (combined, attribute) => ({
        ...combined,
        [attribute]: branch?.[attribute],
      }),
      !!this.props.branch ? {...this.props.branch} : {}
    );
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(preparedBranch),
      },
    });
  };

  continue = async () => {
    const {onNext} = this.props;
    const branch = await this.generateBranch(true);
    if (!branch) return;
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = async () => {
    const {onBack} = this.props;
    const branch = await this.generateBranch(false);
    if (!branch) return;
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  generateBranch = async (validate = false) => {
    const {branch} = this.props;
    try {
      const {...attributes} = this.state;
      const preparedBranch = await generalBranchInfoPreparation({
        validate,
        ...attributes,
      });
      const updatedBranch = {...branch, ...preparedBranch};
      updatedBranch.tax_rate =
        Number(branch?.tax_rate || 0) === Number(updatedBranch?.tax_rate || 0)
          ? branch.tax_rate
          : updatedBranch.tax_rate;
      return updatedBranch;
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      return null;
    }
  };

  stepChangeValidator = async () => {
    const branch = await this.generateBranch(true);
    return {
      shouldContinue: !!branch,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = (value, key) => {
    if (!this.changedAttributes.includes(key))
      this.changedAttributes = [...this.changedAttributes, key];
    this.setState({[key]: value});
  };

  render() {
    const {branch, canEditName, Container, onBack} = this.props;
    const {...attributes} = this.state;
    return (
      <BranchGeneralInfoStep
        {...attributes}
        canEditName={canEditName}
        Container={Container}
        editMode={!!branch?.id}
        onBack={!!onBack ? this.back : null}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(BranchGeneralInfoStepContainer);
