import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {white},
} = lib;

// Helper Components
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${white};
  box-shadow: -2px 9px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

class Content extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  handleClickOutside = () => {
    const {visible, onClose} = this.props;
    if (!visible) return;
    onClose();
  };

  render() {
    const {children} = this.props;
    return <Container>{children}</Container>;
  }
}

export {Content};

export default onClickOutside(Content);
