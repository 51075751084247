// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// local components
import Dealership from './components/Dealership';
import Content from './components/Content';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const DealershipSelectStep = ({
  Container,
  continueWithTemplate,
  dealerships,
  loading,
  onNext,
}) =>
  loading ? (
    <Container padding="none">
      <ModalLoader />
    </Container>
  ) : (
    <Container padding="none">
      <Container noBorder>
        <Title>Select dealership to edit</Title>
        <Content>
          {continueWithTemplate && (
            <Dealership onClick={onNext(null, true)} continueWithTemplate>
              <i className="mdi mdi-motion-play-outline" />
              Continue
            </Dealership>
          )}
          {dealerships
            .sort(sortByString({key: 'name', direction: 'asc'}))
            .map((dealership) => (
              <Dealership onClick={onNext(dealership)} key={dealership.id}>
                {dealership.name}
              </Dealership>
            ))}
        </Content>
      </Container>
    </Container>
  );

DealershipSelectStep.propTypes = {
  dealership: PropTypes.object,
  continueWithTemplate: PropTypes.bool,
  Container: PropTypes.any,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
};

export default DealershipSelectStep;
