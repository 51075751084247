import styled from 'styled-components';
// import {transitions} from 'polished';

const SpotlightWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000000000000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
  ${'' /* pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions('visibility .1s ease-in-out, opacity .1s ease-in-out')}; */}
`;

export default SpotlightWrapper;
