const getName = (item) => {
  const name = !!item?.service
    ? !!item?.attribute
      ? `${item?.service?.name} - ${item?.attribute?.name}`
      : item?.service?.name
    : item?.custom_service;
  return name || '-';
};

export default getName;
