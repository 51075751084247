import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Calamp from '../Calamp/Calamp';
import ShortDashcam from '../ShortDashcam/ShortDashcam';
import Section from '../Section/Section';
import Spireon from '../Spireon/Spireon';

class Equipment extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  getSection = (equipment, label) => {
    const {section} = this.props;
    if (!section) return;
    return {
      ...section,
      label,
      status: {
        ...section.status.equipment[equipment],
      },
    };
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        {!!section?.status?.equipment?.calamp && (
          <Section
            section={this.getSection('calamp', 'Calamp')}
            gas={gas}
            noBorder
          >
            <Calamp {...this.props} equipment />
          </Section>
        )}
        <Section
          section={this.getSection('spireon', 'Spireon')}
          gas={gas}
          noBorder={!section?.status?.equipment?.calamp}
        >
          <Spireon {...this.props} equipment />
        </Section>
        <Section
          section={this.getSection('short_dashcam', 'DashCam')}
          gas={gas}
        >
          <ShortDashcam {...this.props} equipment />
        </Section>
      </Section>
    );
  }
}

export default Equipment;
