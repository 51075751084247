// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver api
import getDriverByIdApi from '@matthahn/sally-fn/lib/driver/api/getByID.api.driver';
import updateDriverApi from '@matthahn/sally-fn/lib/driver/api/update.api.driver';

// driver attributes
import disableAppPaymentExtensionsAttribute from '@matthahn/sally-fn/lib/driver/attributes/disable_app_payment_extensions.attribute.driver';

// driver lib
import showAppPaymentExtensionOption from '../../../driver/lib/showAppPaymentExtensionOption.lib.driver';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// math lib
import divide from '@matthahn/sally-fw/lib/lib/divide';
import multiply from '@matthahn/sally-fw/lib/lib/multiply';

// paymentPlan api
import getPaymentPlanBalanceApi from '@matthahn/sally-fn/lib/paymentPlan/api/getBalance.api.paymentPlan';
import savePaymentPlanApi from '@matthahn/sally-fn/lib/paymentPlan/api/save.api.paymentPlan';

// paymentPlan attributes
import paymentPlanDollarAmountAttribute from '../../attributes/payment_plan_dollar_amount.attribute.paymentPlan';
import paymentPlanPercentAmountAttribute from '../../attributes/payment_plan_percent_amount.attribute.paymentPlan';

// paymentPlan components
import PaymentPlanEditorModal from '../../components/PaymentPlanEditorModal/PaymentPlanEditorModal';

// paymentPlan events
import paymentPlanSavedEvent from '../../events/saved.event.paymentPlan';
import showPaymentPlanEditorModal from '../../events/showEditModal.event.paymentPlan';

// paymentPlan preparations
import savePaymentPlanPreparation from '../../preparations/save.preparation.paymentPlan';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class PaymentPlanEditorContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    user: PropTypes.object,
  };

  state = {
    disable_app_payment_extensions: disableAppPaymentExtensionsAttribute(false),
    dollarAmount: false,
    driver: null,
    loading: false,
    payment_plan_dollar_amount: paymentPlanDollarAmountAttribute(''),
    payment_plan_percent_amount: paymentPlanPercentAmountAttribute(''),
    paymentPlanBalance: null,
    saving: false,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showPaymentPlanEditorModal.subscribe(this.show));
  }

  show = async ({driverId}) => {
    this.setState({loading: true, visible: true});

    try {
      const driver = await getDriverByIdApi(driverId);
      const paymentPlanBalance = await this.getPaymentPlanBalance(driver);
      const payPlanPercentAmount =
        (driver?.driverpaymentplan?.payment_plan_percent_amount || 0) * 100;
      this.setState({
        disable_app_payment_extensions: disableAppPaymentExtensionsAttribute(
          driver?.disable_app_payment_extensions || false
        ),
        dollarAmount: !!driver?.driverpaymentplan?.payment_plan_dollar_amount,
        driver,
        loading: false,
        paymentPlanBalance,
        payment_plan_dollar_amount: paymentPlanDollarAmountAttribute(
          driver?.driverpaymentplan?.payment_plan_dollar_amount || ''
        ),
        payment_plan_percent_amount: paymentPlanPercentAmountAttribute(
          payPlanPercentAmount
        ),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({visible: false});
    }
  };

  hide = () => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({visible: false});
  };

  getPaymentPlanBalance = async (driver) => {
    try {
      const paymentPlanBalance = await getPaymentPlanBalanceApi(driver.id);
      return paymentPlanBalance;
    } catch (error) {
      return null;
    }
  };

  change = (value, key) => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({[key]: value});
  };

  changeAppPaymentExtensions = async (value) => {
    const {loading, driver, saving} = this.state;
    if (loading || saving) return;

    const previousValue = this.state.disable_app_payment_extensions;
    const disable_app_payment_extensions = value.api.format();
    this.setState({saving: true, disable_app_payment_extensions: value});

    try {
      const updatedDriver = await updateDriverApi(driver.id, {
        disable_app_payment_extensions,
      });
      this.setState({saving: false, driver: updatedDriver});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({
        saving: false,
        disable_app_payment_extensions: previousValue,
      });
    }
  };

  save = async () => {
    const {user} = this.props;
    const {
      dollarAmount,
      driver,
      loading,
      paymentPlanBalance,
      payment_plan_dollar_amount,
      payment_plan_percent_amount,
      saving,
      visible,
    } = this.state;

    if (loading || saving || !visible) return;

    this.setState({saving: true});

    try {
      const paymentPlanData = await savePaymentPlanPreparation({
        dollarAmount,
        balance: paymentPlanBalance?.payment_plan_balance || 0,
        payment_plan_dollar_amount,
        payment_plan_percent_amount,
      });
      await savePaymentPlanApi(driver.id, {
        ...paymentPlanData,
        driver: driver.id,
        modified_by: user.id,
      });
      const updatedDriver = await getDriverByIdApi(driver.id);
      const updatedPaymentPlanBalance = await this.getPaymentPlanBalance(
        updatedDriver
      );
      paymentPlanSavedEvent.publish({
        driver: updatedDriver,
        paymentPlanBalance: updatedPaymentPlanBalance,
      });
      this.setState({
        driver: updatedDriver,
        paymentPlanBalance: updatedPaymentPlanBalance,
        saving: false,
        visible: false,
      });
      alert.success('Payment Plan Settings Saved');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  estimatedPayment = () => {
    const {paymentPlanBalance, payment_plan_percent_amount} = this.state;
    const netBalance = paymentPlanBalance?.payment_plan_balance || 0;
    const percent = payment_plan_percent_amount.api.format();
    const estimatedPayment =
      netBalance > 0 || percent < 0
        ? percent > 100
          ? netBalance
          : multiply(netBalance, divide(percent, 100))
        : 0;
    return Math.max(estimatedPayment, 25);
  };

  render() {
    const {
      disable_app_payment_extensions,
      dollarAmount,
      driver,
      paymentPlanBalance,
      loading,
      payment_plan_dollar_amount,
      payment_plan_percent_amount,
      saving,
      visible,
    } = this.state;
    return (
      <PaymentPlanEditorModal
        disable_app_payment_extensions={disable_app_payment_extensions}
        dollarAmount={dollarAmount}
        estimatedPayment={this.estimatedPayment()}
        loading={loading || !driver}
        onChange={this.change}
        onChangeAppPaymentExtensions={this.changeAppPaymentExtensions}
        onClose={this.hide}
        onSave={this.save}
        payment_plan_dollar_amount={payment_plan_dollar_amount}
        payment_plan_percent_amount={payment_plan_percent_amount}
        paymentPlanBalance={paymentPlanBalance?.payment_plan_balance || 0}
        saving={saving}
        showAppPaymentExtensionOption={showAppPaymentExtensionOption({
          driver,
          paymentPlanBalance,
        })}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({user: state.authorization.userData}))(
  subscriptionHOC(PaymentPlanEditorContainer)
);
