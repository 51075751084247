// redux store
import store from '../../redux/store';

const getPaymentLinkOptions = ({
  selectedPaymentLink = null,
  paymentLinks = store.getState().ticket.paymentLinks,
} = {}) => {
  const paymentLinkOptions = [...paymentLinks].map(({url}) => ({
    value: url,
    label: url,
  }));
  return !!selectedPaymentLink &&
    ![...paymentLinkOptions].find(({value}) => value === selectedPaymentLink)
    ? [
        {value: selectedPaymentLink, label: selectedPaymentLink},
        ...paymentLinkOptions,
      ]
    : paymentLinkOptions;
};

export default getPaymentLinkOptions;
