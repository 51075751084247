export default [
  'id',
  'created_at',
  'modified_at',
  'svid',
  'vin',
  'name',
  'plate',
  'vehicle_make',
  'vehicle_model',
  'vehicle_year',
  'sally_branch',
  'fhv_license_number',
];
