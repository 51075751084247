import React from 'react';
import PropTypes from 'prop-types';

// components
import {Select} from '@matthahn/sally-ui';

// local handlers
import onChangeHandler from './handlers/onChange.handler';

// local lib
import parseValue from './lib/parseValue.lib';

// react redux
import {connect} from 'react-redux';

const CreditTypePermission = ({label, onChange, value, creditTypes}) => {
  const parsedValue = parseValue(value);
  return (
    <Select
      value={parsedValue}
      options={creditTypes}
      onChange={onChangeHandler({value: parsedValue, onChange})}
    >
      {label}
    </Select>
  );
};

CreditTypePermission.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default connect((state) => ({creditTypes: state.payment.creditTypes}))(
  CreditTypePermission
);
