// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  noOfImportantTickets: 0,

  loadingRequirements: true,
  paymentLinks: [],
  violations: [],

  loadingSummary: true,
  summary: null,
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
