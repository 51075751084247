import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container.Navigation';
import Top from './components/Top.Navigation';
import BottomScroller from './components/BottomScroller.Navigation';
import Bottom from './components/Bottom.Navigation';
import TopRight from './components/TopRight.Navigation';
import Logo from './components/Logo.Navigation';
import Avatar from './components/Avatar.Navigation';
import Search from './components/Search.Navigation';
import Button from './components/Button.Navigation';
import NotLive from './components/NotLive.Navigation';

// documents container
import OcrIndicatorContainer from '../../../../document/containers/OcrIndicatorContainer/OcrIndicatorContainer';

const Navigation = ({
  logoUrl,
  pages,
  avatar,
  live,
  onAvatarClick,
  onPage,
  onSearch,
}) => (
  <Container live={live}>
    <Top>
      <Logo url={logoUrl} white={!live} />
      {!live && <NotLive>Not Live</NotLive>}
      <TopRight>
        <OcrIndicatorContainer />
        <Search red={!live} onClick={onSearch} />
        <Avatar avatar={avatar || ''} onClick={onAvatarClick} />
      </TopRight>
    </Top>
    <BottomScroller>
      <Bottom>
        {pages.map((page) => (
          <Button
            key={`${page.icon}-${page.url}`}
            icon={page.icon}
            url={page.url}
            selected={page.selected}
            badge={page.badge || 0}
            onClick={(e) => onPage(page)}
          >
            {page.page}
          </Button>
        ))}
      </Bottom>
    </BottomScroller>
  </Container>
);

Navigation.propTypes = {
  logoUrl: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      url: PropTypes.string,
      selected: PropTypes.bool,
      page: PropTypes.string,
    })
  ),
  live: PropTypes.bool,
  avatar: PropTypes.string,
  onAvatarClick: PropTypes.func,
  onPage: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Navigation;
