import styled from 'styled-components';

// colors
import {white, grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 70px;
  height: 100%;
  border-style: none solid;
  border-width: 1px;
  border-color: ${({production}) => (!production ? white : grey)};
  position: relative;
  color: ${({production}) => (!production ? white : darkGrey)};
  font-size: 32px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

export default Container;
