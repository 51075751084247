import React from 'react';
import {Switch, Route} from 'react-router-dom';

// Accident
import AccidentPage, {
  route as routeAccident,
} from '../../../accident/pages/AccidentPage';

// Accidents
import AccidentsPage, {
  route as routeAccidents,
} from '../../../accident/pages/AccidentsPage';

// Dashboard
import DashboardPage, {
  route as routeDashboard,
} from '../../pages/DashboardPage';

// Fleet
import FleetPage, {route as routeFleet} from '../../pages/FleetPage';

// FleetEdit
import FleetEditPage, {
  route as routeFleetEdit,
} from '../../pages/FleetEditPage';

// Customers
import CustomersPage, {
  route as routeCustomers,
} from '../../pages/CustomersPage';

// CustomerEdit
import CustomerEditPage, {
  route as routeCustomerEdit,
} from '../../pages/CustomerEditPage';

// VehicleTracking
import VehicleTrackingPage, {
  route as routeVehicleTracking,
} from '../../pages/VehicleTrackingPage';

// Kibana
import KibanaPage, {route as routeKibana} from '../../pages/KibanaPage';

// GasList
import GasListPage, {route as routeGasList} from '../../pages/GasListPage';

// Gas
import GasPage, {route as routeGas} from '../../pages/GasPage';

// Tolls
import TollsPage, {route as routeTolls} from '../../pages/TollsPage';

// PaymentGroups
import PaymentGroupsPage, {
  route as routePaymentGroups,
} from '../../../paymentGroup/pages/PaymentGroupsPage';

// PaymentGroup
import PaymentGroupPage, {
  route as routePaymentGroup,
} from '../../../paymentGroup/pages/PaymentGroupPage';

// Tickets
import TicketsPage, {route as routeTickets} from '../../pages/TicketsPage';

// 404
import Page404 from '../../pages/Page404/';

const Routes = () => (
  <Switch>
    <Route exact path={routeDashboard()} component={DashboardPage} />
    <Route exact path={routeFleet()} component={FleetPage} />
    <Route exact path={routeFleetEdit()} component={FleetEditPage} />
    <Route exact path={routeCustomers()} component={CustomersPage} />
    <Route exact path={routeCustomerEdit()} component={CustomerEditPage} />
    <Route
      exact
      path={routeVehicleTracking()}
      component={VehicleTrackingPage}
    />
    <Route exact path={routeKibana()} component={KibanaPage} />
    <Route exact path={routeGas()} component={GasPage} />
    <Route exact path={routeGasList()} component={GasListPage} />
    <Route exact path={routeTolls()} component={TollsPage} />
    <Route exact path={routeTickets()} component={TicketsPage} />
    <Route exact path={routePaymentGroups()} component={PaymentGroupsPage} />
    <Route path={routePaymentGroup()} component={PaymentGroupPage} />
    <Route exact path={routeAccidents()} component={AccidentsPage} />
    <Route path={routeAccident()} component={AccidentPage} />
    <Route component={Page404} />
  </Switch>
);

export default Routes;
