import styled, {css} from 'styled-components';

// colors
import {
  grey,
  white,
  darkGrey,
  green,
  red,
} from '@matthahn/sally-ui/lib/libs/colors';

// constants
const SIZE = '30px';

// helpers
const theme = ({status}) => {
  const statuses = {
    processing: css`
      color: ${darkGrey};
      background: ${grey};
    `,
    failed: css`
      color: ${white};
      background: ${red};
    `,
    completed: css`
      color: ${white};
      background: ${green};
    `,
  };
  return statuses?.[status] || statuses.processing;
};

const Icon = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
  height: ${SIZE};
  max-height: ${SIZE};
  min-height: ${SIZE};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  ${theme}
`;

export default Icon;
