// components
import Info from '@matthahn/sally-ui/lib/components/Info/Info';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// local components
import Action from './components/Action';
import Container from './components/Container';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ChangeOrderActionsModal = ({
  actions,
  loading,
  onAction,
  onClose,
  requiresTosAgreement,
  visible,
}) => (
  <Modal visible={visible} onClose={onClose} title="Change Order">
    {(Content) =>
      loading ? (
        <Content padding="none">
          <ModalLoader />
        </Content>
      ) : (
        <Content padding="none">
          {requiresTosAgreement && (
            <Info type="warning" flat>
              Driver must agree to the terms of service before the contract can
              be signed.
            </Info>
          )}
          <Container>
            {actions.map((action) => (
              <Action
                key={action.icon}
                disabled={action.disabled}
                onClick={onAction(action)}
              >
                <i className={`mdi ${action.icon}`} />
                {action.label}
              </Action>
            ))}
          </Container>
        </Content>
      )
    }
  </Modal>
);

ChangeOrderActionsModal.propTypes = {
  actions: PropTypes.array,
  loading: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  requiresTosAgreement: PropTypes.bool,
  visible: PropTypes.bool,
};

export default ChangeOrderActionsModal;
