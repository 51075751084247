import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {Info, Row, Column} from '@matthahn/sally-ui';
import Message from './components/Message';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Section extends Component {
  static propTypes = {
    children: PropTypes.node,
    flat: PropTypes.bool,
    gas: PropTypes.object.isRequired,
    noBorder: PropTypes.bool,
    section: PropTypes.object.isRequired,
  };

  wasOverridden = () => {
    const {section, gas} = this.props;
    return !!section.override && !!gas[section.override];
  };

  hasAlerts = () => {
    const {section} = this.props;
    return ['error', 'warning'].includes(section.status.status);
  };

  content = () => {
    const {section, children} = this.props;
    return (
      <Fragment>
        {this.hasAlerts() && (
          <Message>
            <Info type={section.status.status} noIcon flat>
              {section.status.message}
            </Info>
          </Message>
        )}
        {children}
      </Fragment>
    );
  };

  overriddenMessage = () => {
    const {section, gas} = this.props;
    return this.wasOverridden() ? (
      <Row>
        <Column>
          <Info type="warning" noIcon flat>
            Section overridden by {gas[section.override]}
          </Info>
        </Column>
      </Row>
    ) : null;
  };

  render() {
    const {section, flat, noBorder} = this.props;
    const content = this.content();
    const wasOverridden = this.wasOverridden();
    return (
      <GasSectionRow
        title={section.label}
        flat={flat || wasOverridden || this.hasAlerts()}
        noBorder={noBorder}
      >
        {this.overriddenMessage()}
        {!wasOverridden && content}
      </GasSectionRow>
    );
  }
}

export default Section;
