import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {string} from '@matthahn/sally-fw/lib/type';

export default attribute({
  type: SELECT,
  attribute: 'hold_type',
  api: string,
  label: {
    default: 'Hold Type',
    short: 'Type',
  },
  options: ['Reserve For Driver', 'Defleet', 'Compliance', 'Other'],
});
