// branch attributes
import * as branchAttributes from '../../../attributes';

// local constants
import booleanAttributes from '../constants/booleanAttributes.constant';
import textAttributes from '../constants/textAttributes.constant';

const generateAttributesObject = ({attributes, branch, defaultValue}) =>
  [...attributes].reduce(
    (combined, attribute) => ({
      ...combined,
      [attribute]: branchAttributes[attribute](
        branch?.[attribute] || defaultValue
      ),
    }),
    {}
  );

const generateAttributes = (branch = null) => ({
  ...generateAttributesObject({
    attributes: booleanAttributes,
    branch,
    defaultValue: false,
  }),
  ...generateAttributesObject({
    attributes: textAttributes,
    branch,
    defaultValue: '',
  }),
});

export default generateAttributes;
