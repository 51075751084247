import React, {Component} from 'react';

export default (importComponent) => {
  class AsyncComponent extends Component {
    state = {
      Component: null,
    };

    async componentDidMount() {
      const {default: Component} = await importComponent();

      this.setState({
        Component: Component,
      });
    }

    render() {
      const {Component} = this.state;

      return !!Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};
