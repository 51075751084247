import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Signature} from '@matthahn/sally-ui';

class RequiredSignature extends Component {
  static propTypes = {
    required: PropTypes.func.isRequired,
    onGeneration: PropTypes.func.isRequired,
  };

  state = {
    generating: false,
    done: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.onGeneration({
      start: this.onGenerationStart,
      end: this.onGenerationEnd,
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onGenerationStart = () => {
    if (!this.mounted) return;
    this.setState({generating: true});
  };

  onGenerationEnd = () => {
    if (!this.mounted) return;
    this.setState({generating: false});
  };

  sign = (signature) => {
    if (this.state.generating) return;
    const done = !!signature;
    if (!this.mounted) return;
    this.setState({done});
    this.props.required(done);
  };

  render() {
    const {required, direct, left, ...props} = this.props;
    const {generating} = this.state;
    return <Signature image={generating} onSign={this.sign} {...props} />;
  }
}

export default RequiredSignature;
