const columns = [
  {
    key: 'name',
    label: 'Name',
    span: 2,
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'state',
    label: 'State',
  },
];

export default columns;
