// Rental Rate
import currentRentalRate from '@matthahn/sally-fn/lib/rentalRate/libs/currentRentalRate.lib.rentalRate';

// Types
import {amount as amountType} from '@matthahn/sally-fn/lib/types';

export const raw = ({
  rental: {rates = [], reduced_deductible = false} = {},
} = {}) => currentRentalRate(rates, reduced_deductible);

export default (args) => `$${amountType(raw(args)).format()}`;
