// react
import React, {Component} from 'react';

// propTypes
import PropTypes from 'prop-types';

// redux
import {connect} from 'react-redux';

// reducerDeductible actions
import {set as setAct} from '../../redux/actions';

// reducedDeductible api
import listReducedDeductibleOptionsApi from '@matthahn/sally-fn/lib/reducedDeductible/api/listOptions.api.reducedDeductible';

// components
import {FullScreenLoader} from '@matthahn/sally-ui';

// error
import parseError from '../../../error/parseError';

// lib
import {lib} from '@matthahn/sally-ui';

// alerts
const {alert} = lib;

class ReducedDeductibleOptionsFetchContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    initialized: false,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const {
        rates: reducedDeductibleOptions,
      } = await listReducedDeductibleOptionsApi();
      dispatch(setAct({reducedDeductibleOptions}));
      this.setState({initialized: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children} = this.props;
    const {initialized} = this.state;
    return initialized ? children : <FullScreenLoader />;
  }
}

export default connect()(ReducedDeductibleOptionsFetchContainer);
