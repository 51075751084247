// driver api
import listDriversApi from '@matthahn/sally-fn/lib/driver/api/list.api.driver';

// driver components
import PickDriverModal from '../../components/PickDriverModal/PickDriverModal';

// driver events
import driverPickedEvent from '../../events/picked.event.driver';
import showPickDriverEvent from '../../events/showPickDriver.event.driver';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// uuid
import {v4} from 'uuid';

class PickDriverContainer extends Component {
  static propTypes = {subscribe: PropTypes.func};

  static OPTIONS = {
    perPage: 25,
  };

  state = {
    drivers: [],
    loading: false,
    page: 1,
    pages: 1,
    query: {},
    search: '',
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showPickDriverEvent.subscribe(this.show));
  }

  apiId = null;

  show = ({query = {}} = {}) => {
    this.setState({visible: true});
    this.loadDrivers({page: 1, search: '', drivers: [], query});
  };

  hide = () => {
    this.setState({visible: false});
  };

  loadDrivers = async ({
    page = this.state.page,
    search = this.state.search,
    drivers = this.state.drivers,
    query = this.state.query,
  } = {}) => {
    const apiID = v4();
    this.apiID = apiID;

    const reset = page === 1;

    const {perPage} = this.constructor.OPTIONS;

    this.setState({loading: true, drivers, page, search, query});

    const finalQuery = {
      ...query,
      limit: perPage,
      offset: (page - 1) * perPage,
      ordering: '-created_at',
    };
    if (search.trim().length) finalQuery.search = search;

    try {
      const {results, count} = await listDriversApi(finalQuery);
      if (this.apiID !== apiID) return;
      this.setState({
        drivers: reset ? [...results] : [...drivers, ...results],
        loading: false,
        pages: Math.ceil(count / perPage) || 1,
      });
    } catch (error) {
      if (this.apiID !== apiID) return;
      this.setState({loading: false, drivers: [], pages: 1});
    }
  };

  selectDriver = (driver) => () => {
    driverPickedEvent.publish({driver});
    this.setState({visible: false});
  };

  loadMore = () => {
    const {page, pages} = this.state;
    if (page >= pages) return;
    this.loadDrivers({page: page + 1});
  };

  search = (search) => {
    this.loadDrivers({page: 1, search});
  };

  render() {
    const {drivers, loading, page, pages, search, visible} = this.state;
    return (
      <PickDriverModal
        drivers={drivers}
        loading={loading}
        more={!loading && page !== pages}
        onClose={this.hide}
        onMore={this.loadMore}
        onSearch={this.search}
        onSelect={this.selectDriver}
        search={search}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(PickDriverContainer);
