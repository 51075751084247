import styled from 'styled-components';

// colors
import {white, black, red} from '@matthahn/sally-ui/lib/libs/colors';

const Badge = styled.div`
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 6px;
  pointer-events: none;
  border-radius: 50%;
  background: ${({production}) => (production ? red : white)};
  color: ${({production}) => (production ? white : black)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 16px;
  height: 16px;
`;

export default Badge;
