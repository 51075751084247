// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// components
import {FullScreenLoader} from '@matthahn/sally-ui';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// ticket api
import listPaymentLinksApi from '@matthahn/sally-fn/lib/ticket/api/listPaymentLinks.api.ticket';
import listViolationsApi from '@matthahn/sally-fn/lib/ticket/api/listViolations.api.ticket';

// ticket redux actions
import {set as setAct} from '../../redux/actions';

class TicketRequirementsLoaderContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    loadingRequirements: PropTypes.bool,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const [{data: paymentLinks}, {data: violations}] = await Promise.all([
        api(listPaymentLinksApi),
        api(listViolationsApi),
      ]);
      dispatch(
        setAct({
          loadingRequirements: true,
          paymentLinks,
          violations,
        })
      );
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children, loadingRequirements} = this.props;
    return loadingRequirements ? children : <FullScreenLoader />;
  }
}

export default connect((state) => ({
  loadingRequirements: state.ticket.loadingRequirements,
}))(TicketRequirementsLoaderContainer);
