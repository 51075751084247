// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// local components
import Branch from './components/Branch';
import Content from './components/Content';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BranchTemplateStep = ({
  branches,
  continueWithTemplate,
  Container,
  loading,
  onNext,
}) =>
  loading ? (
    <Container padding="none">
      <ModalLoader />
    </Container>
  ) : (
    <Container padding="none">
      <Container noBorder>
        <Title>
          Select branch to copy the settings from or start a new template
        </Title>
        <Content>
          {continueWithTemplate && (
            <Branch onClick={onNext(null, true)} continueWithTemplate>
              <i className="mdi mdi-motion-play-outline" />
              Continue
            </Branch>
          )}
          <Branch onClick={onNext(null)} newTemplate>
            <i className="mdi mdi-plus" />
            New template
          </Branch>
          {branches
            .sort(sortByString({key: 'name', direction: 'asc'}))
            .map((branch) => (
              <Branch onClick={onNext(branch)} key={branch.id}>
                {branch.name}
              </Branch>
            ))}
        </Content>
      </Container>
    </Container>
  );

BranchTemplateStep.propTypes = {
  branch: PropTypes.object,
  continueWithTemplate: PropTypes.bool,
  Container: PropTypes.any,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
};

export default BranchTemplateStep;
