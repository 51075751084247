import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column, Input, Form} from '@matthahn/sally-ui';

// Helpers
const button = (onClick, loading) => [
  {
    label: 'Change',
    onClick,
    loading,
  },
];

const ChangePasswordModal = ({
  visible,
  onSave,
  onHide,
  onChange,
  loading,
  username,
  oldPassword,
  newPassword,
  repeatedPassword,
}) => (
  <Modal
    title="Change Password"
    visible={visible}
    onClose={onHide}
    buttonsRight={button(onSave, loading)}
  >
    {(ModalContainer) => (
      <ModalContainer key="changepasswormodalcontainer">
        <Form onSubmit={onSave} enter>
          <Row margin>
            <Column size={1 / 2}>
              <Input
                type="text"
                value={username}
                onChange={(val) => onChange(val, 'username')}
              >
                Username
              </Input>
            </Column>
            <Column size={1 / 2}>
              <Input
                type="password"
                value={oldPassword}
                onChange={(val) => onChange(val, 'oldPassword')}
              >
                Old Password
              </Input>
            </Column>
          </Row>
          <Row>
            <Column size={1 / 2}>
              <Input
                type="password"
                value={newPassword}
                onChange={(val) => onChange(val, 'newPassword')}
              >
                New Password
              </Input>
            </Column>
            <Column size={1 / 2}>
              <Input
                type="password"
                value={repeatedPassword}
                onChange={(val) => onChange(val, 'repeatedPassword')}
              >
                Repeat New Password
              </Input>
            </Column>
          </Row>
        </Form>
      </ModalContainer>
    )}
  </Modal>
);

ChangePasswordModal.propTypes = {
  visible: PropTypes.bool,
  onSave: PropTypes.func,
  onHide: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  username: PropTypes.string,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  repeatedPassword: PropTypes.string,
};

export default ChangePasswordModal;
