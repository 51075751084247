import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;
  margin-right: auto;
`;

const TBody = styled.tbody``;

const TR = styled.tr`
  height: 0pt;
`;

const TDTop = styled.td`
  border-style: solid;
  padding: 5pt;
  border-color: rgb(0, 0, 0);
  border-width: 1pt 0pt 0pt;
  vertical-align: top;
  width: 234pt;
`;

const TD = styled.td`
  border: 0pt solid rgb(0, 0, 0);
  padding: 5pt;
  vertical-align: top;
  width: 234pt;
`;

const TDBottom = styled.td`
  border-style: solid;
  padding: 5pt;
  border-color: rgb(0, 0, 0);
  border-width: 0pt 0pt 1pt;
  vertical-align: top;
  width: 234pt;
`;

const PLeft = styled.p`
  padding: 0px;
  margin: 0px;
  color: rgb(0, 0, 0);
  font-size: 12pt;
  font-family: calibri;
  line-height: 1.15;
  text-align: left;
`;

const PRight = styled.p`
  padding: 0px;
  margin: 0px;
  color: rgb(0, 0, 0);
  font-size: 12pt;
  font-family: calibri;
  line-height: 1;
  text-align: left;
`;

const SpanLeft = styled.span`
  font-size: 9pt;
  font-family: 'helvetica neue';
  font-weight: 400;
`;

const SpanRight = styled.span`
  color: rgb(0, 0, 0);
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9.5pt;
  font-family: 'helvetica neue';
  font-style: normal;
`;

const RentalDrivers = ({
  driver: {
    first_name,
    last_name,
    address_street1,
    address_street2,
    address_city,
    address_zip,
    address_state,
    phone_number,
    email,
    dmv_license_number,
    fhv_license_number,
  },
}) => (
  <Table>
    <TBody>
      <TR>
        <TDTop colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>FULL LEGAL NAME</SpanLeft>
          </PLeft>
        </TDTop>
        <TDTop colspan="1" rowspan="1">
          <PRight>
            <SpanRight>
              {first_name} {last_name}
            </SpanRight>
          </PRight>
        </TDTop>
      </TR>
      <TR>
        <TD colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>ADDRESS</SpanLeft>
          </PLeft>
        </TD>
        <TD colspan="1" rowspan="1">
          <PRight>
            <SpanRight>
              {address_street1}
              {!!address_street2 && `, ${address_street2}`}
              <br />
              {address_city} {address_state} {address_zip}
            </SpanRight>
          </PRight>
        </TD>
      </TR>
      <TR>
        <TD colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>PHONE</SpanLeft>
          </PLeft>
        </TD>
        <TD colspan="1" rowspan="1">
          <PRight>
            <SpanRight>{phone_number}</SpanRight>
          </PRight>
        </TD>
      </TR>
      <TR>
        <TD colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>EMAIL</SpanLeft>
          </PLeft>
        </TD>
        <TD colspan="1" rowspan="1">
          <PRight>
            <SpanRight>{email}</SpanRight>
          </PRight>
        </TD>
      </TR>
      <TR>
        <TD colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>DMV LICENSE</SpanLeft>
          </PLeft>
        </TD>
        <TD colspan="1" rowspan="1">
          <PRight>
            <SpanRight>{dmv_license_number}</SpanRight>
          </PRight>
        </TD>
      </TR>
      <TR>
        <TDBottom colspan="1" rowspan="1">
          <PLeft>
            <SpanLeft>TLC LICENSE</SpanLeft>
          </PLeft>
        </TDBottom>
        <TDBottom colspan="1" rowspan="1">
          <PRight>
            <SpanRight>{fhv_license_number}</SpanRight>
          </PRight>
        </TDBottom>
      </TR>
    </TBody>
  </Table>
);

RentalDrivers.propTypes = {
  driver: PropTypes.object,
};

export default RentalDrivers;
