import styled from 'styled-components';

// colors
import {black, darkGrey, grey} from '@matthahn/sally-ui/lib/libs/colors';

const Heading = styled.div`
  width: 100%;
  height: 50px;
  max-height: 50px;
  min-height: 50px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  color: ${black};
  border-bottom: 1px solid ${grey};

  & i {
    color: ${darkGrey};
    font-size: 24px;
    cursor: pointer;
    display: block;
  }
`;

export default Heading;
