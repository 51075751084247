const api = async (
  call,
  query = {},
  {current = [], offset = 0, limit = 1000, shouldContinue = () => true} = {}
) => {
  query.offset = offset;
  query.limit = limit;

  try {
    const {results, next} = await call(query);
    const combined = [...current, ...results];

    if (!!next && shouldContinue()) {
      const nextData = api(call, query, {
        current: combined,
        offset: offset + limit,
        limit,
      });
      return nextData;
    }

    return combined;
  } catch (error) {
    return current;
  }
};

export default api;
