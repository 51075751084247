import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// Components
import SelectDriverStep from '../../components/SelectDriverStep/SelectDriverStep';

// spotlight events
import onSearchInputEvent from '../../../spotlight/events/onSearchInput.event.spotlight';

class SelectDriverStepContainer extends Component {
  static propTypes = {
    Container: PropTypes.any,
    visible: PropTypes.bool,
    driver: PropTypes.object,
    rental: PropTypes.object,
    goingBack: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onDisabled: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,

    drivers: PropTypes.array,
    driversLoading: PropTypes.bool,
  };

  state = {
    search: '',
  };

  onSearch = (search) => {
    if (search?.length > 2)
      onSearchInputEvent.publish(search, {domain: ['driver']});
    this.setState({search});
  };

  onDriver = (driver) => () => this.selectDriver(driver);

  selectDriver = (driver) => {
    if (!driver) return alert.warning('Select a driver');
    this.props.onNext({driver});
  };

  next = () => {
    const {driver} = this.props;
    this.selectDriver(driver);
  };

  drivers = () => {
    const {driver, drivers} = this.props;
    const {search} = this.state;
    if (search?.length <= 2 && !driver) return [];
    const formattedSearch = `${search}`.toLocaleLowerCase();
    const filteredDrivers = !formattedSearch.trim().length
      ? drivers
      : [...drivers].filter((driverToFilter) =>
          `${driverToFilter.first_name} ${
            driverToFilter.last_name
          } ${driverToFilter.fhv_license_number ||
            ''} ${driverToFilter.phone_number || ''}`
            .toLowerCase()
            .includes(formattedSearch)
        );
    const driversWithoutSelectedDriver = !!driver
      ? [...filteredDrivers].filter(
          (driverToFilter) => driverToFilter.id !== driver.id
        )
      : filteredDrivers;
    const driverWithSelectedDriver = !!driver
      ? [driver, ...driversWithoutSelectedDriver]
      : driversWithoutSelectedDriver;
    return [...driverWithSelectedDriver]
      .sort((a, b) => {
        const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
        const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      })
      .splice(0, 10);
  };

  render() {
    const {Container, driver, driversLoading} = this.props;
    const {search} = this.state;
    return (
      <SelectDriverStep
        Container={Container}
        driver={driver}
        drivers={this.drivers()}
        loading={driversLoading}
        onDriver={this.onDriver}
        onNext={this.next}
        onSearch={this.onSearch}
        search={search}
      />
    );
  }
}

export default connect((state) => ({
  drivers: state.spotlight.drivers,
  driversLoading: state.spotlight.driversLoading,
}))(SelectDriverStepContainer);
