// dealership containers
import DealershipBranchSelectStepContainer from '../DealershipBranchSelectStepContainer/DealershipBranchSelectStepContainer';
import DealershipCreateStepContainer from '../DealershipCreateStepContainer/DealershipCreateStepContainer';
import DealershipGeneralInfoStepContainer from '../DealershipGeneralInfoStepContainer/DealershipGeneralInfoStepContainer';

// dealership events
import showDealershipGeneratorEvent from '../../events/showGenerator.event.dealership';

// components
import Flow from '@matthahn/sally-ui/lib/components/Flow/Flow';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import DEFAULT_STATE from './constants/defaultState.constant';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class DealershipGeneratorContainer extends Component {
  static propTypes = {
    dealerships: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {...DEFAULT_STATE};

  componentDidMount() {
    this.props.subscribe(showDealershipGeneratorEvent.subscribe(this.show));
  }

  show = () => {
    const {minimized} = this.state;
    this.setState(
      minimized
        ? {visible: true, minimized: false}
        : {...DEFAULT_STATE, visible: true}
    );
  };

  close = (ignoreDisabled = false) => {
    const {visible, disabled, done} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;

    const state = {visible: false, minimized: false};

    if (!done) return this.setState(state);

    notify({
      id: 'closeDealershipCreationModal',
      title: 'Warning',
      icon: undefined,
      content:
        'Are you sure you want to close this window? All progress will be lost.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Close',
        onClick: () => this.setState(state),
      },
    });
  };

  finish = () => {
    this.setState({visible: false, minimized: false});
  };

  minimize = (ignoreDisabled = false) => {
    const {visible, disabled} = this.state;
    if (!visible || (disabled && !ignoreDisabled)) return;
    this.setState({visible: false, minimized: true});
  };

  onStep = (step, ignoreCheck = false) => {
    const {disabled, done, step: currentStep} = this.state;
    const goingBack = step < currentStep;
    if (!ignoreCheck && disabled) return;
    const newDone = step - 1 > done ? step - 1 : done;
    this.setState({step, done: newDone, goingBack});
  };

  onStepReset = (step) => () => this.setState({step, done: step - 1});

  onStepComplete = (step) => (data = {}) => {
    const {disabled} = this.state;
    if (disabled) return;
    this.setState(data);
    this.onStep(step);
  };

  disable = (disabled) => this.setState({disabled});

  flows = () => [
    {title: 'Select Branch'},
    {title: 'General Info'},
    {title: 'Save'},
  ];

  content = (Content) => {
    const {visible, goingBack, dealership, step, done} = this.state;

    const props = {
      dealership,
      Container: Content,
      done,
      goingBack,
      onClose: this.close,
      onDisabled: this.disable,
      onFinish: this.finish,
      visible,
    };

    switch (step) {
      case 1:
        return (
          <DealershipBranchSelectStepContainer
            onNext={this.onStepComplete(2)}
            {...props}
          />
        );
      case 2:
        return (
          <DealershipGeneralInfoStepContainer
            canEditName
            onBack={this.onStepComplete(1)}
            onNext={this.onStepComplete(3)}
            {...props}
          />
        );
      case 3:
        return (
          <DealershipCreateStepContainer
            onBack={this.onStepComplete(2)}
            {...props}
          />
        );
      default:
        return <Content>You even know what you're doing here?</Content>;
    }
  };

  headerActions = () => {
    const {done} = this.state;
    return [
      !!done && {
        icon: 'minus',
        tooltip: 'Minimize',
        onClick: this.minimize,
      },
    ].filter((action) => !!action);
  };

  render() {
    const {visible, step, done, hide} = this.state;
    const flows = this.flows();
    const flow = flows[step - 1];
    return (
      <Flow
        flows={flows}
        title="New Dealership"
        subtitle={flow.title}
        visible={visible && !hide}
        onClose={this.close}
        step={step}
        done={done}
        size={flow.size || 'medium'}
        onStep={() => {}}
        headerActions={this.headerActions()}
      >
        {this.content}
      </Flow>
    );
  }
}

export default subscriptionHOC(DealershipGeneratorContainer);
