import {DATETIME} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {dateTime, apiDateTime} from '@matthahn/sally-fw/lib/type';

export default attribute({
  type: DATETIME,
  attribute: 'end_date',
  display: dateTime,
  input: dateTime,
  api: apiDateTime,
  label: {
    default: 'End Date',
    short: 'End Date',
  },
});
