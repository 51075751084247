const list = [
  {
    bundle: 'reservation',
    name: 'Reservation',
  },
  {
    bundle: 'preBoarding',
    name: 'Pre-Boarding',
  },
  {
    bundle: 'boarding',
    name: 'Boarding',
  },
  {
    bundle: 'infoArea',
    name: 'Info',
  },
  {
    bundle: 'tlc',
    name: 'TLC',
  },
  {
    bundle: 'postTlc',
    name: 'Post-TLC',
  },
  {
    bundle: 'storage',
    name: 'Storage',
  },
];

export default list;
