import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import {Icon} from '@matthahn/sally-ui';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {red},
} = lib;

const Container = styled.div`
  width: 100%;
  color: ${red} !important;
  font-size: ${({size}) => size}px;
  text-align: center;
  padding: ${({space}) => space}px 0px;
`;

const Loader = ({size, space}) => (
  <Container size={size} space={space}>
    <Icon icon="loading2" spin />
  </Container>
);

Loader.propTypes = {
  size: PropTypes.number,
  space: PropTypes.number,
};

Loader.defaultProps = {
  size: 40,
  space: 20,
};

export default Loader;
