// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// ticket attributes
import dueDatetimeAttribute from '@matthahn/sally-fn/lib/ticket/attributes/due_datetime.attribute.ticket';
import fineAmountAttribute from '@matthahn/sally-fn/lib/ticket/attributes/fine_amount.attribute.ticket';
import licensePlateAttribute from '@matthahn/sally-fn/lib/ticket/attributes/license_plate.attribute.ticket';
import violationAttribute from '@matthahn/sally-fn/lib/ticket/attributes/violation.attribute.ticket';
import paymentLinkAttribute from '@matthahn/sally-fn/lib/ticket/attributes/payment_link.attribute.ticket';
import penaltyAmountAttribute from '@matthahn/sally-fn/lib/ticket/attributes/penalty_amount.attribute.ticket';
import processingFeeAttribute from '@matthahn/sally-fn/lib/ticket/attributes/processing_fee.attribute.ticket';
import stateAttribute from '@matthahn/sally-fn/lib/ticket/attributes/state.attribute.ticket';
import summonsNumberAttribute from '@matthahn/sally-fn/lib/ticket/attributes/summons_number.attribute.ticket';
import violationDatetimeAttribute from '@matthahn/sally-fn/lib/ticket/attributes/violation_datetime.attribute.ticket';

// ticket components
import CreateTicketModal from '../../components/CreateTicketModal/CreateTicketModal';

// ticket events
import showTicketCreateModalEvent from '../../events/showCreateModal.event.ticket';
import showTicketUploadModalEvent from '../../events/showUploadModal.event.ticket';

// ticket lib
import getPaymentLinkOptions from '../../lib/getPaymentLinkOptions.lib.ticket';
import getViolationOptions from '../../lib/getViolationOptions.lib.ticket';

// ticket services
import createTicketService from '../../services/create.service.ticket';

class CreateTicketContainer extends Component {
  static propTypes = {
    paymentLinks: PropTypes.array,
    subscribe: PropTypes.func,
    violations: PropTypes.array,
  };

  static DEFAULT_STATE = {
    addNewAfterSaving: false,
    due_datetime: dueDatetimeAttribute(''),
    file: null,
    fine_amount: fineAmountAttribute(''),
    license_plate: licensePlateAttribute(''),
    violation: violationAttribute(''),
    payment_link: paymentLinkAttribute(''),
    penalty_amount: penaltyAmountAttribute(''),
    processing_fee: processingFeeAttribute(''),
    saving: false,
    state: stateAttribute(''),
    summons_number: summonsNumberAttribute(''),
    violation_datetime: violationDatetimeAttribute(''),
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentDidMount() {
    this.props.subscribe(showTicketCreateModalEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  showUploadModal = () => {
    if (this.state.saving) return;
    this.hide();
    showTicketUploadModalEvent.publish();
  };

  save = ({addNewAfterSaving = false}) => async () => {
    const {visible, saving, ...attributes} = this.state;

    if (!visible || saving) return;

    this.setState({saving: true, addNewAfterSaving});

    try {
      await createTicketService({
        ...attributes,
        showTicket: !addNewAfterSaving,
      });
      if (addNewAfterSaving) return this.show();
      this.setState({saving: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  render() {
    const {
      addNewAfterSaving,
      due_datetime,
      file,
      fine_amount,
      license_plate,
      payment_link,
      penalty_amount,
      processing_fee,
      saving,
      state,
      summons_number,
      violation_datetime,
      violation,
      visible,
    } = this.state;
    return (
      <CreateTicketModal
        addNewAfterSaving={addNewAfterSaving}
        due_datetime={due_datetime}
        file={file}
        fine_amount={fine_amount}
        license_plate={license_plate}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        onUpload={this.showUploadModal}
        payment_link={payment_link}
        paymentLinkOptions={getPaymentLinkOptions()}
        penalty_amount={penalty_amount}
        processing_fee={processing_fee}
        saving={saving}
        state={state}
        summons_number={summons_number}
        violation_datetime={violation_datetime}
        violation={violation}
        violationOptions={getViolationOptions()}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({
  paymentLinks: state.ticket.paymentLinks,
  violations: state.ticket.violations,
}))(subscriptionHOC(CreateTicketContainer));
