// document api
import createDocumentApi from '@matthahn/sally-fn/lib/document/api/create.api.document';

// ticket api
import createTicketApi from '@matthahn/sally-fn/lib/ticket/api/create.api.ticket';

// ticket events
import ticketCreatedEvent from '../events/created.event.ticket';

// ticket preparations
import createTicketPreparation from '../preparations/create.preparation.ticket';

const createTicketService = async ({
  due_datetime,
  file,
  fine_amount,
  license_plate,
  payment_link,
  penalty_amount,
  processing_fee,
  showTicket,
  state,
  summons_number,
  violation_datetime,
  violation,
}) => {
  const ticketBody = await createTicketPreparation({
    due_datetime,
    fine_amount,
    license_plate,
    violation,
    payment_link,
    penalty_amount,
    processing_fee,
    state,
    summons_number,
    violation_datetime,
  });
  const ticket = await createTicketApi(ticketBody);

  if (!!file) {
    const formMeta = new FormData();
    const fileName = file.name;

    formMeta.append('document_file', file, fileName);
    formMeta.append('name', fileName);
    formMeta.append('use_ocr', false);
    formMeta.append('ticket', ticket.id);
    formMeta.append('type', 'ticket');
    formMeta.append('extra_data', JSON.stringify({ticket: ticket.id}));

    await createDocumentApi(formMeta, false);
  }

  ticketCreatedEvent.publish(ticket, {showTicket});
  return ticket;
};

export default createTicketService;
