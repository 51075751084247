// date lib
// import {parseISO, isDate, isAfter} from 'date-fns';
// import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// const getActiveMileagePackage = (rental, {now = new Date()} = {}) => {
//   const currentDateTime = isDate(now) ? now : parseISO(now);
//   const mileagePackages = [...(rental?.rental_mileage_packages || [])];
//   const applicableMilagePackage = mileagePackages.filter((mileagePackage) =>
//     isAfter(currentDateTime, parseISO(mileagePackage.effective_date))
//   );
//   if (!applicableMilagePackage.length) return null;

//   const [mileagePackage] = orderByDate(
//     applicableMilagePackage,
//     'effective_date',
//     'desc'
//   );

//   return mileagePackage || null;
// };

const getActiveMileagePackage = (rental) =>
  [...(rental?.rental_mileage_packages || [])].find(
    (mileagePackage) => mileagePackage.active
  ) || null;

export default getActiveMileagePackage;
