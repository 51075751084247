// mileagePackage lib
import generateMileagePackageKey from './generateMileagePackageKey.lib.mileagePackage';

// types
import amountType from '@matthahn/sally-fw/lib/type/types/amount.type';
import formattedIntegerType from '@matthahn/sally-fw/lib/type/types/formattedInteger.type';

const generateMileagePackageOptions = ({mileagePackages, dealership, branch}) =>
  [...mileagePackages]
    .filter((mileagePackage) =>
      !dealership
        ? mileagePackage.sally_branch === branch
        : mileagePackage.sally_dealership === dealership
    )
    .map((mileagePackage) => ({
      value: generateMileagePackageKey(mileagePackage),
      label: `Mileage: ${formattedIntegerType(
        mileagePackage.alloted_mileage
      ).format()} Rate: $${amountType(mileagePackage.rate).format()}`,
    }));

export default generateMileagePackageOptions;
