import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {transitions} from 'polished';

// Components
import {Icon} from '@matthahn/sally-ui';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey, black},
} = lib;

// Helper components
const Container = styled.div`
  cursor: pointer;
  color: ${darkGrey};
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  ${transitions('color .3s ease-in-out')};

  &:hover {
    color: ${black};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 18px !important;
  margin-bottom: 5px;
`;

const ProfileButton = ({icon, children, onClick}) => (
  <Container onClick={onClick}>
    <StyledIcon icon={icon} />
    <br />
    {children}
  </Container>
);

ProfileButton.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default ProfileButton;
