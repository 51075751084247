// Action constants
import {SET} from './constants';

const DEFAULT_STATE = {
  driversLoading: false,
  driversLoaded: false,
  drivers: [],

  vehiclesLoading: false,
  vehiclesLoaded: false,
  vehicles: [],

  loading: false,
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};
