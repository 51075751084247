import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// types
import amount from '@matthahn/sally-fw/lib/type/types/amount.type';
import formattedNumber from '@matthahn/sally-fw/lib/type/types/formattedNumber.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'payment_plan_dollar_amount',
  display: amount,
  input: formattedNumber,
  api: number,
  label: {
    default: 'Dollar Amount',
    short: 'Dollar Amount',
  },
});
