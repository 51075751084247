// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default async ({dollarAmount, balance = 0, ...attributes}) => {
  const parsedData = await prepare(attributes, {
    field: 'api',
    required: [
      dollarAmount
        ? 'payment_plan_dollar_amount'
        : 'payment_plan_percent_amount',
    ],
  });

  const data = {
    ...parsedData,
    [dollarAmount
      ? 'payment_plan_percent_amount'
      : 'payment_plan_dollar_amount']: 0,
  };

  if (
    !dollarAmount &&
    (data.payment_plan_percent_amount > 100 ||
      data.payment_plan_percent_amount < 0)
  )
    throw new Error('Payment plan percent amount must be between 0 and 100');

  if (!dollarAmount)
    data.payment_plan_percent_amount = data.payment_plan_percent_amount / 100;

  return data;
};
