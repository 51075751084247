// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchInsurancePolicyStep = ({
  Container,
  onBack,
  onChange,
  onNext,

  insurance_company_name,
  insurance_company_number,
  policy_effective_date,
  policy_expiration_date,
  policy_number,
}) => (
  <Fragment>
    <Container padding="none">
      <Container padding="none" noBorder>
        <CompactDataRow
          label={policy_number.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={policy_number}
            onChange={onChange}
            size="small"
            flat
          />
        </CompactDataRow>
        <CompactDataRow
          label={policy_effective_date.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={policy_effective_date}
            onChange={onChange}
            size="small"
            flat
          />
        </CompactDataRow>
        <CompactDataRow
          label={policy_expiration_date.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={policy_expiration_date}
            onChange={onChange}
            size="small"
            flat
          />
        </CompactDataRow>
        <CompactDataRow
          label={insurance_company_name.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={insurance_company_name}
            onChange={onChange}
            size="small"
            flat
          />
        </CompactDataRow>
        <CompactDataRow
          label={insurance_company_number.label.default}
          wrapContent
          noPadding
        >
          <AttributeInput
            value={insurance_company_number}
            onChange={onChange}
            size="small"
            flat
          />
        </CompactDataRow>
      </Container>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchInsurancePolicyStep.propTypes = {
  Container: PropTypes.any,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,

  insurance_company_name: PropTypes.object,
  insurance_company_number: PropTypes.object,
  policy_effective_date: PropTypes.object,
  policy_expiration_date: PropTypes.object,
  policy_language: PropTypes.object,
  policy_number: PropTypes.object,
};

export default BranchInsurancePolicyStep;
