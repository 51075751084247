// rentalRate constants
import OVERRIDE_RATE from '../constants/overrideRate.constant.rentalRate';

// rentalRate lib
import getLowestRate from './getLowestRate.lib.rentalRate';

const isRateBelowThreshold = ({
  rate,
  suggestedRate,
  threshold = OVERRIDE_RATE,
}) => rate < getLowestRate({suggestedRate, threshold});

export default isRateBelowThreshold;
