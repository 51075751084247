import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Cleaning extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    const {
      cleaning_wipe_down,
      cleaning_dash_and_console,
      cleaning_mats,
      cleaning_seats,
      cleaning_vacuum_floor_and_back_shelf,
      cleaning_ceiling,
      cleaning_windows,
      cleaning_inside_door_wedge,
      cleaning_rims,
      cleaning_body,
    } = this.state;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Interior" flat noBorder>
          <GasSectionRow title="Wipe Down" noBorder>
            <CheckBox
              size="small"
              value={cleaning_wipe_down}
              onChange={() => {}}
            />
          </GasSectionRow>
          <GasSectionRow title="Dash and console detail">
            <CheckBox
              size="small"
              value={cleaning_dash_and_console}
              onChange={() => {}}
            />
          </GasSectionRow>
          <GasSectionRow title="Mats">
            <CheckBox size="small" value={cleaning_mats} onChange={() => {}} />
          </GasSectionRow>
          <GasSectionRow title="Seats">
            <CheckBox size="small" value={cleaning_seats} onChange={() => {}} />
          </GasSectionRow>
          <GasSectionRow title="Vacuum floor and back shelf">
            <CheckBox
              size="small"
              value={cleaning_vacuum_floor_and_back_shelf}
              onChange={() => {}}
            />
          </GasSectionRow>
          <GasSectionRow title="Headliner">
            <CheckBox
              size="small"
              value={cleaning_ceiling}
              onChange={() => {}}
            />
          </GasSectionRow>
          <GasSectionRow title="Windows">
            <CheckBox
              size="small"
              value={cleaning_windows}
              onChange={() => {}}
            />
          </GasSectionRow>
          <GasSectionRow title="Inside Door Wedge">
            <CheckBox
              size="small"
              value={cleaning_inside_door_wedge}
              onChange={() => {}}
            />
          </GasSectionRow>
        </GasSectionRow>
        <GasSectionRow title="Outside" flat>
          <GasSectionRow title="Rims" noBorder>
            <CheckBox size="small" value={cleaning_rims} onChange={() => {}} />
          </GasSectionRow>
          <GasSectionRow title="Body" noBorder>
            <CheckBox size="small" value={cleaning_body} onChange={() => {}} />
          </GasSectionRow>
        </GasSectionRow>
      </Section>
    );
  }
}

export default Cleaning;
