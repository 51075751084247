import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Lights extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Dashboard warning lights all off" noBorder>
          <CheckBox
            size="small"
            value={gas.dashboard_lights_off}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Light reset: List out which lights reset">
          {gas.dashboard_lights_reset}
        </GasSectionRow>
        <GasSectionRow title="Reverse lights">
          <CheckBox
            size="small"
            value={gas.lights_reverse}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Brake lights">
          <CheckBox size="small" value={gas.lights_brake} onChange={() => {}} />
        </GasSectionRow>
        <GasSectionRow title="Signal lights">
          <CheckBox
            size="small"
            value={gas.lights_signals}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Low beam lights">
          <CheckBox
            size="small"
            value={gas.lights_low_beam}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="High beam lights">
          <CheckBox
            size="small"
            value={gas.lights_high_beam}
            onChange={() => {}}
          />
        </GasSectionRow>
      </Section>
    );
  }
}

export default Lights;
