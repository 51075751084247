import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Document} from '@matthahn/sally-ui';
import {Loader} from '../../atoms/Loader';

const GoogleDoc = ({loading, html, data, parser, ...props}) =>
  !html ? (
    <Loader size={50} space={40} />
  ) : (
    <Document
      html={html}
      data={data}
      loading={loading}
      parser={parser}
      {...props}
    />
  );

GoogleDoc.propTypes = {
  loading: PropTypes.bool,
  html: PropTypes.string,
  data: PropTypes.object,
  parser: PropTypes.object,
};

export default GoogleDoc;
