// branch components
import WorkingHoursExceptionsEditor from '../WorkingHoursExceptionsEditor/WorkingHoursExceptionsEditor';

// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// date constants
import DAYS_OF_WEEK from '../../../date/constants/daysOfWeek.constant.date';

// date components
import WorkingHoursIntervalEditor from '../../../date/components/WorkingHoursIntervalEditor/WorkingHoursIntervalEditor';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BranchWorkingHoursEditorModal = ({
  exceptions,
  onClose,
  onExceptionsChange,
  onRulesChange,
  onSave,
  operationTimeRulesId,
  refresh,
  saving,
  timeZone,
  title,
  visible,
  workingHours,
}) => (
  <Modal
    title={title || 'Operating Hours Editor'}
    subtitle={!!title ? 'Operating Hours Editor' : ''}
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Save', loading: saving, onClick: onSave}]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        {!!workingHours && (
          <Content padding="none" noBorder>
            {[...DAYS_OF_WEEK].map((day) => (
              <WorkingHoursIntervalEditor
                day={day}
                key={day}
                refresh={refresh}
                workingHours={workingHours[day]?.open}
                onChange={(rules) => onRulesChange({...workingHours, ...rules})}
              />
            ))}
          </Content>
        )}
        <Content padding="none" noBorder>
          <WorkingHoursExceptionsEditor
            exceptions={exceptions}
            onChange={onExceptionsChange}
            operationTimeRulesId={operationTimeRulesId}
            timeZone={timeZone}
          />
        </Content>
      </Content>
    )}
  </Modal>
);

BranchWorkingHoursEditorModal.propTypes = {
  exceptions: PropTypes.array,
  onClose: PropTypes.func,
  onExceptionsChange: PropTypes.func,
  onRulesChange: PropTypes.func,
  onSave: PropTypes.func,
  operationTimeRulesId: PropTypes.number,
  refresh: PropTypes.any,
  saving: PropTypes.bool,
  timeZone: PropTypes.string,
  title: PropTypes.node,
  visible: PropTypes.bool,
  workingHours: PropTypes.object,
};

export default BranchWorkingHoursEditorModal;
