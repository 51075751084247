// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// layout components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const AddPaymentGroupModal = ({
  loading,
  name,
  onChange,
  onClose,
  onSave,
  owner_email,
  owner_first_name,
  owner_last_name,
  owner_phone_number,
  visible,
}) => (
  <Modal
    title="Add new Payment Group"
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Save', loading, onClick: onSave}]}
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput value={name} disabled={loading} onChange={onChange}>
              {name.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <AttributeInput
              value={owner_first_name}
              disabled={loading}
              onChange={onChange}
            >
              {owner_first_name.label.default}
            </AttributeInput>
          </Column>
          <Column size={1 / 2}>
            <AttributeInput
              value={owner_last_name}
              disabled={loading}
              onChange={onChange}
            >
              {owner_last_name.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column size={1 / 2}>
            <AttributeInput
              value={owner_phone_number}
              disabled={loading}
              onChange={onChange}
              preIcon="phone"
            >
              {owner_phone_number.label.default}
            </AttributeInput>
          </Column>
          <Column size={1 / 2}>
            <AttributeInput
              value={owner_email}
              disabled={loading}
              onChange={onChange}
              preIcon="mail"
            >
              {owner_email.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

AddPaymentGroupModal.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  owner_email: PropTypes.object,
  owner_first_name: PropTypes.object,
  owner_last_name: PropTypes.object,
  owner_phone_number: PropTypes.object,
  visible: PropTypes.bool,
};

export default AddPaymentGroupModal;
