import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Keys extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Battery Checked and Working" noBorder>
          <CheckBox size="small" value={gas.keys_battery} onChange={() => {}} />
        </GasSectionRow>
        <GasSectionRow title="Keys Labeled">
          <CheckBox size="small" value={gas.keys_labeled} onChange={() => {}} />
        </GasSectionRow>
      </Section>
    );
  }
}

export default Keys;
