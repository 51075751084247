class VehicleTracker {
  events = {};
  historicEvents = {};
  lastSocketEvent = 0;

  set({
    events = this.events,
    lastSocketEvent = this.lastSocketEvent,
    historicEvents = this.historicEvents,
  }) {
    this.events = events;
    this.historicEvents = historicEvents;
    this.lastSocketEvent = lastSocketEvent;
  }

  get() {
    return {
      events: this.events,
      historicEvents: this.historicEvents,
      lastSocketEvent: this.lastSocketEvent,
    };
  }
}

export default new VehicleTracker();
