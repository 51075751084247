import styled from 'styled-components';

// colors
import {black, white} from '@matthahn/sally-ui/lib/libs/colors';

const Total = styled.div`
  width: 100%;
  height: 34px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 30px;
  background: ${black};
  color: ${white};
`;

export default Total;
