import {REDUX_SET} from './constants';

const DEFAULT_STATE = {
  loading: false,
  turnovers: [],
  filter: null,

  maintenance: null,

  branch: null,
  dealership: null,

  driver: null,
  loadingDriverRateAddon: false,
  driverRateAddon: 0,

  search: '',
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case REDUX_SET:
      return {
        ...state,
        ...data,
      };

    default:
      return state;
  }
};
