// Sections
import Calamp from '../components/Calamp/Calamp';
import Cleaning from '../components/Cleaning/Cleaning';
import Dashcam from '../components/Dashcam/Dashcam';
import Equipment from '../components/Equipment/Equipment';
import Fuel from '../components/Fuel/Fuel';
import Keys from '../components/Keys/Keys';
import Launch from '../components/Launch/Launch';
import Lights from '../components/Lights/Lights';
import Mileage from '../components/Mileage/Mileage';
import Misc from '../components/Misc/Misc';
import Offboard from '../components/Offboard/Offboard';
import Oil from '../components/Oil/Oil';
import Pictures from '../components/Pictures/Pictures';
import ShortCleaning from '../components/ShortCleaning/ShortCleaning';
import ShortDashcam from '../components/ShortDashcam/ShortDashcam';
import ShortLights from '../components/ShortLights/ShortLights';
import ShortTires from '../components/ShortTires/ShortTires';
import Spireon from '../components/Spireon/Spireon';
import SvidDecal from '../components/SvidDecal/SvidDecal';
import TestDrive from '../components/TestDrive/TestDrive';
import Tires from '../components/Tires/Tires';

export default [
  {type: 'mileage', Component: Mileage},
  {type: 'last_oil_change', Component: Oil},
  {type: 'equipment', Component: Equipment},
  {type: 'calamp', Component: Calamp},
  {type: 'spireon', Component: Spireon},
  {type: 'dashcam', Component: Dashcam},
  {type: 'short_dashcam', Component: ShortDashcam},
  {type: 'short_lights', Component: ShortLights},
  {type: 'lights', Component: Lights},
  {type: 'fuel', Component: Fuel},
  {type: 'short_cleaning', Component: ShortCleaning},
  {type: 'cleaning', Component: Cleaning},
  {type: 'short_tires', Component: ShortTires},
  {type: 'tires', Component: Tires},
  {type: 'keys', Component: Keys},
  {type: 'test_drive', Component: TestDrive},
  {type: 'misc', Component: Misc},
  {type: 'svid_decal', Component: SvidDecal},
  {type: 'pictures', Component: Pictures},
  {type: 'launch', Component: Launch},
  {type: 'offboard', Component: Offboard},
];
