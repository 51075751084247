export default [
  'id',
  'first_name',
  'last_name',
  'created_at',
  'modified_at',
  'phone_number',
  'email',
  'fhv_license_number',
  'state',
];
