// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// legacy containers
import GoogleDocContainer from '../../../components/containers/molecules/GoogleDocContainer/GoogleDocContainer';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const SignChangeOrderContractModal = ({
  driver,
  html = '',
  loading,
  onClose,
  onExec,
  onExecError,
  rental,
  signing,
  visible,
}) => (
  <Modal visible={visible} onClose={onClose} title="Sign Contract" size="large">
    {(Content) =>
      loading || !driver || !rental ? (
        <Content padding="none">
          <ModalLoader />
        </Content>
      ) : (
        <Content padding="none">
          <GoogleDocContainer
            loading={signing}
            html={html}
            data={{
              rental,
              driver,
              vehicle: {...rental.vehicle},
            }}
            required={[
              'signature',
              'smallSignature',
              'signatureL',
              'smallSignatureL',
              'input_field',
            ]}
            onExec={onExec}
            onError={onExecError}
          />
        </Content>
      )
    }
  </Modal>
);

SignChangeOrderContractModal.propTypes = {
  driver: PropTypes.object,
  html: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onExec: PropTypes.func,
  onExecError: PropTypes.func,
  rental: PropTypes.object,
  signing: PropTypes.bool,
  visible: PropTypes.bool,
};

export default SignChangeOrderContractModal;
