import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import listApi from '@matthahn/sally-fn/lib/user/api/list.api.user';
import api from '../../../libs/everythingFromApi';

class UsersFetchContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getUsers();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getUsers = async () => {
    const {dispatch} = this.props;
    if (!this.mounted) return;

    dispatch(setAct({loading: true}));
    const users = await api(listApi);
    dispatch(setAct({loading: false, users}));
    setTimeout(this.getUsers, 30000);
  };

  render() {
    const {children} = this.props;
    return children;
  }
}

export default connect()(UsersFetchContainer);
