import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Avatar} from '@matthahn/sally-ui';

// Libs
import {lib} from '@matthahn/sally-ui';

// Color
const {
  colors: {red, white},
} = lib;

const Container = styled.div`
  position: relative;
  margin-left: 30px;
  cursor: pointer;
  user-select: none;
`;

const Badge = styled.div`
  pointer-events: none;
  position: absolute;
  top: -5px;
  left: 20px;
  border: 2px solid ${white};
  background: ${red};
  color: white;
  overflow: hidden;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 8px;
  font-weight: 700;
  text-align: center;
`;

const NavigationAvatar = ({avatar, notifications, onClick}) => (
  <Container onClick={onClick}>
    <Avatar avatar={avatar} theme="grey" size="large" />
    {notifications > 0 && (
      <Badge>{notifications > 99 ? '99+' : notifications}</Badge>
    )}
  </Container>
);

NavigationAvatar.propTypes = {
  avatar: PropTypes.string,
  notifications: PropTypes.number,
  onClick: PropTypes.func,
};

NavigationAvatar.defaultProps = {
  notifications: 0,
};

export default NavigationAvatar;
