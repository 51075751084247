import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const AddBlock = styled.div`
  width: 100%;
  cursor: pointer;
  user-select: none;
  color: ${darkGrey};
`;

export default AddBlock;
