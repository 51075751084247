// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import api from '../../../libs/everythingFromApi';

// permissions components
import PermissionsModal from '../../component/PermissionsModal/PermissionsModal';

// permission events
import showPermissionsEvent from '../../events/show.event.permission';

// permission lib
import generateAvailablePermissions from '../../lib/generateAvailablePermissions.lib.permission';
import generatePermissionTree from '../../lib/generatePermissionTree.lib.permission';
import generateUserPermissions from '../../lib/generateUserPermissions.lib.permission';

// permission permissions
import canUpdatePermissionsPermission from '../../permissions/canUpdatePermissions.permission.permission';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

// user api
import getPermissionsDescriptionsApi from '@matthahn/sally-fn/lib/user/api/getPermissionDescriptions.api.user';
import listUsersApi from '@matthahn/sally-fn/lib/user/api/list.api.user';
import updateUserApi from '@matthahn/sally-fn/lib/user/api/update.api.user';

class PermissionsContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    userData: PropTypes.object,
  };

  state = {
    availablePermissions: [],
    loading: false,
    permissions: {},
    permissionTree: {},
    saving: false,
    search: '',
    user: null,
    users: [],
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showPermissionsEvent.subscribe(this.show));
  }

  show = () => {
    if (!canUpdatePermissionsPermission()) return;
    this.setState({visible: true, user: null, search: ''}, this.getUsers);
  };

  hide = () => {
    const {visible, saving, loading} = this.state;
    if (!visible || saving || loading) return;
    this.setState({visible: false});
  };

  getUsers = async () => {
    const {userData} = this.props;
    this.setState({loading: true});
    try {
      const [allUsers, permissionsResponse] = await Promise.all([
        api(listUsersApi, {ordering: 'username', service: false}),
        getPermissionsDescriptionsApi(userData.id),
      ]);
      const users = [...allUsers].filter((user) => !user.is_superuser);
      const availablePermissions = generateAvailablePermissions(
        permissionsResponse?.actions?.POST || {}
      );
      this.setState({loading: false, users, availablePermissions});
    } catch (error) {
      this.setState({loading: false, users: []});
    }
  };

  search = (search) => {
    this.setState({search});
  };

  users = () => {
    const {users, search} = this.state;
    const formattedSearch = `${search}`.toLowerCase();
    return !!formattedSearch.length
      ? [...users].filter((user) => {
          const name = [user.first_name, user.last_name]
            .filter((userName) => !!userName)
            .join('');
          return [name, user.email || '', user.username || ''].some(
            (searchValue) =>
              !!searchValue &&
              searchValue.toLowerCase().includes(formattedSearch)
          );
        })
      : users;
  };

  change = (permission) => (value) => {
    const {permissions, saving} = this.state;
    if (saving) return;
    this.setState({permissions: {...permissions, [permission]: value}});
  };

  save = async () => {
    const {user, saving, permissions} = this.state;

    if (!user || saving) return;

    this.setState({saving: true});

    try {
      const updatedUser = await updateUserApi(user.id, permissions);
      const users = [...this.state.users].map((userToUpdate) =>
        userToUpdate.id === updatedUser.id ? updatedUser : userToUpdate
      );
      this.setState({saving: false, users});
      alert.success('Permissions updated');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  selectUser = (user) => async () => {
    const {saving, availablePermissions} = this.state;
    if (saving) return;
    this.setState({
      user,
      permissions: generateUserPermissions({user, availablePermissions}),
      permissionTree: generatePermissionTree(availablePermissions),
    });
  };

  render() {
    const {
      availablePermissions,
      loading,
      permissions,
      permissionTree,
      saving,
      search,
      user,
      visible,
    } = this.state;
    return (
      <PermissionsModal
        availablePermissions={availablePermissions}
        loading={loading}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        onSearch={this.search}
        onUserSelect={this.selectUser}
        permissions={permissions}
        permissionTree={permissionTree}
        saving={saving}
        search={search}
        user={user}
        users={this.users()}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(
  connect((state) => ({userData: state.authorization.userData}))(
    PermissionsContainer
  )
);
