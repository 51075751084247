const extractImages = ({gas, vehicle, repairImages = []}) =>
  [
    ...repairImages.map((image) => ({
      key: `${image.id}`,
      label: 'Repair',
      image: image.document_file,
      date: image.created_at,
    })),
    !!gas?.vehicle_picture_front?.document_file && {
      key: 'vehicle_picture_front',
      label: 'Front',
      image: gas?.vehicle_picture_front?.document_file,
      date: gas?.vehicle_picture_front?.created_at,
    },
    !!gas?.vehicle_picture_left?.document_file && {
      key: 'vehicle_picture_left',
      label: 'Left',
      image: gas?.vehicle_picture_left?.document_file,
      date: gas?.vehicle_picture_left?.created_at,
    },
    !!gas?.vehicle_picture_back?.document_file && {
      key: 'vehicle_picture_back',
      label: 'Back',
      image: gas?.vehicle_picture_back?.document_file,
      date: gas?.vehicle_picture_back?.created_at,
    },
    !!gas?.vehicle_picture_right?.document_file && {
      key: 'vehicle_picture_right',
      label: 'Right',
      image: gas?.vehicle_picture_right?.document_file,
      date: gas?.vehicle_picture_right?.created_at,
    },
    !!gas?.vehicle_picture_interior_dash?.document_file && {
      key: 'vehicle_picture_interior_dash',
      label: 'Dash',
      image: gas?.vehicle_picture_interior_dash?.document_file,
      date: gas?.vehicle_picture_interior_dash?.created_at,
    },
    !!gas?.vehicle_picture_tire_mobility_kit?.document_file && {
      key: 'vehicle_picture_tire_mobility_kit',
      label: 'Tire Mobility Kit',
      image: gas?.vehicle_picture_tire_mobility_kit?.document_file,
      date: gas?.vehicle_picture_tire_mobility_kit?.created_at,
    },
    !!gas?.vehicle_picture_interior_front?.document_file && {
      key: 'vehicle_picture_interior_front',
      label: 'Front Interior',
      image: gas?.vehicle_picture_interior_front?.document_file,
      date: gas?.vehicle_picture_interior_front?.created_at,
    },
    !!gas?.vehicle_picture_interior_rear?.document_file && {
      key: 'vehicle_picture_interior_rear',
      label: 'Rear Interior',
      image: gas?.vehicle_picture_interior_rear?.document_file,
      date: gas?.vehicle_picture_interior_rear?.created_at,
    },
    !!gas?.vehicle_picture_interior_floor_board_left?.document_file && {
      key: 'vehicle_picture_interior_floor_board_left',
      label: 'Floor Board Left',
      image: gas?.vehicle_picture_interior_floor_board_left?.document_file,
      date: gas?.vehicle_picture_interior_floor_board_left?.created_at,
    },
    !!gas?.vehicle_picture_interior_floor_board_right?.document_file && {
      key: 'vehicle_picture_interior_floor_board_right',
      label: 'Floor Board Right',
      image: gas?.vehicle_picture_interior_floor_board_right?.document_file,
      date: gas?.vehicle_picture_interior_floor_board_right?.created_at,
    },
    !!gas?.fuel_image?.document_file && {
      key: 'fuel',
      label: 'Fuel Level',
      image: gas?.fuel_image?.document_file,
      date: gas?.fuel_image?.created_at,
    },
  ].filter((image) => !!image);

export default extractImages;
