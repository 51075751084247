import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// branch actions
import {set as setAct} from '../../redux/actions';

// branch events
import branchCreatedEvent from '../../events/created.event.branch';
import branchUpdatedEvent from '../../events/updated.event.branch';

// branch sockets
import branchCreatedSocket from '../../sockets/created.socket.branch';
import branchUpdatedSocket from '../../sockets/updated.socket.branch';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class BranchListenerContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(
      branchCreatedSocket.subscribe(this.branchCreated),
      branchUpdatedSocket.subscribe(this.branchUpdated),
      branchCreatedEvent.subscribe(this.branchCreated),
      branchUpdatedEvent.subscribe(this.branchUpdated)
    );
  }

  branchCreated = (branch) => {
    const {branches, dispatch} = this.props;
    const updatedBranches = [...branches, branch];
    dispatch(setAct({branches: updatedBranches}));
  };

  branchUpdated = (branch) => {
    const {branches, dispatch} = this.props;
    const updatedBranches = [...branches].map((existingBranch) =>
      existingBranch.id === branch.id ? branch : existingBranch
    );
    dispatch(setAct({branches: updatedBranches}));
  };

  render() {
    return null;
  }
}

export default subscriptionHOC(
  connect((state) => ({branches: state.branch.branches}))(
    BranchListenerContainer
  )
);
