// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import CompactLabel from '../../../layout/components/CompactLabel/CompactLabel';
import FlowScroll from '../../../layout/components/FlowScroll/FlowScroll';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Grid from './components/Grid';
import Section from './components/Section';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchGasStep = ({
  Container,
  onBack,
  onChange,
  onNext,
  sections,
  ...attributes
}) => (
  <Fragment>
    <Container padding="none">
      <FlowScroll>
        {sections.map((section) => (
          <Fragment key={section.type}>
            <CompactLabel>{section.label}</CompactLabel>
            <Grid>
              {section.sections.map((gasSection) => (
                <Section
                  key={gasSection.key}
                  selected={attributes[section.type].includes(gasSection.key)}
                  onClick={onChange({
                    type: section.type,
                    section: gasSection.key,
                  })}
                >
                  <i className={`mdi mdi-${gasSection.icon}`} />
                  {gasSection.label}
                </Section>
              ))}
            </Grid>
          </Fragment>
        ))}
      </FlowScroll>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchGasStep.propTypes = {
  Container: PropTypes.any,
  offboard: PropTypes.array,
  onBack: PropTypes.func,
  onboard: PropTypes.array,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  onsite_sitting: PropTypes.array,
  onsite: PropTypes.array,
  sections: PropTypes.array,
};

export default BranchGasStep;
