// Constants
import {REDUX_SET, REDUX_RESET} from './constants';

export const set = (data) => (dispatch, getState) => {
  if (!!data.vehicle) {
    const {vehicle: previousVehicle} = getState().vehicle;
    data.vehicle = {
      ...data.vehicle,
      samson_tickets:
        data.vehicle?.samson_tickets !== undefined
          ? data.vehicle?.samson_tickets
          : previousVehicle?.samson_tickets || 0,
    };
  }
  return dispatch({type: REDUX_SET, data});
};

export const reset = () => ({type: REDUX_RESET});
