// redux
import store from '../../redux/store';

const rentalRateOverridePermission = (
  {
    is_superuser = false,
    rental_rate_override_permission = false,
  } = store.getState().authorization.userData
) => is_superuser || rental_rate_override_permission;

export default rentalRateOverridePermission;
