// driver attributes
import * as driverAttributes from '@matthahn/sally-fn/lib/driver/attributes';

export default () =>
  Object.values(driverAttributes).reduce(
    (combined, attribute) =>
      !!combined.find(
        (existingAttribute) => existingAttribute.value === attribute.attribute
      )
        ? combined
        : [
            ...combined,
            {
              value: attribute.attribute,
              label: attribute.label.default,
            },
          ],
    []
  );
