// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import HtmlEditor from '../../../layout/components/HtmlEditor/HtmlEditor';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Editor from './components/Editor';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchAccidentInfoStep = ({
  accident_information,
  Container,
  onBack,
  onChange,
  onNext,
  refresh,
}) => (
  <Fragment>
    <Container padding="none" noBorder>
      <Editor>
        <HtmlEditor
          html={accident_information}
          onChange={onChange}
          refresh={refresh}
          toolbar={{
            options: [
              'inline',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove',
            ],
          }}
        />
      </Editor>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          !!onNext ? (
            <Button size="small" theme="grey" onClick={onNext}>
              Next
            </Button>
          ) : null
        }
      />
    </Container>
  </Fragment>
);

BranchAccidentInfoStep.propTypes = {
  accident_information: PropTypes.string,
  Container: PropTypes.any,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
  refresh: PropTypes.any,
};

export default BranchAccidentInfoStep;
