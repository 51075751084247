import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: grid;
  padding: 10px;
  row-gap: 30px;
  grid-template-columns: 33.33% 33.33% 33.33%;
`;

export default Container;
