import styled, {css} from 'styled-components';

// colors
import {
  black,
  grey,
  white,
  darkGrey,
  transparent,
} from '@matthahn/sally-ui/lib/libs/colors';

const Branch = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  background: ${grey};
  padding: 0px 10px;
  gap: 10px;
  border-radius: 4px;
  color: ${darkGrey};
  ${({newTemplate, continueWithTemplate}) =>
    newTemplate
      ? css`
          color: ${darkGrey};
          background: ${transparent};
          border: 2px dashed ${grey};
        `
      : continueWithTemplate
      ? css`
          color: ${white};
          background: ${black};
          grid-column-start: 1;
          grid-column-end: 3;
          margin-bottom: 10px;
        `
      : ''}
`;

export default Branch;
