import {css} from 'styled-components';
import {transitions} from 'polished';

export default ({
  visible = false,
  speed = '.3s',
  ease = 'ease-in-out',
  transform = null,
} = {}) => css`
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  ${transform
    ? css`
        transform: ${({visible}) => (visible ? 'none' : transform)};
      `
    : ''};
  ${transitions(
    `opacity ${speed} ${ease}, visibility ${speed} ${ease}${
      transform ? `, transform ${speed} ${ease}` : ''
    }`
  )};
`;
