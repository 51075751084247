import React from 'react';
import PropTypes from 'prop-types';

// components
import TableRow from '@matthahn/sally-ui/lib/components/Table/components/Row.Table';

// local components
import ValueContainer from './components/ValueContainer';

const CompactDataRow = ({
  children = null,
  contentProps = {},
  label = null,
  labelProps = {},
  rowProps = {},
  noPadding = false,
  wrapContent = false,
  rowPortions = 4,
  labelSpan = 1,
  contentSpan = 3,
}) => (
  <TableRow portions={rowPortions} {...rowProps}>
    {(TableColumn) => [
      <TableColumn key="label" span={labelSpan} {...labelProps}>
        {label}
      </TableColumn>,
      <TableColumn
        border="none none none solid"
        key="content"
        span={contentSpan}
        noPadding={noPadding}
        {...contentProps}
      >
        {wrapContent ? <ValueContainer>{children}</ValueContainer> : children}
      </TableColumn>,
    ]}
  </TableRow>
);

CompactDataRow.propTypes = {
  children: PropTypes.node,
  contentProps: PropTypes.object,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  rowProps: PropTypes.object,
  wrapContent: PropTypes.bool,
  rowPortions: PropTypes.number,
  labelSpan: PropTypes.number,
  contentSpan: PropTypes.number,
};

export default CompactDataRow;
