import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey},
} = lib;

const Container = styled.div`
  width: 100%;
  text-align: right;
  padding-bottom: 15px;
  padding-right: 10px;
  user-select: none;
`;

const Text = styled.span`
  display: inline-block;
  color: ${darkGrey};
  cursor: pointer;
`;

const SelectAll = ({onClick}) => (
  <Container>
    <Text onClick={onClick}>Select All</Text>
  </Container>
);

SelectAll.propTypes = {
  onClick: PropTypes.func,
};

export default SelectAll;
