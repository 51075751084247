import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Uploader} from '@matthahn/sally-ui';

// local components
import Container from './components/Content';

const UploadChicagoTicketsModal = ({loading, visible, onClose, onUpload}) => (
  <Modal
    title="Upload Chicago Tickets"
    icon="upload"
    visible={visible}
    onClose={onClose}
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          <Uploader loading={loading} onChange={onUpload} accept=".txt" small />
        </Container>
      </Content>
    )}
  </Modal>
);

UploadChicagoTicketsModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
};

export default UploadChicagoTicketsModal;
