/**
 * @description Combine reducers
 * @namespace reduces
 */

import {combineReducers} from 'redux';

// Global reducers
import accident from '../accident/redux/reducer';
import authorization from './authorization/reducer';
import branch from '../branch/redux/reducer';
import dealership from '../dealership/redux/reducer';
import mileagePackage from '../mileagePackage/redux/reducer';
import payment from '../payment/redux/reducer';
import paymentGroup from '../paymentGroup/redux/reducer';
import reducedDeductible from '../reducedDeductible/redux/reducer';
import spotlight from '../spotlight/redux/reducer';
import termsOfService from '../termsOfService/redux/reducer';
import ticket from '../ticket/redux/reducer';
import toll from '../toll/redux/reducer';
import ui from './ui/reducer';
import user from '../user/redux/reducer';

// Local reducers
import customer from '../components/containers/templates/CustomerEditContainer/reducer';
import docBundleManager from '../components/containers/organisms/DocBundleManagerContainer/reducer';
import putVehicleOnHold from '../vehicleHold/containers/PutVehicleOnHoldContainer/redux/reducer';
import releaseVehicleFromHold from '../vehicleHold/containers/ReleaseVehicleFromHoldContainer/redux/reducer';
import rentalCancel from '../components/containers/organisms/TurnoverCancelReservationContainer/reducer';
import rentalEdit from '../components/containers/organisms/RentalModalContainer/reducer';
import rentalLaunch from '../components/containers/organisms/LaunchRentalFlowContainer/reducer';
import rentalReservation from '../components/containers/organisms/RentalReservationContainer/reducer';
import rentalReturn from '../components/containers/organisms/TurnoverReturnVehicleContainer/reducer';
import takeRentMoney from '../components/containers/organisms/TakeRentMoneyContainer/reducer';
import turnover from '../components/containers/templates/TurnoverContainer/reducer';
import userSwitch from '../components/containers/organisms/UserSwitchContainer/reducer';
import vehicle from '../components/containers/templates/VehicleEditContainer/reducer';
import vehicleReady from '../components/containers/organisms/VehicleReadyFlowContainer/reducer';
import vehicleTracker from '../components/containers/templates/VehicleTrackingContainer/redux//reducer';

export default combineReducers({
  accident,
  authorization,
  mileagePackage,
  reducedDeductible,
  spotlight,
  termsOfService,
  ui,

  branch,
  customer,
  dealership,
  docBundleManager,
  payment,
  paymentGroup,
  putVehicleOnHold,
  releaseVehicleFromHold,
  rentalCancel,
  rentalEdit,
  rentalLaunch,
  rentalReservation,
  rentalReturn,
  takeRentMoney,
  ticket,
  toll,
  turnover,
  user,
  userSwitch,
  vehicle,
  vehicleReady,
  vehicleTracker,
});
