import styled from 'styled-components';

const Row = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
`;

export default Row;
