import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'accidents_slack_channel',
  label: {
    default: 'Accidents slack channel',
    short: 'Accidents',
  },
});
