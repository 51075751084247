import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Dashcam extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
    equipment: PropTypes.bool,
  };

  content = () => {
    const {gas} = this.props;

    return gas.dashcam_serial_confirmed ? (
      <Fragment>
        <GasSectionRow title='Ensure camera not using "SAN DISK"' noBorder>
          <CheckBox
            size="small"
            value={gas.dashcam_sandisc}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Front camera light blinking red / back camera solid white">
          <CheckBox
            size="small"
            value={gas.dashcam_camera_blinking}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Camera directionally correct">
          <CheckBox
            size="small"
            value={gas.dashcam_camera_direction_correct}
            onChange={() => {}}
          />
        </GasSectionRow>
      </Fragment>
    ) : (
      'Serial Number was not confirmed'
    );
  };

  render() {
    const {section, gas, equipment} = this.props;

    const content = this.content();

    return equipment ? (
      content
    ) : (
      <Section section={section} gas={gas} flat={gas.dashcam_serial_confirmed}>
        {content}
      </Section>
    );
  }
}

export default Dashcam;
