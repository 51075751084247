import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Heading, Icon} from '@matthahn/sally-ui';
import Row from './Row.Profile';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey},
} = lib;

// Helper Components
const StyledIcon = styled(Icon)`
  font-size: 16px !important;
  color: ${darkGrey};
  cursor: pointer;
`;

const Header = ({username, onClose}) => (
  <Row>
    <div>
      <Heading size="4" bold>
        Hello {username}!
      </Heading>
    </div>
    <div>
      <StyledIcon icon="close" onClick={onClose} />
    </div>
  </Row>
);

Header.propTypes = {
  username: PropTypes.string,
  onClose: PropTypes.func,
};

export default Header;
