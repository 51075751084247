// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Switch from '@matthahn/sally-ui/lib/components/Switch/Switch';

// layout components
import CardLoader from '../../../layout/components/CardLoader/CardLoader';

// legacy components
import {AttributeInput} from '../../../components/components/atoms/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// type
import amountType from '@matthahn/sally-fn/lib/types/types/amount.type';

const PaymentPlanEditorModal = ({
  disable_app_payment_extensions,
  dollarAmount,
  estimatedPayment,
  loading,
  onChange,
  onChangeAppPaymentExtensions,
  onClose,
  onSave,
  payment_plan_dollar_amount,
  payment_plan_percent_amount,
  paymentPlanBalance,
  saving,
  showAppPaymentExtensionOption,
  visible,
}) => {
  const disableAppPaymentExtensions = disable_app_payment_extensions.api.format();
  return (
    <Modal
      visible={visible}
      title="Payment Plan"
      onClose={onClose}
      size="small"
      buttonsRight={
        disableAppPaymentExtensions
          ? []
          : [
              {
                label: 'Save',
                loading: saving,
                disabled: loading,
                onClick: onSave,
              },
            ]
      }
    >
      {(Content) =>
        loading ? (
          <Content padding="none">
            <CardLoader />
          </Content>
        ) : (
          <Content>
            <Row margin>
              <Column>
                Payment Plan Balance:{' '}
                <b>${amountType(paymentPlanBalance).format()}</b>
              </Column>
            </Row>
            {showAppPaymentExtensionOption && (
              <Row margin={!disableAppPaymentExtensions}>
                <Column>
                  <AttributeInput
                    value={disable_app_payment_extensions}
                    onChange={onChangeAppPaymentExtensions}
                    disabled={saving}
                  >
                    {disable_app_payment_extensions.label.default}
                  </AttributeInput>
                </Column>
              </Row>
            )}
            {(!showAppPaymentExtensionOption ||
              !disableAppPaymentExtensions) && (
              <Fragment>
                <Row margin>
                  <Column>
                    <Switch
                      value={dollarAmount}
                      onChange={(value) => onChange(value, 'dollarAmount')}
                      disabled={saving}
                    >
                      Dollar amount (Please don't use)
                    </Switch>
                  </Column>
                </Row>
                <Row margin={!dollarAmount}>
                  <Column>
                    {dollarAmount ? (
                      <AttributeInput
                        value={payment_plan_dollar_amount}
                        onChange={onChange}
                        disabled={saving}
                      >
                        {payment_plan_dollar_amount.label.default}
                      </AttributeInput>
                    ) : (
                      <AttributeInput
                        value={payment_plan_percent_amount}
                        onChange={onChange}
                        disabled={saving}
                      >
                        {payment_plan_percent_amount.label.default}
                      </AttributeInput>
                    )}
                  </Column>
                </Row>
                {!dollarAmount && (
                  <Row>
                    <Column>
                      Estimated weekly payment: $
                      {amountType(estimatedPayment).format()}
                    </Column>
                  </Row>
                )}
              </Fragment>
            )}
          </Content>
        )
      }
    </Modal>
  );
};

PaymentPlanEditorModal.propTypes = {
  dollarAmount: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeAppPaymentExtensions: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  payment_plan_dollar_amount: PropTypes.object,
  payment_plan_percent_amount: PropTypes.object,
  saving: PropTypes.bool,
  showAppPaymentExtensionOption: PropTypes.bool,
  visible: PropTypes.bool,
};

export default PaymentPlanEditorModal;
