import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

class ShortDashcam extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  content = () => {
    const {gas} = this.props;

    return gas.dashcam_ok ? (
      <CheckBox value={true} onChange={() => {}} size="small" />
    ) : (
      <CheckBox value={false} onChange={() => {}} size="small" />
    );
  };

  render() {
    const {section, equipment} = this.props;

    const content = this.content();

    return equipment ? content : <Section section={section}>{content}</Section>;
  }
}

export default ShortDashcam;
