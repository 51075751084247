import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// actions
import {set as setAct} from '../../redux/actions';

// api
import listPaymentTypesApi from '@matthahn/sally-fn/lib/payment/api/getTypes.api.payment';

// components
import {FullScreenLoader} from '@matthahn/sally-ui';

// error
import parseError from '../../../error/parseError';

// lib
import {lib} from '@matthahn/sally-ui';

// payment lib
import parseChargeTypes from '../../lib/parseChargeTypes.lib.payment';
import parseCreditTypes from '../../lib/parseCreditTypes.lib.payment';

// alerts
const {alert} = lib;

class PaymentTypesFetchContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
    loadingPaymentTypes: PropTypes.bool,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const [
        {charge_types: allChargeTypes, credit_types: allCreditTypes},
        {charge_types, credit_types},
      ] = await Promise.all([
        listPaymentTypesApi(),
        listPaymentTypesApi({options: 'ui'}),
      ]);
      dispatch(
        setAct({
          loadingPaymentTypes: true,
          allChargeTypes: parseChargeTypes(allChargeTypes),
          allCreditTypes: parseCreditTypes(allCreditTypes),
          chargeTypes: parseChargeTypes(charge_types),
          creditTypes: parseCreditTypes(credit_types),
        })
      );
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children, loadingPaymentTypes} = this.props;
    return loadingPaymentTypes ? children : <FullScreenLoader />;
  }
}

export default connect((state) => ({
  loadingPaymentTypes: state.payment.loadingPaymentTypes,
}))(PaymentTypesFetchContainer);
