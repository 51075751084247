const list = [
  {
    bundle: 'reservation',
    name: 'Reservation',
  },
  {
    bundle: 'preBoarding',
    name: 'Pre-Boarding',
  },
  {
    bundle: 'boarding',
    name: 'Boarding',
  },
];

export default list;
