// date lib
// import {parseISO, isDate, isAfter} from 'date-fns';
// import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// const getActiveReducedDeductible = (rental, {now = new Date()} = {}) => {
//   const currentDateTime = isDate(now) ? now : parseISO(now);
//   const reducedDeductibles = [...(rental?.reduced_deductibles || [])];
//   const applicableDeductibles = reducedDeductibles.filter((reducedDeductible) =>
//     isAfter(currentDateTime, parseISO(reducedDeductible.effective_date))
//   );
//   if (!applicableDeductibles.length) return null;

//   const [reducedDeductible] = orderByDate(
//     applicableDeductibles,
//     'effective_date',
//     'desc'
//   );

//   return reducedDeductible || null;
// };

const getActiveReducedDeductible = (rental) =>
  [...(rental?.reduced_deductibles || [])].find(
    (reducedDeductible) => reducedDeductible.active
  ) || null;

export default getActiveReducedDeductible;
