import PropTypes from 'prop-types';
import React, {Component} from 'react';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class SvidDecal extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;
    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Missing">
          <CheckBox
            size="small"
            value={gas.svid_decal === 'missing'}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Present">
          <CheckBox
            size="small"
            value={gas.svid_decal === 'present'}
            onChange={() => {}}
          />
        </GasSectionRow>
      </Section>
    );
  }
}

export default SvidDecal;
