import styled from 'styled-components';

// constants
const SIZE = `100px`;

const Hour = styled.div`
  width: ${SIZE};
  max-width: ${SIZE};
  min-width: ${SIZE};
`;

export default Hour;
