import styled from 'styled-components';

// colors
import {grey, transparent} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  background: ${({disabled}) => (disabled ? transparent : grey)};
`;

export default Container;
