import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import printJS from 'print-js';

// Actions
import {close as closeAct} from './actions';

// Api
import {
  zip as zipApi,
  list as listApi,
  email as emailApi,
  print as printApi,
} from '@matthahn/sally-fn/lib/document/api';
import {checkTaskStatus as checkStatusApi} from '@matthahn/sally-fn/lib/core/api';
import {list as listDriversApi} from '@matthahn/sally-fn/lib/driver/api';

// Components
import {DocBundleManager} from '../../../components/organisms/DocBundleManager';

// Lib
import {lib} from '@matthahn/sally-ui';
import {
  isIn,
  lessThanOrEqualTo,
} from '@matthahn/sally-fn/lib/libs/apiQueryFilterExpressions';
import sallyError from '../../../../libs/sallyError';
import utc from '../../../../libs/utc';

// Helpers
const {alert, notify} = lib;

class DocBundleManagerContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    bundle: PropTypes.object,
    accidents: PropTypes.array,
    rentals: PropTypes.array,
    vehicles: PropTypes.array,
    drivers: PropTypes.array,
    name: PropTypes.string,
    date: PropTypes.oneOf([PropTypes.string, PropTypes.object]),

    dispatch: PropTypes.func,
  };

  state = {
    loading: null,
    data: [],
    files: [],
    selected: [],
    filesExist: false,
    print: null,
    closable: true,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.init();
  }

  closePrintNotification = null;

  call = (bundle, list, type) => {
    const {date} = this.props;
    const addonQuery = !!date
      ? {[lessThanOrEqualTo('created_at')]: utc(date)}
      : {};
    return !!bundle.files.length
      ? list.map(({id}) =>
          listApi({
            [type]: id,
            [isIn('type')]: bundle.files.map(({file}) => file.type).join(','),
            ordering: '-created_at',
            ...addonQuery,
          })
        )
      : [];
  };

  getExistingFiles = ({bundle, type, dataID, files}) =>
    [...bundle.files].map(({file}) => {
      const existingFile = [...files].find(
        (f) => f.type === file.type && f[type] === dataID
      );
      return !!existingFile
        ? {
            id: existingFile.id,
            name: existingFile.name,
            type: existingFile.type,
            exists: true,
          }
        : {
            id: `${dataID}${type}${file.type}`,
            name: file.defaultFileName,
            type: file.type,
            exists: false,
          };
    });

  init = async () => {
    const {bundle, accidents, drivers, rentals, vehicles} = this.props;

    this.setState({loading: 'loading'});

    const lists = {
      accident: accidents,
      driver: drivers,
      rental: rentals,
      vehicle: vehicles,
    };

    try {
      const apiCalls = Object.entries(bundle)
        .map(([type, actualBundle]) =>
          this.call(actualBundle, lists[type], type)
        )
        .flat();

      const response = await Promise.all(apiCalls);

      const files = response
        .map(({results}) =>
          [...results].reduce(
            (combined, current) =>
              !combined.find((duplicate) => duplicate.type === current.type)
                ? [...combined, current]
                : combined,
            []
          )
        )
        .reduce(
          (combined, results) => [
            ...combined,
            ...results.filter(
              (file) => !combined.find((duplicate) => duplicate.id === file.id)
            ),
          ],
          []
        );

      const data = Object.entries(bundle)
        .map(([type, actualBundle]) =>
          [...lists[type]].map((object) => ({
            id: `${type}${object.id}`,
            name: type,
            files: this.getExistingFiles({
              bundle: actualBundle,
              type,
              dataID: object.id,
              files,
            }),
          }))
        )
        .flat()
        .filter(({files}) => !!files.length);

      const selected = [...files].map(({id}) => id);
      const filesExist = !![...data].find((d) =>
        [...d.files].find(({exists}) => exists)
      );

      this.setState({loading: null, data, files, selected, filesExist});
    } catch (error) {
      alert.error('Something went wrong. Try again later.');
      this.setState({loading: null});
      this.close();
    }
  };

  close = (bypass = false) => {
    if (!bypass && !!this.state.loading) return;
    this.props.dispatch(closeAct());
  };

  ids = () => [...this.state.files].map(({id}) => id);

  selectedFileIDs = () =>
    [...this.ids()].filter((id) => this.state.selected.includes(id));

  toggleSelect = (id) => {
    if (this.state.loading) return;
    this.setState({
      selected: this.state.selected.includes(id)
        ? [...this.state.selected].filter((selected) => selected !== id)
        : [...this.state.selected, id],
    });
  };

  selectAll = () => {
    if (this.state.loading) return;
    this.setState({selected: this.ids()});
  };

  bundleName = () => this.props?.bundle?.accident?.name;

  checkTaskStatus = async (
    taskID,
    cb = () => null,
    onError = () => null,
    retries = 0,
    errors = 0
  ) => {
    if (retries >= 10 || errors >= 3) {
      onError();
      alert.error('Something went wrong. Try again later.');
      this.setState({loading: null});
      return;
    }

    try {
      const {status, ...data} = await checkStatusApi(taskID);
      if (status === 'success') {
        cb(data);
        return this.setState({loading: null});
      }
      setTimeout(
        () => this.checkTaskStatus(taskID, cb, onError, retries + 1),
        3000
      );
    } catch (error) {
      this.checkTaskStatus(taskID, cb, onError, retries, errors + 1);
    }
  };

  bundle = async () => {
    const {vehicles, drivers} = this.props;
    if (!!this.state.loading) return;
    try {
      const svid = !vehicles.length ? null : vehicles[0].svid;
      const driver_name = !drivers.length
        ? null
        : `${drivers[0].first_name} ${drivers[0].last_name}`;
      this.setState({loading: 'bundle'});
      const {task_id} = await zipApi(this.selectedFileIDs(), {
        svid,
        driver_name,
      });
      this.checkTaskStatus(task_id, ({url}) => window.open(url, '_blank'));
    } catch (error) {
      const {message} = sallyError(error);
      alert.error(message);
      this.setState({loading: null});
    }
  };

  print = async () => {
    if (this.state.loading) return;

    this.setState({loading: 'print'});

    try {
      this.closePrintNotification = notify({
        id: 'Print Files',
        title: 'Preparing files',
        icon: 'loading2',
        content: 'Files are being prepared for printing',
        closable: false,
        loading: true,
      });
      const {task_id} = await printApi(this.selectedFileIDs());
      if (!this.closePrintNotification) return;
      this.checkTaskStatus(
        task_id,
        ({url}) => {
          if (!this.closePrintNotification) return;
          this.closePrintNotification();
          this.closePrintNotification = null;
          this.setState({closable: false});
          notify({
            id: 'actualPrint',
            title: 'File Ready',
            content: 'Files are ready for printing',
            secondary: {
              label: 'Cancel',
              onClick: () => this.setState({closable: true}),
            },
            primary: {
              label: 'Print',
              onClick: () => {
                printJS(url);
                this.setState({closable: true});
              },
            },
            closable: false,
          });
        },
        () => {
          if (!this.closePrintNotification) return;
          this.closePrintNotification();
          this.closePrintNotification = null;
        }
      );
    } catch (error) {
      if (!!this.closePrintNotification) {
        this.closePrintNotification();
        this.closePrintNotification = null;
      }
      const {message} = sallyError(error);
      alert.error(message);
      this.setState({loading: null});
    }
  };

  email = async () => {
    if (this.state.loading) return;

    this.setState({loading: 'email'});

    try {
      const driverIDs = [...this.props.drivers].map(({id}) => id);
      const {results: drivers} = await listDriversApi({
        [isIn('id')]: driverIDs.join(','),
      });
      const driver = !!drivers.length ? {...[...drivers][0]} : null;
      if (!driver) return this.setState({loading: null});
      const {task_id} = await emailApi(this.selectedFileIDs(), driver.email);
      this.checkTaskStatus(task_id, () =>
        alert.success(`Email was sent to ${driver.email}`)
      );
    } catch (error) {
      const {message} = sallyError(error);
      alert.error(message);
      this.setState({loading: null});
    }
  };

  render() {
    const {visible} = this.props;
    const {loading, data, selected, filesExist, closable} = this.state;
    return (
      <DocBundleManager
        visible={visible}
        loading={loading}
        bundle={this.bundleName()}
        data={data}
        selected={selected}
        filesExist={filesExist}
        closable={closable}
        onSelect={this.toggleSelect}
        onSelectAll={this.selectAll}
        onBundle={this.bundle}
        onPrint={this.print}
        onEmail={this.email}
        onClose={this.close}
      />
    );
  }
}

export default connect((state) => ({...state.docBundleManager}))(
  DocBundleManagerContainer
);
