import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'ops_slack_channel',
  label: {
    default: 'OPS slack channel',
    short: 'OPS',
  },
});
