import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Components
import {GlobalHotKeys} from 'react-hotkeys';

// Events
import focusOnSearchEvt from '../../events/focusOnSearch.event.spotlight';

// Lib
import {lib} from '@matthahn/sally-ui';

// Alert
const {alert} = lib;

class Shortcuts extends Component {
  static propTypes = {
    username: PropTypes.string,
    dispatch: PropTypes.func,
  };

  state = {
    mounted: false,
  };

  componentDidMount() {
    this.setState({mounted: true});
  }

  keyMap = {
    test: 'option+Backspace',
    spotlight: 'shift+command+space',
  };

  handlers = {
    test: () => alert.info(`Come on now ${this.props.username}`),
    spotlight: () => this.openSpotlight(),
  };

  openSpotlight = () => focusOnSearchEvt.publish();

  render() {
    const {mounted} = this.state;
    return mounted ? (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers} />
    ) : null;
  }
}

export default connect((state) => ({username: state.authorization.username}))(
  Shortcuts
);
