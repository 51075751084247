import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const States = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grey};
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
  padding: 5px;
`;

export default States;
