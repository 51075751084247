// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder api
import createChangeOrderApi from '@matthahn/sally-fn/lib/changeOrder/api/create.api.changeOrder.';

// changeOrder components
import CreateChangeOrderModal from '../../components/CreateChangeOrderModal/CreateChangeOrderModal';

// changeOrder events
import changeOrderCreatedEvent from '../../events/created.event.changeOrder';
import showCreateChangeOrderEvent from '../../events/showCreateChangeOrder.event.changeOrder';

// date
import {parseISO, addDays} from 'date-fns';

// date lib
import getUpcomingFriday from '../../../date/lib/getUpcomingFriday.lib';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// mileagePackage lib
import getActiveMileagePackage from '../../../mileagePackage/lib/getActiveMileagePackage.lib.mileagePackage';
import shouldSelectMileagePackage from '../../../mileagePackage/lib/shouldSelectMileagePackage.lib.mileagePackage';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// reducedDeductible lib
import getActiveReducedDeductible from '../../../reducedDeductible/lib/getActive.lib.reducedDeductible';

// rental api
import getRentalByIdApi from '@matthahn/sally-fn/lib/rental/api/getByID.api.rental';

// rental states
import reservedRentalState from '@matthahn/sally-fn/lib/rental/state/reserved.state.rental';

// rentalRate api
import availableDatesApi from '@matthahn/sally-fn/lib/rentalRate/api/availableDates.api.rentalRate';

// rentalRate services
import getSuggestedRentalRateService from '../../../rentalRate/services/getSuggestedRentalRate.service.rentalRate';

// rentalRate attributes
import {
  description as descriptionAttr,
  effective_date as effectiveDateAttr,
  rate as rateAttr,
} from '@matthahn/sally-fn/lib/rentalRate/attributes';

// rentalRate lib
import {getRentalRateObject as getCurrentRate} from '@matthahn/sally-fn/lib/rentalRate/libs/currentRentalRate.lib.rentalRate';
import computeRates from '@matthahn/sally-fn/lib/rentalRate/libs/compute.lib.rentalRate';
import getLowestRate from '../../../rentalRate/lib/getLowestRate.lib.rentalRate';
import isRateBelowThreshold from '../../../rentalRate/lib/isRateBelowThreshold.lib.rentalRate';

// rentalRate permissions
import rentalRateOverridePermission from '../../../rentalRate/permissions/override.permission.rentalRate';

class CreateChangeOrderContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(showCreateChangeOrderEvent.subscribe(this.show));
  }

  state = {
    availableDates: [],
    creating: false,
    currentMileagePackage: null,
    currentRate: null,
    currentReducedDeductible: null,
    description: descriptionAttr(''),
    effective_date: effectiveDateAttr(''),
    loading: false,
    mileagePackage: null,
    rate: rateAttr(''),
    reducedDeductible: null,
    rental: null,
    suggestedRate: 0,
    visible: false,
  };

  show = async ({rentalId}) => {
    this.setState({visible: true, loading: true});

    try {
      const rental = await getRentalByIdApi(rentalId);

      const rates = computeRates({
        rental,
        rates: orderByDate([...rental.rates], 'effective_date', 'desc'),
      });

      const [availableDates, suggestedRate] = await Promise.all([
        availableDatesApi(rental.id),
        getSuggestedRentalRateService({rentalId}),
      ]);

      const currentRate = getCurrentRate(rates);
      const currentReducedDeductible = getActiveReducedDeductible(rental);
      const currentMileagePackage = getActiveMileagePackage(rental);

      this.setState({
        availableDates,
        currentMileagePackage,
        currentRate,
        currentReducedDeductible,
        description: descriptionAttr(''),
        effective_date: effectiveDateAttr(
          rental.state === reservedRentalState.key
            ? addDays(new Date(), 1)
            : getUpcomingFriday()
        ),
        loading: false,
        rate: rateAttr(''),
        reducedDeductible: null,
        mileagePackage: null,
        rental,
        suggestedRate,
      });
    } catch (error) {
      this.setState({visible: false});
    }
  };

  hide = () => {
    const {creating, loading} = this.state;
    if (creating || loading) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    const {creating, loading} = this.state;
    if (creating || loading) return;
    this.setState({[key]: value});
  };

  createChangeOrder = async ({promptRentalRate = true} = {}) => {
    const {
      creating,
      description: descriptionAttribute,
      effective_date: effectiveDateAttribute,
      loading,
      mileagePackage,
      rate,
      reducedDeductible,
      rental,
      suggestedRate,
    } = this.state;

    if (creating || loading) return;

    const rateDisplayValue = rate.input.format();
    const new_rental_rate = rate.api.format();
    const description = descriptionAttribute.api.format();
    const effective_date = effectiveDateAttribute.api.format();
    const new_deductible = !!reducedDeductible
      ? reducedDeductible?.deductible_amount
      : null;
    const new_deductible_rate = !!reducedDeductible
      ? reducedDeductible?.rate
      : null;
    const new_mileage_package_rate = !!mileagePackage
      ? mileagePackage.rate
      : null;
    const new_alloted_mileage = !!mileagePackage
      ? mileagePackage.alloted_mileage
      : null;

    const requireMileagePackage = shouldSelectMileagePackage({
      vehicle: rental.vehicle,
    });

    if (
      rateDisplayValue === '' &&
      !reducedDeductible &&
      (!requireMileagePackage || (requireMileagePackage && !mileagePackage))
    )
      return alert.info(
        'Enter a rate, select a damage waiver or select a mileage package.'
      );
    if (requireMileagePackage && !mileagePackage)
      return alert.info('Select a mileage package.');
    if (!effective_date) return alert.info('Enter an effective date.');
    if (
      !!suggestedRate &&
      !rentalRateOverridePermission() &&
      isRateBelowThreshold({
        rate: new_rental_rate,
        suggestedRate,
      })
    )
      return alert.warning(
        `You do not have permission to set the rate bellow $${getLowestRate({
          suggestedRate,
        })}.`
      );

    if (this.warnAboutRentalRate() && promptRentalRate)
      return notify({
        title: 'Confirmation',
        content:
          'This will set the rental rate to $0. Are you sure you want to continue?',
        primary: {
          label: 'Cancel',
          onClick: () => null,
        },
        secondary: {
          label: 'Continue',
          onClick: () => this.createChangeOrder({promptRentalRate: false}),
        },
      });

    this.setState({creating: true});

    try {
      const changeOrderBody = Object.entries({
        description,
        new_rental_rate,
        rental: rental.id,
        new_deductible,
        new_deductible_rate,
        effective_date,
        new_mileage_package_rate,
        new_alloted_mileage,
      }).reduce(
        (combined, [key, value]) =>
          value === null ? combined : {...combined, [key]: value},
        {}
      );
      const createdChangeOrder = await createChangeOrderApi(changeOrderBody);
      changeOrderCreatedEvent.publish({changeOrder: createdChangeOrder});
      this.setState({creating: false, visible: false});
      alert.success('Change order created.');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({creating: false});
    }
  };

  disabledDates = () => {
    const {availableDates} = this.state;
    return [
      {before: addDays(new Date(), 1)},
      ...[...availableDates]
        .map((date, index) => {
          const currentDate = parseISO(date);
          const dates = [];
          if (!index) dates.push({before: currentDate});
          if (index + 1 === availableDates.length)
            dates.push({after: currentDate});
          dates.push({
            after: currentDate,
            before: parseISO(availableDates[index + 1]),
          });
          return dates;
        })
        .flat(),
    ];
  };

  warnAboutRentalRate = () => {
    const {rate} = this.state;
    return rate.api.format() === 0;
  };

  render() {
    const {
      creating,
      currentMileagePackage,
      currentRate,
      currentReducedDeductible,
      description,
      effective_date,
      loading,
      mileagePackage,
      rate,
      reducedDeductible,
      rental,
      suggestedRate,
      visible,
    } = this.state;
    return (
      <CreateChangeOrderModal
        // displayEffectiveDate={rental?.state !== reservedRentalState.key}
        creating={creating}
        currentMileagePackage={currentMileagePackage}
        currentRate={currentRate}
        currentReducedDeductible={currentReducedDeductible}
        description={description}
        disabledDates={this.disabledDates()}
        displayEffectiveDate={false}
        displayRentalRateWarning={this.warnAboutRentalRate()}
        effective_date={effective_date}
        loading={loading}
        mileagePackage={mileagePackage}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.createChangeOrder}
        rate={rate}
        reducedDeductible={reducedDeductible}
        rental={rental}
        showMileagePackage={shouldSelectMileagePackage({
          vehicle: rental?.vehicle,
        })}
        suggestedRate={suggestedRate}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(CreateChangeOrderContainer);
