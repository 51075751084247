// api lib
import getApiEndpoint from '../../api/lib/getEndpoint.lib.api';

// auth lib
import getAuthToken from '../../auth/lib/getAuthToken.lib.auth';

// samson api
import getTicketById from '@matthahn/sally-fn/lib/samson/api/getTicketById.api.samson';

const getTicketByIdApi = ({
  ticketId,
  token = getAuthToken('samson'),
  url = getApiEndpoint('samson'),
}) => getTicketById({ticketId, token, url});

export default getTicketByIdApi;
