import React from 'react';
import styled from 'styled-components';

// Components
import {Icon} from '@matthahn/sally-ui';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {red},
} = lib;

const Container = styled.div`
  width: 100%;
  color: ${red} !important;
  font-size: 20px;
  text-align: center;
`;

const RowLoader = () => (
  <Container>
    <Icon icon="loading2" spin />
  </Container>
);

export default RowLoader;
