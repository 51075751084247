import React, {Component} from 'react';
import PropTypes from 'prop-types';

// gas components
import Section from '../Section/Section';

// types
import {commaSeperatedNumber} from '@matthahn/sally-fn/lib/types';

// vehicle lib
import isEVVehicle from '@matthahn/sally-fn/lib/vehicle/libs/isEV.lib.vehicle';

class Oil extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {gas, section, vehicle} = this.props;
    return isEVVehicle(vehicle) ? null : (
      <Section section={section} gas={gas}>
        {!!gas.last_oil_change_mileage
          ? commaSeperatedNumber(gas.last_oil_change_mileage).format()
          : '-'}
      </Section>
    );
  }
}

export default Oil;
