const DEFAULT_STATE = {
  branch: null,
  disabled: false,
  done: 0,
  generating: false,
  goingBack: false,
  hide: false,
  minimized: false,
  step: 1,
  visible: false,
};

export default DEFAULT_STATE;
