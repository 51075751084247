// date lib
import convertHoursIntoHourMinutes from '../../../lib/convertHoursIntoHourMinutes.lib.date';

// math lib
import divide from '@matthahn/sally-fw/lib/lib/divide';
import sum from '@matthahn/sally-fw/lib/lib/sum';

const getHourOptions = ({
  currentTime = 0,
  minuteInterval = 15,
  options = [],
}) => {
  if (currentTime >= 24) return options;

  const option = {
    value: `${currentTime}`,
    label: convertHoursIntoHourMinutes(currentTime),
  };

  const updatedOptions = [...options, option];
  const nextTime = sum(currentTime, divide(minuteInterval, 60));
  return getHourOptions({
    currentTime: nextTime,
    minuteInterval,
    options: updatedOptions,
  });
};

export default getHourOptions;
