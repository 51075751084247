import styled from 'styled-components';

// styling
import visibility from '../../../../styling/visibility.styling';

const Container = styled.div`
  width: 400px;
  height: 100vh;
  z-index: 10000;
  position: fixed;
  padding-top: 70px;
  top: 0px;
  right: 0px;
  ${({visible}) => visibility({visible, transform: 'translateX(100%)'})};
`;

export default Container;
