import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// types
import formattedInteger from '@matthahn/sally-fw/lib/type/types/formattedInteger.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'app_reservations_per_hour',
  display: formattedInteger,
  input: formattedInteger,
  api: number,
  label: {
    default: '# of App Reservations per Hour',
    short: '# of App Reservations per Hour',
  },
});
