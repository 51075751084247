import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// document api
import listDocumentsApi from '@matthahn/sally-fn/lib/document/api/list.api.document';

// document files
import vehicleDamageFile from '@matthahn/sally-fn/lib/gas/documents/folders/vehicleDamage.document.gas';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// gas api
import getGasById from '@matthahn/sally-fn/lib/gas/api/getById.api.gas';

// gas components
import AfterCompletion from '../../components/AfterCompletion/AfterCompletion';
import GasModal from '../../components/GasModal/GasModal';
import Sections from '../../components/Sections/Sections';

// gas events
import showGasEvent from '../../events/showGas.event.gas';

// gas lib
import extractGasImages from '../../lib/extractImages.lib.gas';

// gas sections
import {sectionsObject} from '../../sections/sections.gas';

// gas types
import {getType} from '../../types/types.gas';

class GasContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    loading: false,
    gas: null,
    repairImages: [],
    vehicle: null,
    sections: {},
  };

  componentDidMount() {
    this.props.subscribe(showGasEvent.subscribe(this.show));
  }

  show = async ({gasId, vehicle}) => {
    if (!gasId || !vehicle) return;
    this.setState({visible: true, loading: true, vehicle, repairImages: []});

    try {
      const gas = await getGasById(gasId);
      if (!gas.type) {
        alert.info('Can not preview old GAS');
        this.setState({loading: false, visible: false});
        return;
      }
      const {results: repairImages} = await listDocumentsApi({
        gas: gas.id,
        type: vehicleDamageFile.type,
      });
      const sections = sectionsObject({vehicle, gas});
      this.setState({loading: false, gas, sections, repairImages});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, visible: false});
    }
  };

  hide = () => {
    const {visible, loading} = this.state;
    if (!visible || loading) return;
    this.setState({visible: false});
  };

  showLoader = () => {
    const {loading, gas, vehicle} = this.state;
    return loading || !gas || !vehicle;
  };

  render() {
    const {visible, gas, sections, vehicle, repairImages} = this.state;

    const defaultProps = {vehicle, gas, sections};
    return (
      <GasModal
        visible={visible}
        loading={this.showLoader()}
        gas={gas}
        branch={vehicle?.sally_branch}
        onClose={this.hide}
        images={
          this.showLoader()
            ? []
            : extractGasImages({gas, vehicle, repairImages})
        }
      >
        {!this.showLoader() && (
          <Sections sections={sections} {...defaultProps} />
        )}
        {!this.showLoader() && (
          <AfterCompletion gasType={getType(gas.type)} {...defaultProps} />
        )}
      </GasModal>
    );
  }
}

export default subscriptionHOC(GasContainer);
