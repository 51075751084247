import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// dealership actions
import {set as setAct} from '../../redux/actions';

// dealership events
import dealershipCreatedEvent from '../../events/created.event.dealership';
import dealershipUpdatedEvent from '../../events/updated.event.dealership';

// dealership sockets
import dealershipCreatedSocket from '../../sockets/created.socket.dealership';
import dealershipUpdatedSocket from '../../sockets/updated.socket.dealership';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class DealershipListenerContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(
      dealershipCreatedSocket.subscribe(this.dealershipCreated),
      dealershipUpdatedSocket.subscribe(this.dealershipUpdated),
      dealershipCreatedEvent.subscribe(this.dealershipCreated),
      dealershipUpdatedEvent.subscribe(this.dealershipUpdated)
    );
  }

  dealershipCreated = (dealership) => {
    const {dealerships, dispatch} = this.props;
    const updatedDealerships = [...dealerships, dealership];
    dispatch(setAct({dealerships: updatedDealerships}));
  };

  dealershipUpdated = (dealership) => {
    const {dealerships, dispatch} = this.props;
    const updatedDealerships = [...dealerships].map((existingDealership) =>
      existingDealership.id === dealership.id ? dealership : existingDealership
    );
    dispatch(setAct({dealerships: updatedDealerships}));
  };

  render() {
    return null;
  }
}

export default subscriptionHOC(
  connect((state) => ({dealerships: state.dealership.dealerships}))(
    DealershipListenerContainer
  )
);
