import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// chicagoTicket api
import uploadTeslaChicagoTicketsApi from '@matthahn/sally-fn/lib/chicagoTicket/api/upload.api.chicagoTicket';

// chicagoTicket components
import UploadChicagoTicketsModal from '../../components/UploadChicagoTicketsModal/UploadChicagoTicketsModal';

// lib
import downloadFile from '../../../libs/downloadFile';

class UploadChicagoTicketsContainer extends Component {
  static defaultProps = {
    children: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
  };

  show = () => this.setState({visible: true});

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  upload = async ([file]) => {
    const {loading} = this.state;
    if (loading || !file) return;
    this.setState({loading: true});
    try {
      const fileName = 'chicagoTickets.csv';
      const formMeta = new FormData();
      formMeta.append('ticket_file', file, fileName);
      const chicagoTickets = await uploadTeslaChicagoTicketsApi(formMeta);
      downloadFile(chicagoTickets, `Chicago Tickets.txt`);
      alert.success('Chicago Tickets Uploaded');
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {children} = this.props;
    const {loading, visible} = this.state;
    return (
      <Fragment>
        {children({showModal: this.show})}
        <UploadChicagoTicketsModal
          loading={loading}
          visible={visible}
          onClose={this.hide}
          onUpload={this.upload}
        />
      </Fragment>
    );
  }
}

export default UploadChicagoTicketsContainer;
