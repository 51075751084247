import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {black},
} = lib;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0px 56px;
  margin: 0px;
  display: block;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: 20px;
  color: ${black};
  background: none;
`;

export default Input;
