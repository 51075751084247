import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Button,
  CheckBox,
  Icon,
  InputTyping,
  Table,
  Modal,
  Portal,
} from '@matthahn/sally-ui';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// Columns
import columns from './columns';

// Types
import {phoneNumber} from '@matthahn/sally-fw/lib/type';

const SelectDriverStep = ({
  driver,
  drivers,
  loading,
  onClose,
  onDriver,
  onSearch,
  search,
  visible,
}) => (
  <Modal
    title="Select driver"
    icon="user"
    onClose={onClose}
    visible={visible}
    portal={Portal.assetsContainer.secondary}
  >
    {(Container) => (
      <Fragment>
        <Container>
          <InputTyping
            value={search}
            onChange={onSearch}
            placeholder="Search"
            preIcon="search1"
            postValue={loading ? <Icon icon="loading2" spin /> : null}
          />
        </Container>
        {(!!search.length || !!driver) && (
          <Container padding="none" noBorder>
            <Table
              columns={columns}
              loading={loading}
              noBorder
              noRadius
              smallNoResultsLabel
            >
              {(TableRow) =>
                [
                  ...drivers.map((driverToDisplay) => (
                    <TableRow
                      key={driverToDisplay.id}
                      onClick={onDriver(driverToDisplay)}
                    >
                      {(TableColumn) => [
                        <TableColumn key="name">
                          <CheckBox
                            value={driverToDisplay.id === driver?.id}
                            onChange={() => null}
                            size="small"
                          />{' '}
                          {driverToDisplay.first_name}{' '}
                          {driverToDisplay.last_name}
                        </TableColumn>,
                        <TableColumn key="fhv">
                          {!!driverToDisplay.phone_number
                            ? phoneNumber(driverToDisplay.phone_number).format()
                            : '-'}
                        </TableColumn>,
                      ]}
                    </TableRow>
                  )),
                ].filter((row) => !!row)
              }
            </Table>
          </Container>
        )}
        <Container>
          <StepFooter
            right={
              <Button size="small" onClick={onClose}>
                Next
              </Button>
            }
          />
        </Container>
      </Fragment>
    )}
  </Modal>
);

SelectDriverStep.propTypes = {
  driver: PropTypes.object,
  drivers: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onDriver: PropTypes.func,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  visible: PropTypes.bool,
};

export default SelectDriverStep;
