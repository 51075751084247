// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Info from '@matthahn/sally-ui/lib/components/Info/Info';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Text from '@matthahn/sally-ui/lib/components/Text/Text';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// legacy components
import {AttributeInput} from '../../../components/components/atoms/AttributeInput';

// lib
import fkOrId from '../../../libs/fkOrId';

// mileagePackage components
import SelectMileagePackage from '../../../mileagePackage/components/SelectMileagePackage/SelectMileagePackage';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

// reducedDeductible components
import ReducedDeductibleOptionSelect from '../../../reducedDeductible/components/ReducedDeductibleOptionSelect/ReducedDeductibleOptionSelect';

// rentalRate lib
import netRentalRate from '@matthahn/sally-fn/lib/rentalRate/libs/netRentalRate.lib.rentalRate';

// types
import {
  amount as amountType,
  formattedInteger as formattedIntegerType,
  commaSeperatedNumber as commaSeperatedNumberType,
  phoneNumber as phoneNumberType,
} from '@matthahn/sally-fn/lib/types';

const AppRentalLaunchModal = ({
  currentMileagePackage,
  currentRate,
  currentReducedDeductible,
  driver,
  loading,
  mileagePackage,
  onChange,
  onClose,
  onSave,
  rate,
  reducedDeductible,
  rental,
  saving,
  showMileagePackage,
  suggestedRate,
  vehicle,
  visible,
  onMvr,
}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="App Reservation"
    size="large"
    buttonsRight={[{label: 'Save', loading: saving, onClick: onSave}]}
  >
    {(Content) =>
      loading || !rental ? (
        <ModalLoader />
      ) : (
        <Fragment>
          {!!onMvr && (
            <Content padding="none">
              <Info
                type="warning"
                action={{label: 'Driver profile', onClick: onMvr}}
                flat
              >
                Run MVR on drivers profile
              </Info>
            </Content>
          )}
          <Content>
            <Row margin>
              <Column>
                Contact {driver?.first_name} {driver?.last_name} to confirm the
                details about the reservation
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 3}>
                <Text value={vehicle?.svid}>Vehicle</Text>
              </Column>
              <Column size={1 / 3}>
                <Text value={`${driver?.first_name} ${driver?.last_name}`}>
                  Driver
                </Text>
              </Column>
              <Column size={1 / 3}>
                <a href={`tel:${driver?.phone_number}`}>
                  <Text value={phoneNumberType(driver?.phone_number).format()}>
                    Phone Number
                  </Text>
                </a>
              </Column>
            </Row>
            <Row margin>
              <Column size={1 / 3}>
                <Text
                  value={`$${amountType(
                    netRentalRate(currentRate?.rate)
                  ).format()}`}
                >
                  Current Rental Rate
                </Text>
              </Column>
              {!!suggestedRate && (
                <Column size={1 / 3}>
                  <Text value={`$${amountType(suggestedRate).format()}`}>
                    Suggested Rental Rate
                  </Text>
                </Column>
              )}
              <Column size={!!suggestedRate ? 1 / 3 : 3 / 3}>
                <AttributeInput
                  value={rate}
                  onChange={onChange}
                  disabled={saving}
                >
                  New Rental Rate
                </AttributeInput>
              </Column>
            </Row>
            <Row margin>
              <Column size={showMileagePackage ? 1 / 3 : 1}>
                <Text
                  value={commaSeperatedNumberType(
                    rental?.avg_daily_calamp_miles
                  ).format()}
                >
                  Average Daily Miles
                </Text>
              </Column>
              {showMileagePackage && (
                <Column size={1 / 3}>
                  <Text
                    value={
                      !!currentMileagePackage
                        ? `Mileage: ${formattedIntegerType(
                            currentMileagePackage.alloted_mileage
                          ).format()} Rate: $${amountType(
                            currentMileagePackage.rate
                          ).format()}`
                        : '-'
                    }
                  >
                    Current Mileage Package
                  </Text>
                </Column>
              )}
              {showMileagePackage && (
                <Column size={1 / 3}>
                  <SelectMileagePackage
                    branch={fkOrId(
                      rental?.vehicle?.sally_branch || rental?.sally_branch
                    )}
                    dealership={fkOrId(
                      rental?.vehicle?.sally_dealership ||
                        rental?.sally_dealership
                    )}
                    disabled={saving}
                    onChange={(value) => onChange(value, 'mileagePackage')}
                    mileagePackage={mileagePackage}
                  />
                </Column>
              )}
            </Row>
            <Row>
              <Column size={1 / 2}>
                <Text
                  value={`Deductible: $${amountType(
                    currentReducedDeductible?.deductible_amount || 0
                  ).format()} Rate: $${amountType(
                    currentReducedDeductible?.rate || 0
                  ).format()}`}
                >
                  Current Damage Waiver
                </Text>
              </Column>
              <Column size={1 / 2}>
                <ReducedDeductibleOptionSelect
                  disabled={saving}
                  label="New Damage Waiver"
                  onChange={(value) => onChange(value, 'reducedDeductible')}
                  reducedDeductible={reducedDeductible}
                />
              </Column>
            </Row>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

AppRentalLaunchModal.propTypes = {
  currentMileagePackage: PropTypes.object,
  currentRate: PropTypes.object,
  currentReducedDeductible: PropTypes.object,
  driver: PropTypes.object,
  loading: PropTypes.bool,
  mileagePackage: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onMvr: PropTypes.func,
  onSave: PropTypes.func,
  rate: PropTypes.object,
  reducedDeductible: PropTypes.object,
  rental: PropTypes.object,
  saving: PropTypes.bool,
  showMileagePackage: PropTypes.bool,
  suggestedRate: PropTypes.number,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
};

export default AppRentalLaunchModal;
