import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {Row, Column, CheckBox, Text} from '@matthahn/sally-ui';
import Section from '../Section/Section';

import Tyre from './components/Tyre';

class Tires extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  change = () => {};

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas}>
        <Row margin>
          <Column size={1 / 2}>
            <Tyre
              penny={gas.tire_ok_fl}
              name="Front Left"
              onClick={this.change}
            >
              <Text size="large" value={gas.tire_pressure_fl} />
            </Tyre>
          </Column>
          <Column size={1 / 2}>
            <Tyre
              penny={gas.tire_ok_fr}
              name="Front Right"
              onClick={this.change}
            >
              <Text size="large" value={gas.tire_pressure_fr} />
            </Tyre>
          </Column>
        </Row>
        <Row margin>
          <Column size={1 / 2}>
            <Tyre penny={gas.tire_ok_rl} name="Rear Left" onClick={this.change}>
              <Text size="large" value={gas.tire_pressure_rl} />
            </Tyre>
          </Column>
          <Column size={1 / 2}>
            <Tyre
              penny={gas.tire_ok_rr}
              name="Rear Right"
              onClick={this.change}
            >
              <Text size="large" value={gas.tire_pressure_rr} />
            </Tyre>
          </Column>
        </Row>
        <Row>
          <Column>
            <CheckBox
              size="giant"
              value={gas.spare_tires_and_tools}
              onChange={this.change}
              labelProps={{big: true}}
            >
              Spare tires and tools present
            </CheckBox>
          </Column>
        </Row>
      </Section>
    );
  }
}

export default Tires;
