// Constants
import {REDUX_SET} from './constants';

// Api
import api from './api';

// driver api
import getDriverRateAddonApi from '@matthahn/sally-fn/lib/driver/api/getRateAddon.api.driver';

// libs
import getActiveRental from '../../../../libs/getActiveRental';

export const setDriver = (driver) => async (dispatch, getState) => {
  if (!driver)
    return dispatch({
      type: REDUX_SET,
      data: {driver, loadingDriverRateAddon: false, driverRateAddon: 0},
    });

  dispatch({type: REDUX_SET, data: {driver, loadingDriverRateAddon: true}});

  try {
    const response = await getDriverRateAddonApi(driver.id);
    if (getState().turnover.driver?.id !== driver.id) return;
    dispatch({
      type: REDUX_SET,
      data: {
        loadingDriverRateAddon: false,
        driverRateAddon: Number(response?.rate) || 0,
      },
    });
  } catch (error) {
    if (getState().turnover.driver?.id !== driver.id) return;
    dispatch({
      type: REDUX_SET,
      data: {loadingDriverRateAddon: false, driverRateAddon: 0},
    });
  }
};

export const setBranch = (branch) => ({
  type: REDUX_SET,
  data: {
    branch,
    dealership: null,
    driver: null,
    loadingDriverRateAddon: false,
    driverRateAddon: 0,
  },
});

export const setDealership = (dealership) => ({
  type: REDUX_SET,
  data: {dealership},
});

export const search = (search) => ({
  type: REDUX_SET,
  data: {search},
});

export const getTurnoverData = (newBranch) => async (dispatch, getState) => {
  const {loading, branch, turnovers: currentTurnovers} = getState().turnover;

  if (loading) return;

  const sameBranch = newBranch?.id === branch?.id;
  if (!sameBranch) dispatch(setBranch(newBranch));

  dispatch({
    type: REDUX_SET,
    data: {loading: true, turnovers: sameBranch ? currentTurnovers : []},
  });
  const turnovers = await api(newBranch);
  dispatch({type: REDUX_SET, data: {turnovers, loading: false}});
};

export const add = (turnover) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers, {...turnover}];

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const update = (turnover) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].map((t) =>
    t.id === turnover.id ? turnover : t
  );

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const updatePart = (id, turnoverPart) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].map((t) =>
    t.id === id ? {...t, ...turnoverPart} : t
  );

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const remove = (id) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].filter((t) => t.id !== id);

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const addRental = (id, rental) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].map((t) =>
    t.id === id
      ? {
          ...t,
          rentals: [...t.rentals, {...rental}],
        }
      : t
  );

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const updateRental = (id, rental) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].map((t) =>
    t.id === id
      ? {
          ...t,
          rentals: [...t.rentals].map((r) => (r.id === rental.id ? rental : r)),
        }
      : t
  );

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const removeRental = (id, rentalID) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;

  const newTurnovers = [...turnovers].map((t) =>
    t.id === id
      ? {
          ...t,
          rentals: [...t.rentals].filter((r) => r.id !== rentalID),
        }
      : t
  );

  dispatch({type: REDUX_SET, data: {turnovers: newTurnovers}});
};

export const filterTurnover = (filter = null) => ({
  type: REDUX_SET,
  data: {filter},
});

export const launch = (id) => (dispatch, getState) => {
  const {turnovers} = getState().turnover;
  const rawTurnover = [...turnovers].find((t) => t.id === id);
  const newTurnovers = [...turnovers].filter((t) => t.id !== id);

  if (!rawTurnover) return;

  const turnover = {...rawTurnover, state: 'active'};
  const rental = getActiveRental(turnover);

  dispatch({
    type: REDUX_SET,
    data: {
      turnovers: !rental.end_date
        ? [...newTurnovers]
        : [...newTurnovers, {...turnover}],
    },
  });
};
