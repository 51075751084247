import React, {Component} from 'react';

// alerts
import alert from '@matthahn/sally-ui/lib/libs/alert';

// api
import {isIn} from '../../../api/queries/queries';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import downloadFile from '../../../libs/downloadFile';

// propTypes
import PropTypes from 'prop-types';

// samson api
import getSamsonInvoiceApi from '@matthahn/sally-fn/lib/vehicle/api/getSamsonInvoice.api.vehicle';
import getMechanicByIdApi from '../../api/getMechanicById.api.samson';
import getTicketByIdApi from '../../api/getTicketById.api.samson';
import listServicesApi from '../../api/listServices.api.samson';

// samson components
import SamsonTicketModal from '../../components/SamsonTicketModal/SamsonTicketModal';

// samson events
import showSamsonEvent from '../../events/showSamson.event.samson';

// samson lib
import attachServiceToItem from '../../lib/attachService.lib.item';
import isServiceDiscount from '../../lib/isServiceDiscount.lib.samson';

class SamsonTicketContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    downloading: false,
    loading: true,
    mechanic: null,
    ticket: null,
    vehicle: null,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showSamsonEvent.subscribe(this.show));
  }

  show = async ({samsonId, vehicle}) => {
    if (!samsonId || !vehicle) return;
    this.setState({visible: true, loading: true, vehicle});

    try {
      const ticket = await getTicketByIdApi({ticketId: samsonId});
      const serviceIds = [...ticket.items]
        .map(({service}) => service)
        .join(',');
      const [{results: services}, mechanic] = await Promise.all([
        !!serviceIds
          ? listServicesApi({query: {[isIn('id')]: serviceIds}})
          : {results: []},
        this.getMechanic(fkOrId(ticket.mechanic)),
      ]);
      const ticketWithServices = {
        ...ticket,
        items: [...ticket.items].map((item) =>
          attachServiceToItem({item, services})
        ),
      };
      this.setState({
        loading: false,
        ticket: ticketWithServices,
        mechanic,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false, visible: false});
    }
  };

  hide = () => {
    const {loading, downloading, visible} = this.state;
    if (loading || downloading || !visible) return;
    this.setState({visible: false});
  };

  getMechanic = async (mechanicId) => {
    try {
      const mechanic = await getMechanicByIdApi({mechanicId});
      return mechanic;
    } catch (error) {
      return null;
    }
  };

  downloadInvoice = async () => {
    const {ticket, downloading} = this.state;

    if (downloading || !ticket.invoice_url) return;

    this.setState({downloading: true});

    try {
      const invoice = await getSamsonInvoiceApi(ticket.invoice_url);
      downloadFile(invoice, `Ticket #${ticket.id} Invoice.pdf`);
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
    }

    if (!this.mounted) return;
    this.setState({downloading: false});
  };

  loading = () => {
    const {loading, ticket, vehicle} = this.state;
    return loading || !ticket || !vehicle;
  };

  items = () => {
    const {ticket} = this.state;
    return !!ticket
      ? [...ticket.items]
          .filter(({service}) => !isServiceDiscount(service))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  discounts = () => {
    const {ticket} = this.state;
    return !!ticket
      ? [...ticket.items]
          .filter(({service}) => isServiceDiscount(service))
          .sort((a, b) => a.id - b.id)
      : [];
  };

  render() {
    const {downloading, mechanic, ticket, vehicle, visible} = this.state;
    return (
      <SamsonTicketModal
        discounts={this.discounts()}
        downloading={downloading}
        items={this.items()}
        loading={this.loading()}
        mechanic={mechanic}
        onClose={this.hide}
        onDownload={this.downloadInvoice}
        ticket={ticket}
        vehicle={vehicle}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(SamsonTicketContainer);
