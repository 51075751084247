import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// branch constants
import STATES from '../../branch/constants/states.constant.branch';

export default attribute({
  type: SELECT,
  attribute: 'garage_state',
  label: {
    default: 'Garage State',
    short: 'State',
  },
  options: [...STATES],
});
