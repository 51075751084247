import {addWeeks, setDay, format, getDay} from 'date-fns';

function getUpcomingFriday({currentDate = new Date(), dateFormat = null} = {}) {
  const thisWeeksFriday = setDay(currentDate, 5);

  const upcomingFriday =
    getDay(currentDate) >= 5 ? addWeeks(currentDate, 1) : thisWeeksFriday;

  return !!dateFormat ? format(upcomingFriday, dateFormat) : upcomingFriday;
}

export default getUpcomingFriday;
