// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// driver redux actions
import {set as setAction} from '../../../components/containers/templates/CustomerEditContainer/actions';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// rental api
import repoRentalApi from '@matthahn/sally-fn/lib/rental/api/repo.api.rental';

// rental attributes
import customRepoReasonAttribute from '@matthahn/sally-fn/lib/rental/attributes/custom_repo_reason.attribute.rental';
import repoReasonAttribute from '@matthahn/sally-fn/lib/rental/attributes/repo_reason.attribute.rental';
import repoStartDateAttribute from '@matthahn/sally-fn/lib/rental/attributes/repo_start_date.attribute.rental';

// rental components
import RepoRentalModal from '../../components/RepoRentalModal/RepoRentalModal';

// rental events
import showRentalRepoEvent from '../../events/showRepoModal.event.rental';

// rental lib
import isRentalRepo from '../../lib/isRepo.lib.rental';

// rental permissions
import repoRentalPermission from '../../permissions/repoRental.permission.rental';

// rental preparations
import repoRentalPreparation from '@matthahn/sally-fn/lib/rental/preparation/repo.preparation.rental';

class RepoRentalContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
    user: PropTypes.object,
  };

  state = {
    custom_repo_reason: customRepoReasonAttribute(''),
    rental: null,
    repo_reason: repoReasonAttribute(''),
    repo_start_date: repoStartDateAttribute(new Date()),
    saving: false,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showRentalRepoEvent.subscribe(this.show));
  }

  show = ({rental = null} = {}) => {
    if (!repoRentalPermission())
      return alert.warning('You do not have permission to repo this vehicle');
    this.setState({
      custom_repo_reason: customRepoReasonAttribute(''),
      rental,
      repo_reason: repoReasonAttribute(''),
      repo_start_date: repoStartDateAttribute(new Date()),
      visible: true,
    });
  };

  hide = () => {
    if (this.state.saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = () => {
    const {rental, saving} = this.state;
    if (!rental || saving) return;
    const fn = isRentalRepo(rental) ? this.clearRepo : this.repoRental;
    fn();
  };

  repoRental = async () => {
    const {user} = this.props;
    const {
      custom_repo_reason,
      rental,
      repo_reason,
      repo_start_date,
      saving,
    } = this.state;
    if (!rental || saving) return;

    this.setState({saving: true});

    try {
      const rentalBody = await repoRentalPreparation({
        custom_repo_reason,
        repo_reason,
        repo_start_date,
      });
      const rentalProps = await repoRentalApi(rental.id, {
        ...rentalBody,
        repo_initiated_by: user.id,
      });
      this.updateActiveRental(rentalProps);
      alert.success('Repo initiated');
      this.setState({saving: false, visible: false});
    } catch (e) {
      const {message} = parseError(e);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  clearRepo = async () => {
    const {user} = this.props;
    const {rental, saving} = this.state;
    if (!rental || saving) return;

    this.setState({saving: true});

    try {
      const rentalProps = await repoRentalApi(rental.id, {
        repo_start_date: null,
        repo_initiated_by: user.id,
      });
      this.updateActiveRental({...rentalProps, repo_initiated_by: {...user}});
      alert.success('Repo cleared');
      this.setState({saving: false, visible: false});
    } catch (e) {
      const {message} = parseError(e);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  updateActiveRental = (rentalProps) => {
    const {dispatch} = this.props;
    const {rental} = this.state;
    const updatedActiveRental = {
      ...rental,
      ...rentalProps,
    };
    dispatch(setAction({activeRental: updatedActiveRental}));
  };

  render() {
    const {
      custom_repo_reason,
      rental,
      repo_reason,
      repo_start_date,
      saving,
      visible,
    } = this.state;

    return (
      <RepoRentalModal
        custom_repo_reason={custom_repo_reason}
        isRentalRepo={isRentalRepo(rental)}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        rental={rental}
        repo_reason={repo_reason}
        repo_start_date={repo_start_date}
        saving={saving}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({user: state.authorization.userData}))(
  subscriptionHOC(RepoRentalContainer)
);
