import React from 'react';
import PropTypes from 'prop-types';

import {P} from '@matthahn/sally-ui';

const LoadMoreRow = ({children, ...props}) => (
  <P theme="blue" weight="medium" align="center" {...props}>
    load more
  </P>
);

LoadMoreRow.propTypes = {
  children: PropTypes.node,
};

LoadMoreRow.defaultProps = {
  children: 'load more',
};

export default LoadMoreRow;
