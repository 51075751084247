// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch api
import createBranchApi from '@matthahn/sally-fn/lib/branch/api/create.api.branch';

// branch components
import BranchSaveStep from '../../components/BranchSaveStep/BranchSaveStep';

// branch events
import branchCreatedEvent from '../../events/created.event.branch';

// branch lib
import generateLocationObject from '../../lib/generateLocationObject.lib.branch';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// insurancePolicy api
import createInsurancePolicyApi from '@matthahn/sally-fn/lib/insurancePolicy/api/create.api.insurancePolicy';

// map api
import geocodeApi from '@matthahn/sally-fn/lib/map/api/geocode.api.map';

// map constants
import getGoogleMapApiKey from '../../../map/constants/googleMapApiKey.constant.map';

// map lib
import getLocation from '../../../map/lib/getLocation.lib.map';
import prepareAddress from '../../../map/lib/prepareAddress.lib.map';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class BranchCreateStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    loading: false,
  };

  back = () => {
    const {onBack} = this.props;
    const {loading} = this.state;
    if (loading) return;
    onBack();
  };

  save = async () => {
    const {
      branch: {vehicle_insurance_policy, ...branch},
      onFinish,
    } = this.props;

    if (this.state.loading) return;

    this.setState({loading: true});

    try {
      const geoLocation = await geocodeApi({
        key: getGoogleMapApiKey(),
        address: prepareAddress({
          address: branch.street_address1,
          city: branch.city,
          zip: branch.zip,
          state: branch.state,
        }),
      });
      branch.gps_location = generateLocationObject(getLocation(geoLocation));
      const createdBranch = await createBranchApi(branch);
      const createdInsurancePolicy = await createInsurancePolicyApi({
        ...vehicle_insurance_policy,
        sally_branch: createdBranch.id,
      });
      branchCreatedEvent.publish({
        ...createdBranch,
        vehicle_insurance_policy: createdInsurancePolicy,
      });
      onFinish();
      notify({
        id: 'branchCreated',
        title: 'Success',
        icon: undefined,
        content:
          'Branch successfully created. Would you like to create a contract or a dealership?',
        primary: {
          label: 'Done',
          onClick: () => null,
        },
        additionalButtons: [
          {
            label: 'Create Contract',
            onClick: () => null,
          },
          {
            label: 'Create Dealership',
            onClick: () => null,
          },
        ],
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {Container} = this.props;
    const {loading} = this.state;
    return (
      <BranchSaveStep
        Container={Container}
        loading={loading}
        onBack={this.back}
        onSave={this.save}
      />
    );
  }
}

export default BranchCreateStepContainer;
