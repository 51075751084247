import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
// import Card from './components/Card';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
  z-index: 10;
`;

const Content = styled.div`
  width: 100%;
  max-width: 260px;
`;

const AuthorizationCard = ({children}) => (
  <Container>
    <Content>{children}</Content>
  </Container>
);

AuthorizationCard.propTypes = {
  children: PropTypes.node,
};

export default AuthorizationCard;
