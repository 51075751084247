import styled from 'styled-components';

// colors
import {white, red} from '@matthahn/sally-ui/lib/libs/colors';

const Charged = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  padding: 3px;
  border-radius: 3px;
  font-size: 12px;
  color: ${white};
  background: ${red};
`;

export default Charged;
