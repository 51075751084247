// lib
import multiply from '@matthahn/sally-fw/lib/lib/multiply';

const itemPrice = ({cost, quantity, tax_rate, withTax = false}) => {
  const taxRate = !!tax_rate ? Number(tax_rate) : 0;
  const taxMultiplier = 1 + (withTax ? taxRate : 0);
  const price = multiply(cost, taxMultiplier, quantity);
  return price;
};

export default itemPrice;
