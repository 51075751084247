// branch components
import BranchGasStep from '../../components/BranchGasStep/BranchGasStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// gas sections
import sections from '@matthahn/sally-fn/lib/gas/sections/sections';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class BranchGasStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    offboard: [],
    onboard: [],
    onsite_sitting: [],
    onsite: [],
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      offboard: !!branch?.gas_config?.offboard
        ? Array.isArray(branch.gas_config.offboard)
          ? branch.gas_config.offboard
          : [branch.gas_config.offboard]
        : [],
      onboard: !!branch?.gas_config?.onboard
        ? Array.isArray(branch.gas_config.onboard)
          ? branch.gas_config.onboard
          : [branch.gas_config.onboard]
        : [],
      onsite_sitting: !!branch?.gas_config?.onsite_sitting
        ? Array.isArray(branch.gas_config.onsite_sitting)
          ? branch.gas_config.onsite_sitting
          : [branch.gas_config.onsite_sitting]
        : [],
      onsite: !!branch?.gas_config?.onsite
        ? Array.isArray(branch.gas_config.onsite)
          ? branch.gas_config.onsite
          : [branch.gas_config.onsite]
        : [],
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = ['gas_config'];
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  generateBranch = () => {
    const {branch} = this.props;
    const {offboard, onboard, onsite_sitting, onsite} = this.state;
    return {
      ...branch,
      gas_config: {
        offboard,
        onboard,
        onsite_sitting,
        onsite,
      },
    };
  };

  continue = () => {
    const {onNext} = this.props;
    const branch = this.generateBranch();
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = async () => {
    const {onBack} = this.props;
    const branch = this.generateBranch();
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  stepChangeValidator = async () => {
    const branch = await this.generateBranch();
    return {
      shouldContinue: true,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = ({type, section}) => () => {
    this.changedAttributes = ['gas_config'];
    const settings = this.state[type].includes(section)
      ? [...this.state[type]].filter((typeSection) => typeSection !== section)
      : [...this.state[type], section];
    this.setState({[type]: settings});
  };

  sections = () => {
    const preparedSections = [...sections].map(({key, label, icon}) => ({
      key,
      label,
      icon,
    }));
    return [
      {
        type: 'onboard',
        label: 'Onboard',
        sections: [...preparedSections],
      },
      {
        type: 'offboard',
        label: 'Offboard',
        sections: [...preparedSections],
      },
      {
        type: 'onsite',
        label: 'Onsite',
        sections: [...preparedSections],
      },
    ];
  };

  render() {
    const {Container} = this.props;
    const {...attributes} = this.state;
    return (
      <BranchGasStep
        {...attributes}
        Container={Container}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
        sections={this.sections()}
      />
    );
  }
}

export default subscriptionHOC(BranchGasStepContainer);
