import isObject from 'isobject';

// Types
import {contractDate} from '@matthahn/sally-fn/lib/types';

export default ({effective_datetime}) =>
  contractDate(
    isObject(effective_datetime) && !!effective_datetime.api
      ? effective_datetime.api.format()
      : effective_datetime
  ).format();
