import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {darkerGrey},
} = lib;

const CloseIcon = styled.div`
  color: ${darkerGrey};
  display: block;
  position: absolute;
  top: 50%;
  right: 6px;
  font-size: 24px;
  user-select: none;
  cursor: pointer;
  padding: 10px;
  transform: translateY(-50%);
`;

export default CloseIcon;
