// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch api
import getBranchByIdApi from '@matthahn/sally-fn/lib/branch/api/getByID.api.branch';

// branch components
import BranchSelectStep from '../../components/BranchSelectStep/BranchSelectStep';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class BranchSelectStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    branches: PropTypes.array,
    Container: PropTypes.any,
    onDisabled: PropTypes.func,
    onNext: PropTypes.func,
  };

  state = {
    loading: false,
  };

  continue = (branch = null, continueWithSelect = false) => () => {
    this.selectBranch({branch, continueWithSelect});
  };

  selectBranch = async ({branch}) => {
    const {onNext, onDisabled} = this.props;

    if (this.state.loading) return;

    this.setState({loading: true});
    onDisabled(true);

    try {
      const branchObject = await getBranchByIdApi(branch.id);
      onNext({branch: {...branchObject}, originalBranch: {...branchObject}});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  render() {
    const {Container, branches} = this.props;
    const {loading} = this.state;
    return (
      <BranchSelectStep
        branches={branches}
        Container={Container}
        loading={loading}
        onNext={this.continue}
      />
    );
  }
}

export default connect((state) => ({branches: state.branch.branches}))(
  BranchSelectStepContainer
);
