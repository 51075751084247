const countOfUnassignedAndUnchargedTickets = (summary) =>
  Object.values(summary || {}).reduce(
    (acc, branchSummary) =>
      acc +
      branchSummary.not_charged_tickets +
      branchSummary.unassigned_tickets,
    0
  );

export default countOfUnassignedAndUnchargedTickets;
