import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

// Lib
import {sectionsObject} from '../../sections/sections.gas';

// Sections
import sectionsList from '../../lib/sectionComponents.lib.section';

class AfterCompletion extends Component {
  static propTypes = {
    gasType: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  getSection = () => {
    const {gasType, vehicle, gas} = this.props;

    if (!gasType?.afterCompletion) return {section: null, Component: null};

    const Component =
      ([...sectionsList].find((s) => s.type === gasType?.afterCompletion) || {})
        ?.Component || null;
    const section =
      sectionsObject({vehicle, gas, all: true})[gasType.afterCompletion] ||
      null;

    return {Component, section};
  };

  render() {
    const {gasType, ...props} = this.props;
    const {section, Component} = this.getSection();
    return !!section ? (
      <Container>
        <Component section={section} {...props} />
      </Container>
    ) : null;
  }
}

export default AfterCompletion;
