// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch api
import getBranchByIdApi from '@matthahn/sally-fn/lib/branch/api/getByID.api.branch';

// branch components
import BranchTemplateStep from '../../components/BranchTemplateStep/BranchTemplateStep';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class BranchTemplateStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    branches: PropTypes.array,
    Container: PropTypes.any,
    done: PropTypes.number,
    goingBack: PropTypes.bool,
    onClose: PropTypes.func,
    onDisabled: PropTypes.func,
    onNext: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    loading: false,
  };

  continue = (branch = null, continueWithTemplate = false) => () => {
    this.selectBranch({branch, continueWithTemplate});
  };

  selectBranch = async ({
    branch,
    continueWithTemplate = false,
    prompt = true,
  }) => {
    const {onNext, done, onDisabled} = this.props;

    if (this.state.loading) return;

    if (continueWithTemplate) return onNext();

    if (!!done && prompt)
      return notify({
        id: 'confirmBranch',
        title: 'Confirm',
        icon: undefined,
        content:
          'Are you sure you want to select a different branch? This will undo all your previous settings.',
        primary: {
          label: 'Cancel',
          onClick: () => null,
        },
        secondary: {
          label: 'Continue',
          onClick: () => this.selectBranch({branch, prompt: false}),
        },
      });

    if (!branch) return onNext({branch: null});

    this.setState({loading: true});
    onDisabled(true);

    try {
      const branchObject = await getBranchByIdApi(branch.id);

      const selectedBranch = !!branch
        ? {
            app_doc_config: branchObject?.app_doc_config,
            driver_doc_config: branchObject?.driver_doc_config,
            rental_doc_config: branchObject?.rental_doc_config,
            vehicle_doc_config: branchObject?.vehicle_doc_config,

            accident_information: branchObject?.accident_information,
            gas_config: branchObject?.gas_config,

            minimum_deposit: branchObject?.minimum_deposit,
            minimum_rental_rate: branchObject?.minimum_rental_rate,
            requires_emissions: branchObject?.requires_emissions,
            requires_fhv: branchObject?.requires_fhv,
            requires_rental_insurance_id:
              branchObject?.requires_rental_insurance_id,
            requires_rideshare_inspection:
              branchObject?.requires_rideshare_inspection,
            requires_tlc: branchObject?.requires_tlc,
            state_change_fields: branchObject?.state_change_fields,
            tax_rate: branchObject?.tax_rate,
            toll_service: branchObject?.toll_service,
            weekly_safetyscore_budget: branchObject?.weekly_safetyscore_budget,
          }
        : null;

      onNext({branch: selectedBranch}, {ignoreDisabled: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  render() {
    const {Container, branches, done} = this.props;
    const {loading} = this.state;
    return (
      <BranchTemplateStep
        branches={branches}
        Container={Container}
        continueWithTemplate={!!done}
        loading={loading}
        onNext={this.continue}
      />
    );
  }
}

export default connect((state) => ({branches: state.branch.branches}))(
  BranchTemplateStepContainer
);
