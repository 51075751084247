import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Sections
import sectionComponents from '../../lib/sectionComponents.lib.section';

class Sections extends Component {
  static propTypes = {
    sections: PropTypes.object,
  };

  render() {
    const {sections, ...props} = this.props;
    return sectionComponents.map(({type, Component}) =>
      !sections[type] ? null : (
        <Component key={type} section={sections[type]} {...props} />
      )
    );
  }
}

export default Sections;
