import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../../components/containers/templates/VehicleEditContainer/actions';

// Api
import listVehicleTypesApi from '@matthahn/sally-fn/lib/vehicle/api/listTypes.api.vehicle';

// Components
import {FullScreenLoader} from '@matthahn/sally-ui';

// Error
import parseError from '../../../error/parseError';

// Lib
import {lib} from '@matthahn/sally-ui';

// Alerts
const {alert} = lib;

class VehicleTypeFetchContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    loaded: false,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const {
        make_models,
        vehicle_owners,
        vehicle_types,
      } = await listVehicleTypesApi();
      dispatch(
        setAct({
          makes: make_models,
          types: vehicle_types,
          owners: vehicle_owners,
        })
      );
      this.setState({loaded: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children} = this.props;
    const {loaded} = this.state;
    return loaded ? children : <FullScreenLoader />;
  }
}

export default connect()(VehicleTypeFetchContainer);
