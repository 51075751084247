const permissionTree = {
  Payments: [
    'take_money_now_cash_permission',
    'take_deposit_now_cash_permission',
    'apply_deposit_permission',
    'refund_deposit_permission',
    'delete_charge_permission',
    'sally_payment_methods_permission',
    'convert_balance_permission',
    'refund_credit_permission',
    'create_credit_permission',
    'maximum_credit_authorized',
    'credit_types_authorized',
  ],
  Drivers: ['change_driver_hold_permission'],
  Vehicles: [
    'clear_vehicle_repair_permission',
    'spireon_permission',
    'vehicle_storage_permission',
    'change_vehicle_owner_permission',
    'change_vehicle_branch_permission',
  ],
  Documents: ['delete_document_permission'],
  Rentals: ['enable_rental_admin_permission', 'rental_reservation_permission'],
  Tickets: ['ticket_fleet_program_permission'],
  Tolls: ['delete_tolls_permission', 'assign_tolls_permission'],
  Accidents: [
    'accidents_create_permission',
    'accidents_edit_permission',
    'accidents_delete_permission',
  ],
};

export default permissionTree;
