// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Info from '@matthahn/sally-ui/lib/components/Info/Info';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Text from '@matthahn/sally-ui/lib/components/Text/Text';

// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// legacy components
import {AttributeInput} from '../../../components/components/atoms/AttributeInput';

// lib
import fkOrId from '../../../libs/fkOrId';

// mileagePackage components
import SelectMileagePackage from '../../../mileagePackage/components/SelectMileagePackage/SelectMileagePackage';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// reducedDeductible components
import ReducedDeductibleOptionSelect from '../../../reducedDeductible/components/ReducedDeductibleOptionSelect/ReducedDeductibleOptionSelect';

// rentalRate lib
import netRentalRate from '@matthahn/sally-fn/lib/rentalRate/libs/netRentalRate.lib.rentalRate';

// types
import {
  amount as amountType,
  formattedInteger as formattedIntegerType,
  commaSeperatedNumber as commaSeperatedNumberType,
} from '@matthahn/sally-fn/lib/types';

const CreateChangeOrderModal = ({
  creating,
  currentMileagePackage,
  currentRate,
  currentReducedDeductible,
  description,
  disabledDates,
  displayEffectiveDate,
  displayRentalRateWarning,
  effective_date,
  loading,
  mileagePackage,
  onChange,
  onClose,
  onSave,
  rate,
  reducedDeductible,
  rental,
  showMileagePackage,
  suggestedRate,
  visible,
}) => (
  <Modal
    onClose={onClose}
    title="Change Order"
    visible={visible}
    size="large"
    disableOutsideClickClose
    buttonsRight={[
      {label: 'Save', disabled: loading, loading: creating, onClick: onSave},
    ]}
    info={(Content) =>
      displayRentalRateWarning ? (
        <Content padding="none" noBorder>
          <Info type="warning" flat>
            THE RATE IS SET TO 0!
          </Info>
        </Content>
      ) : null
    }
  >
    {(Content) =>
      loading ? (
        <ModalLoader />
      ) : (
        <Content>
          {displayEffectiveDate && (
            <Row margin>
              <Column>
                <AttributeInput
                  value={effective_date}
                  onChange={onChange}
                  disabled={creating}
                  disabledDates={disabledDates}
                  skipDisabledCheck
                >
                  {effective_date.label.default}
                </AttributeInput>
              </Column>
            </Row>
          )}
          <Row margin>
            <Column size={1 / 3}>
              <Text
                value={`$${amountType(
                  netRentalRate(currentRate?.rate)
                ).format()}`}
              >
                Current Rental Rate
              </Text>
            </Column>
            {!!suggestedRate && (
              <Column size={1 / 3}>
                <Text value={`$${amountType(suggestedRate).format()}`}>
                  Suggested Rental Rate
                </Text>
              </Column>
            )}
            <Column size={!!suggestedRate ? 1 / 3 : 3 / 3}>
              <AttributeInput
                value={rate}
                onChange={onChange}
                disabled={creating}
              >
                New Rental Rate
              </AttributeInput>
            </Column>
          </Row>
          <Row margin>
            <Column size={showMileagePackage ? 1 / 3 : 1}>
              <Text
                value={commaSeperatedNumberType(
                  rental?.avg_daily_calamp_miles
                ).format()}
              >
                Average Daily Miles
              </Text>
            </Column>
            {showMileagePackage && (
              <Column size={1 / 3}>
                <Text
                  value={
                    !!currentMileagePackage
                      ? `Mileage: ${formattedIntegerType(
                          currentMileagePackage.alloted_mileage
                        ).format()} Rate: $${amountType(
                          currentMileagePackage.rate
                        ).format()}`
                      : '-'
                  }
                >
                  Current Mileage Package
                </Text>
              </Column>
            )}
            {showMileagePackage && (
              <Column size={1 / 3}>
                <SelectMileagePackage
                  branch={fkOrId(
                    rental?.vehicle?.sally_branch || rental?.sally_branch
                  )}
                  dealership={fkOrId(
                    rental?.vehicle?.sally_dealership ||
                      rental?.sally_dealership
                  )}
                  disabled={creating}
                  onChange={(value) => onChange(value, 'mileagePackage')}
                  mileagePackage={mileagePackage}
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column size={1 / 2}>
              <Text
                value={`Deductible: $${amountType(
                  currentReducedDeductible?.deductible_amount || 0
                ).format()} Rate: $${amountType(
                  currentReducedDeductible?.rate || 0
                ).format()}`}
              >
                Current Damage Waiver
              </Text>
            </Column>
            <Column size={1 / 2}>
              <ReducedDeductibleOptionSelect
                disabled={creating}
                label="New Damage Waiver"
                onChange={(value) => onChange(value, 'reducedDeductible')}
                reducedDeductible={reducedDeductible}
              />
            </Column>
          </Row>
        </Content>
      )
    }
  </Modal>
);

CreateChangeOrderModal.propTypes = {
  creating: PropTypes.bool,
  currentMileagePackage: PropTypes.object,
  currentRate: PropTypes.object,
  currentReducedDeductible: PropTypes.object,
  description: PropTypes.object,
  disabledDates: PropTypes.array,
  displayEffectiveDate: PropTypes.bool,
  displayRentalRateWarning: PropTypes.bool,
  effective_date: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  rate: PropTypes.object,
  reducedDeductible: PropTypes.object,
  rental: PropTypes.object,
  suggestedRate: PropTypes.number,
  visible: PropTypes.bool,
};

export default CreateChangeOrderModal;
