// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// dealership api
import getDealershipByIdApi from '@matthahn/sally-fn/lib/dealership/api/getByID.api.dealership';

// dealership components
import DealershipSelectStep from '../../components/DealershipSelectStep/DealershipSelectStep';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class DealershipSelectStepContainer extends Component {
  static propTypes = {
    dealership: PropTypes.object,
    dealerships: PropTypes.array,
    Container: PropTypes.any,
    done: PropTypes.number,
    goingBack: PropTypes.bool,
    onClose: PropTypes.func,
    onDisabled: PropTypes.func,
    onNext: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    loading: false,
  };

  continue = (dealership = null, continueWithSelect = false) => () => {
    this.selectDealership({dealership, continueWithSelect});
  };

  selectDealership = async ({
    dealership,
    continueWithSelect = false,
    prompt = true,
  }) => {
    const {onNext, done, onDisabled} = this.props;

    if (this.state.loading) return;

    if (continueWithSelect) return onNext();

    if (!!done && prompt)
      return notify({
        id: 'confirmDealership',
        title: 'Confirm',
        icon: undefined,
        content:
          'Are you sure you want to select a different dealership? This will undo all your previous settings.',
        primary: {
          label: 'Cancel',
          onClick: () => null,
        },
        secondary: {
          label: 'Continue',
          onClick: () => this.selectDealership({dealership, prompt: false}),
        },
      });

    this.setState({loading: true});
    onDisabled(true);

    try {
      const dealershipObject = await getDealershipByIdApi(dealership.id);
      onNext({
        dealership: {...dealershipObject},
        originalDealership: {...dealershipObject},
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }

    onDisabled(false);
  };

  render() {
    const {Container, dealerships, done} = this.props;
    const {loading} = this.state;
    return (
      <DealershipSelectStep
        Container={Container}
        continueWithSelect={!!done}
        dealerships={dealerships}
        loading={loading}
        onNext={this.continue}
      />
    );
  }
}

export default connect((state) => ({
  dealerships: state.dealership.dealerships,
}))(DealershipSelectStepContainer);
