// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// spotlight actions
import {
  set as setAct,
  syncVehicles as syncVehiclesAct,
  syncDrivers as syncDriversAct,
} from '../../redux/actions';

// spotlight api
import searchSpotlightApi from '@matthahn/sally-fn/lib/spotlight/api/search.api.spotlight';

// spotlight events
import onSpotlightSearchInputEvent from '../../events/onSearchInput.event.spotlight';

class SpotlightSync extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(onSpotlightSearchInputEvent.subscribe(this.search));
  }

  search = async (search) => {
    const {dispatch} = this.props;
    dispatch(
      setAct({loading: true, driversLoading: true, vehiclesLoading: true})
    );
    try {
      const {drivers, vehicles} = await searchSpotlightApi({
        ordering: '-created_at',
        search,
      });
      dispatch(syncVehiclesAct(vehicles));
      dispatch(syncDriversAct(drivers));
    } catch (error) {
      // Do nothing
    }
    dispatch(
      setAct({loading: false, driversLoading: false, vehiclesLoading: false})
    );
  };

  render() {
    return null;
  }
}

export default connect()(subscriptionHOC(SpotlightSync));
