import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import capitalWord from '@matthahn/sally-fw/lib/type/types/capitalWord.type';

export default attribute({
  type: TEXT,
  attribute: 'garage_address1',
  display: capitalWord,
  input: capitalWord,
  api: capitalWord,
  label: {
    default: 'Garage Address 1',
    short: 'Address 1',
  },
});
