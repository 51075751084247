// branch components
import BranchRequirementsStep from '../../components/BranchRequirementsStep/BranchRequirementsStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// driver lib
import getAvailableDriverAttributes from '../../../driver/lib/availableAttributes.lib.driver';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local constants
import DEFAULT_DATA from './constants/defaultData.constant';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// vehicle lib
import getAvailableVehicleAttributes from '../../../vehicle/lib/availableAttributes.lib.vehicle';

class BranchRequirementsStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    subscribe: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    driver: {...DEFAULT_DATA},
    vehicle: {...DEFAULT_DATA},
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      driver: branch?.state_change_fields?.driver || {...DEFAULT_DATA},
      vehicle: branch?.state_change_fields?.vehicle || {...DEFAULT_DATA},
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = ['state_change_fields'];
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  generateBranch = () => {
    const {branch} = this.props;
    const {driver, vehicle} = this.state;
    return {
      ...branch,
      state_change_fields: {
        driver,
        vehicle,
      },
    };
  };

  continue = () => {
    const {onNext} = this.props;
    const branch = this.generateBranch();
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = () => {
    const {onBack} = this.props;
    const branch = this.generateBranch();
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  stepChangeValidator = async () => {
    const branch = this.generateBranch();
    return {
      shouldContinue: !!branch,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = ({object, state, attribute}) => () => {
    this.changedAttributes = ['state_change_fields'];
    const requirements = this.state?.[object]?.[state] || [];
    const attributes = requirements.includes(attribute)
      ? [...requirements].filter(
          (existingAttribute) => existingAttribute !== attribute
        )
      : [...requirements, attribute];
    const updatedObject = {
      ...this.state[object],
      [state]: attributes,
    };
    this.setState({[object]: updatedObject});
  };

  attributes = () => {
    const {driver, vehicle} = this.state;
    return [
      {
        key: 'driver',
        label: 'Driver',
        attributes: getAvailableDriverAttributes(),
        states: [
          {
            key: 'ready',
            label: 'Ready',
            attributes: [...(driver.ready || [])],
          },
          {
            key: 'reserved',
            label: 'Reserved',
            attributes: [...(driver.reserved || [])],
          },
        ],
      },
      {
        key: 'vehicle',
        label: 'Vehicle',
        attributes: getAvailableVehicleAttributes(),
        states: [
          {
            key: 'ready',
            label: 'Ready',
            attributes: [...(vehicle.ready || [])],
          },
          {
            key: 'reserved',
            label: 'Reserved',
            attributes: [...(vehicle.reserved || [])],
          },
        ],
      },
    ];
  };

  render() {
    const {Container} = this.props;
    return (
      <BranchRequirementsStep
        attributes={this.attributes()}
        Container={Container}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(BranchRequirementsStepContainer);
