import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class ShortCleaning extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Interior Clean" noBorder>
          <CheckBox
            size="small"
            value={gas.clean_interior}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Exterior Clean">
          <CheckBox
            size="small"
            value={gas.clean_exterior}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Odor Free">
          <CheckBox
            size="small"
            value={gas.clean_odor_free}
            onChange={() => {}}
          />
        </GasSectionRow>
      </Section>
    );
  }
}

export default ShortCleaning;
