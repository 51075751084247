// branch components
import SelectBranchModal from '../../components/SelectBranchModal/SelectBranchModal';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';
import showBranchSelectEvent from '../../events/showSelect.event.branch';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class SelectBranchContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showBranchSelectEvent.subscribe(this.show));
  }

  show = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  select = (branch) => () => {
    branchSelectedEvent.publish({branch});
    this.hide();
  };

  render() {
    const {branches} = this.props;
    const {visible} = this.state;
    return (
      <SelectBranchModal
        branches={branches}
        onClose={this.hide}
        onSelect={this.select}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({branches: state.branch.branches}))(
  subscriptionHOC(SelectBranchContainer)
);
