// payment lib
import parseTypeName from './parseTypeName.lib.payment';

const parseChargeTypes = (chargeTypes) =>
  Object.entries(chargeTypes).map(([type, {subtypes}]) => ({
    value: type,
    label: parseTypeName(type),
    subtypes: [...subtypes].map((subType) => ({
      value: subType,
      label: parseTypeName(subType),
    })),
  }));

export default parseChargeTypes;
