// Action constants
import {
  PAGE_CHANGE,
  SET_LATEST_CUSTOMER,
  SET_UNSAVED_CHANGES,
  UNSET_UNSAVED_CHANGES,
} from './constants';

const DEFAULT_STATE = {
  page: '',
  customerID: null,
  unsavedChanges: false,
};

/**
 * @description :: Reducer for the UI
 * @param {Object} state :: The current state
 * @param {String} type :: The type of action
 * @param {Any} data :: The data bind to the action
 * @return {Object} :: New state
 */
export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case PAGE_CHANGE: {
      return {
        ...state,
        page: data,
      };
    }

    case SET_LATEST_CUSTOMER: {
      return {
        ...state,
        customerID: data,
      };
    }

    case SET_UNSAVED_CHANGES: {
      return {
        ...state,
        unsavedChanges: true,
      };
    }

    case UNSET_UNSAVED_CHANGES: {
      return {
        ...state,
        unsavedChanges: false,
      };
    }

    default: {
      return state;
    }
  }
};
