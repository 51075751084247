import isObject from 'isobject';

const getLabel = (option) => {
  if (!!option?.label) return option.label;
  return 'NO LABEL';
};

const getValue = (option) => {
  if (option.hasOwnProperty('value')) return option.value;
  if (option.hasOwnProperty('key')) return option.key;
  if (option.hasOwnProperty('id')) return option.id;
  if (option.hasOwnProperty('option')) return option.option;
  throw new Error('No value in option object found');
};

export default (options) =>
  options.map((option) =>
    isObject(option)
      ? {label: getLabel(option), value: getValue(option)}
      : {label: option, value: option}
  );
