import styled from 'styled-components';

// colors
import {darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Remove = styled.div`
  flex: 1;
  width: 100%;
  text-align: right;
  color: ${darkGrey};
  font-size: 12px;

  & > * {
    cursor: pointer;
  }
`;

export default Remove;
