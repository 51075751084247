// api lib
import getApiEndpoint from '../../api/lib/getEndpoint.lib.api';

// auth lib
import getAuthToken from '../../auth/lib/getAuthToken.lib.auth';

// samson api
import listServices from '@matthahn/sally-fn/lib/samson/api/listServices.api.samson';

const listServicesApi = ({
  query = {},
  token = getAuthToken('samson'),
  url = getApiEndpoint('samson'),
}) => listServices({query, token, url});

export default listServicesApi;
