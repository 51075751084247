import styled from 'styled-components';

const Grid = styled.div`
  width: 100%;
  display: grid;
  padding: 5px;
  gap: 5px;
  grid-template-columns: auto auto auto auto;
`;

export default Grid;
