// components
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';

// layout components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// rental constants
import REPO_REASONS from '../../constants/repoReasons.constant.rental';

// rental lib
import isOtherRepoReason from '../../lib/isOtherRepoReason.lib.rental';

// types
import dateTimeType from '@matthahn/sally-fn/lib/types/types/dateTime.type';

const RepoRentalModal = ({
  isRentalRepo,
  onChange,
  onClose,
  onSave,
  rental,
  repo_start_date,
  saving,
  visible,
  custom_repo_reason,
  repo_reason,
}) => (
  <Modal
    title={isRentalRepo ? 'Clear Repo' : 'Repo Vehicle'}
    visible={visible}
    onClose={onClose}
    buttonsRight={[
      {
        label: isRentalRepo ? 'Cancel Repo' : 'Repo',
        onClick: onSave,
        loading: saving,
      },
    ]}
    size="small"
  >
    {(Content) => {
      return isRentalRepo ? (
        <Content>
          Repo initiated on {dateTimeType(rental.repo_start_date).format()} by{' '}
          {rental?.repo_initiated_by?.username ||
            `User #${rental?.repo_initiated_by}`}
          . Reason: {rental.repo_reason}. Would you like to cancel the repo
          request?
        </Content>
      ) : (
        <Content>
          <Row margin>
            <Column>
              <AttributeInput
                value={repo_reason}
                onChange={onChange}
                disabled={saving}
                options={REPO_REASONS}
              >
                {repo_reason.label.default}
              </AttributeInput>
            </Column>
          </Row>
          {isOtherRepoReason(repo_reason) && (
            <Row margin>
              <Column>
                <AttributeInput
                  value={custom_repo_reason}
                  onChange={onChange}
                  disabled={saving}
                >
                  {custom_repo_reason.label.default}
                </AttributeInput>
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              <AttributeInput
                value={repo_start_date}
                onChange={onChange}
                disabled={saving}
                disabledDates={[{after: new Date()}]}
              >
                {repo_start_date.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      );
    }}
  </Modal>
);

RepoRentalModal.propTypes = {
  custom_repo_reason: PropTypes.object,
  isRentalRepo: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  rental: PropTypes.object,
  repo_reason: PropTypes.object,
  repo_start_date: PropTypes.object,
  saving: PropTypes.bool,
  visible: PropTypes.bool,
};

export default RepoRentalModal;
