// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch api
import updateOperationsTimeRulesApi from '@matthahn/sally-fn/lib/branch/api/updateOperationsTimeRules.api.branch';

// branch components
import BranchWorkingHoursEditorModal from '../../components/BranchWorkingHoursEditorModal/BranchWorkingHoursEditorModal';

// branch events
import showWorkingHoursEditorEvent from '../../events/showWorkingHoursEditor.event.branch';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class WorkingHoursEditorContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    exceptions: [],
    object: null,
    objectType: null,
    operationTimeRulesId: null,
    rules: null,
    saving: false,
    timeZone: null,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showWorkingHoursEditorEvent.subscribe(this.show));
  }

  show = async ({object, objectType, timeZone}) => {
    this.setState({
      exceptions: object?.operationstimerule?.exceptions,
      object,
      objectType,
      operationTimeRulesId: object?.operationstimerule?.id,
      rules: object?.operationstimerule?.rules,
      timeZone,
      visible: true,
    });
  };

  hide = () => {
    const {saving} = this.state;
    if (saving) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {exceptions, object, rules, saving} = this.state;
    if (saving) return;

    this.setState({saving: true});

    try {
      await updateOperationsTimeRulesApi(object?.operationstimerule?.id, {
        exceptions,
        rules,
      });
      this.setState({saving: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  change = (key) => (value) => {
    const {saving, visible} = this.state;
    if (saving || !visible) return;
    this.setState({[key]: value});
  };

  render() {
    const {
      exceptions,
      object,
      operationTimeRulesId,
      rules,
      saving,
      timeZone,
      visible,
    } = this.state;
    return (
      <BranchWorkingHoursEditorModal
        exceptions={exceptions}
        onClose={this.hide}
        onExceptionsChange={this.change('exceptions')}
        onRulesChange={this.change('rules')}
        onSave={this.save}
        operationTimeRulesId={operationTimeRulesId}
        saving={saving}
        timeZone={timeZone}
        title={object?.name}
        visible={visible}
        workingHours={rules}
      />
    );
  }
}

export default subscriptionHOC(WorkingHoursEditorContainer);
