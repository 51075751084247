// branch components
import DealershipBranchSelectStep from '../../components/DealershipBranchSelectStep/DealershipBranchSelectStep';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class DealershipBranchSelectStepContainer extends Component {
  static propTypes = {
    dealership: PropTypes.object,
    branches: PropTypes.array,
    Container: PropTypes.any,
    done: PropTypes.number,
    goingBack: PropTypes.bool,
    onClose: PropTypes.func,
    onDisabled: PropTypes.func,
    onNext: PropTypes.func,
    visible: PropTypes.bool,
  };

  selectBranch = (branch) => () => {
    const {onNext, dealership} = this.props;

    onNext({dealership: {...dealership, sally_branch: branch.id}});
  };

  render() {
    const {Container, branches} = this.props;
    return (
      <DealershipBranchSelectStep
        branches={branches}
        Container={Container}
        onNext={this.selectBranch}
      />
    );
  }
}

export default connect((state) => ({branches: state.branch.branches}))(
  DealershipBranchSelectStepContainer
);
