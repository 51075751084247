import {REDUX_SET, REDUX_RESET} from './constants';

const DEFAULT_STATE = {
  tab: 'actions',

  vehicle: null,
  loadingVehicle: true,

  saveVehicle: null,
  savingVehicle: false,

  notes: [],
  loadingNotes: true,

  mileage: null,
  loadingMileage: true,

  keyLabel: null,
  loadingKeyLabel: true,

  makes: [],
  types: [],
  owners: [],
};

export default (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case REDUX_SET:
      return {
        ...state,
        ...data,
      };

    case REDUX_RESET:
      return {
        ...DEFAULT_STATE,
        makes: state.makes,
        types: state.types,
        owners: state.owners,
      };

    default:
      return state;
  }
};
