// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// dealership api
import createDealershipApi from '@matthahn/sally-fn/lib/dealership/api/create.api.dealership';

// dealership components
import DealershipSaveStep from '../../components/DealershipSaveStep/DealershipSaveStep';

// dealership events
import dealershipCreatedEvent from '../../events/created.event.dealership';

// dealership lib
import generateLocationObject from '../../../branch/lib/generateLocationObject.lib.branch';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// map api
import geocodeApi from '@matthahn/sally-fn/lib/map/api/geocode.api.map';

// map constants
import getGoogleMapApiKey from '../../../map/constants/googleMapApiKey.constant.map';

// map lib
import getLocation from '../../../map/lib/getLocation.lib.map';
import prepareAddress from '../../../map/lib/prepareAddress.lib.map';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class DealershipCreateStepContainer extends Component {
  static propTypes = {
    dealership: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    loading: false,
  };

  back = () => {
    const {onBack} = this.props;
    const {loading} = this.state;
    if (loading) return;
    onBack();
  };

  save = async () => {
    const {dealership, onFinish} = this.props;

    if (this.state.loading) return;

    this.setState({loading: true});

    try {
      const geoLocation = await geocodeApi({
        key: getGoogleMapApiKey(),
        address: prepareAddress({
          address: dealership.street_address1,
          city: dealership.city,
          zip: dealership.zip,
          state: dealership.state,
        }),
      });
      dealership.gps_location = generateLocationObject(
        getLocation(geoLocation)
      );
      const createdDealership = await createDealershipApi(dealership);
      dealershipCreatedEvent.publish(createdDealership);
      onFinish();
      alert.success('Dealership created');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {Container} = this.props;
    const {loading} = this.state;
    return (
      <DealershipSaveStep
        Container={Container}
        loading={loading}
        onBack={this.back}
        onSave={this.save}
      />
    );
  }
}

export default DealershipCreateStepContainer;
