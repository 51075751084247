import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {CheckBox} from '@matthahn/sally-ui';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey, darkGrey},
} = lib;

// Helper Components
const Container = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border: 2px solid ${grey};
  border-radius: 10px;
`;

const Name = styled.div`
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
  color: ${darkGrey};
`;

const Input = styled.div`
  width: 100%;
  margin: 20px 0px;
`;

const Text = styled.div`
  width: 100%;
  font-size: 12px;
`;

const Tyre = ({penny, name, children, onClick}) => (
  <Container>
    <Name>{name}</Name>
    <Input>{children}</Input>
    <Text>
      <CheckBox value={penny} onChange={onClick} size="giant" inline>
        Penny Test
      </CheckBox>
    </Text>
  </Container>
);

Tyre.propTypes = {
  penny: PropTypes.bool,
  name: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Tyre;
