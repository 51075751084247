import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 0px 15px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 14px;
  background: transparent;
  display: block;

  &:disabled {
    opacity: 1;
    background: transparent;
  }
`;

export default Input;
