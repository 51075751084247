import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas lib
import isSectionComplete from '../../lib/isSectionComplete.lib.gas';

class Calamp extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  renderContent = () => {
    const {section} = this.props;
    return ['error', 'warning'].includes(
      section.status.status
    ) ? null : isSectionComplete(
        section?.status?.equipment?.calamp || section?.status
      ) ? (
      <CheckBox value={true} onChange={() => {}} size="small" />
    ) : (
      <CheckBox value={false} onChange={() => {}} size="small" />
    );
  };

  render() {
    const {section, gas} = this.props;

    const content = this.renderContent();

    return !!section?.status?.equipment?.calamp ? (
      content
    ) : (
      <Section section={section} gas={gas}>
        {content}
      </Section>
    );
  }
}

export default Calamp;
