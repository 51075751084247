import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {string} from '@matthahn/sally-fw/lib/type';

export default attribute({
  type: TEXT,
  attribute: 'reason',
  display: string,
  input: string,
  api: string,
  label: {
    default: 'Reason',
    short: 'Reason',
  },
});
