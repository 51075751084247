// vehicle attributes
import * as vehicleAttributes from '@matthahn/sally-fn/lib/vehicle/attributes';

export default () =>
  Object.values(vehicleAttributes)
    .filter(
      (attribute) => !['insurance_policy_number'].includes(attribute.attribute)
    )
    .reduce(
      (combined, attribute) =>
        !!combined.find(
          (existingAttribute) => existingAttribute.value === attribute.attribute
        )
          ? combined
          : [
              ...combined,
              {
                value: attribute.attribute,
                label: attribute.label.default,
              },
            ],
      []
    );
