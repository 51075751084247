// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import CompactLabel from '../../../layout/components/CompactLabel/CompactLabel';
import FlowScroll from '../../../layout/components/FlowScroll/FlowScroll';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Grid from './components/Grid';
import Section from './components/Section';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchDocumentsStep = ({
  Container,
  folders,
  onBack,
  onChange,
  onNext,
}) => (
  <Fragment>
    <Container padding="none">
      <FlowScroll>
        {folders.map((folder) => (
          <Fragment key={folder.key}>
            <CompactLabel>{folder.label}</CompactLabel>
            <Grid>
              {folder.availableFiles.map((availableFile) => (
                <Section
                  key={availableFile.type}
                  selected={folder.selectedFiles.includes(availableFile.type)}
                  onClick={onChange({
                    fileType: availableFile.type,
                    objectType: folder.key,
                  })}
                >
                  {availableFile.defaultFileName}
                </Section>
              ))}
            </Grid>
          </Fragment>
        ))}
      </FlowScroll>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchDocumentsStep.propTypes = {
  Container: PropTypes.any,
  folders: PropTypes.array,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

export default BranchDocumentsStep;
