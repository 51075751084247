// driver api
import getDriverRateAddonApi from '@matthahn/sally-fn/lib/driver/api/getRateAddon.api.driver';

// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import sum from '@matthahn/sally-fw/lib/lib/sum';

// rental api
import getRentalByIdApi from '@matthahn/sally-fn/lib/rental/api/getByID.api.rental';

// vehicle api
import getVehicleByIdApi from '@matthahn/sally-fn/lib/vehicle/api/getByID.api.vehicle';

const getSuggestedRentalRateService = async ({rentalId}) => {
  try {
    const rental = await getRentalByIdApi(rentalId);
    const vehicle = await getVehicleByIdApi(fkOrId(fkOrId(rental.vehicle)));
    const vehicleRate = vehicle?.rate_card?.rate || 0;
    const driverRateResponse = await getDriverRateAddonApi(
      fkOrId(rental?.drivers?.[0]?.driver)
    );
    const driverRateAddon = Number(driverRateResponse?.rate) || 0;
    return sum(vehicleRate, driverRateAddon);
  } catch (error) {
    return 0;
  }
};

export default getSuggestedRentalRateService;
