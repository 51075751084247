// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch components
import BranchRentalFeesEditorModal from '../../components/BranchRentalFeesEditorModal/BranchRentalFeesEditorModal';

// branch events
import showRentalFeesEditorEvent from '../../events/showRentalFeesEditor.event.branch';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// rentalFee api
import updateRentalFeeApi from '@matthahn/sally-fn/lib/rentalFee/api/update.api.rentalFee';

// rentalFee attributes
import chargeBackFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/charge_back_fee.attribute.rentalFee.js';
import cleaningFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/cleaning_fee.attribute.rentalFee.js';
import dashcamFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/dash_cam_fee.attribute.rentalFee.js';
import defaultFueFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/default_fuel_fee.attribute.rentalFee.js';
import earlyTermFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/early_term_fee.attribute.rentalFee.js';
import excessCostPerMileAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/excess_cost_per_mile.attribute.rentalFee.js';
import gasFuelFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/gas_fuel_fee.attribute.rentalFee.js';
import hotswapFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/hotswap_fee.attribute.rentalFee.js';
import latePaymentFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/late_payment_fee.attribute.rentalFee.js';
import lateReturnHourlyFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/late_return_hourly_fee.attribute.rentalFee.js';
import lostKeyFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/lost_key_fee.attribute.rentalFee.js';
import reactivationFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/reactivation_fee.attribute.rentalFee.js';
import returnCancellationFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/return_cancellation_fee.attribute.rentalFee.js';
import ticketServicePercentFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/ticket_service_percent_fee.attribute.rentalFee.js';
import tollServiceFeeTypeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/toll_service_fee_type.attribute.rentalFee.js';
import tollServiceFeeAttribute from '@matthahn/sally-fn/lib/rentalFee/attributes/toll_service_fee.attribute.rentalFee.js';

// rentalFee preparation
import updateRentalFeePreparation from '@matthahn/sally-fn/lib/rentalFee/preparations/update.preparation.rentalFee';

class RentalFeesEditorContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  static ATTRIBUTES = [
    chargeBackFeeAttribute,
    cleaningFeeAttribute,
    dashcamFeeAttribute,
    defaultFueFeeAttribute,
    earlyTermFeeAttribute,
    excessCostPerMileAttribute,
    gasFuelFeeAttribute,
    hotswapFeeAttribute,
    latePaymentFeeAttribute,
    lateReturnHourlyFeeAttribute,
    lostKeyFeeAttribute,
    reactivationFeeAttribute,
    returnCancellationFeeAttribute,
    ticketServicePercentFeeAttribute,
    tollServiceFeeTypeAttribute,
    tollServiceFeeAttribute,
  ];

  state = {
    branch: null,
    rentalFees: {},
    saving: false,
    visible: false,
  };

  componentDidMount() {
    this.props.subscribe(showRentalFeesEditorEvent.subscribe(this.show));
  }

  show = async ({branch}) => {
    this.setState({
      branch,
      rentalFees: [...this.constructor.ATTRIBUTES].reduce(
        (combined, attribute) => ({
          ...combined,
          [attribute.attribute]: attribute(
            branch?.rental_fees?.[attribute.attribute] || ''
          ),
        }),
        {}
      ),
      visible: true,
    });
  };

  hide = () => {
    const {saving} = this.state;
    if (saving) return;
    this.setState({visible: false});
  };

  save = async () => {
    const {branch, rentalFees, saving} = this.state;
    if (saving) return;

    this.setState({saving: true});

    try {
      const rentalFeeBody = await updateRentalFeePreparation(rentalFees);
      await updateRentalFeeApi(branch.rental_fees.id, rentalFeeBody);
      this.setState({saving: false, visible: false});
      alert.success('Rental Fees Saved');
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  change = (value, key) => {
    const {rentalFees, saving, visible} = this.state;
    if (saving || !visible) return;
    this.setState({rentalFees: {...rentalFees, [key]: value}});
  };

  render() {
    const {branch, rentalFees, saving, visible} = this.state;
    return (
      <BranchRentalFeesEditorModal
        attributes={Object.values(rentalFees)}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        saving={saving}
        title={branch?.name}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(RentalFeesEditorContainer);
