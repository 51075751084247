import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Input Types
import {
  DATE,
  DATETIME,
  NUMBER,
  SELECT,
  SWITCH,
  TEXT,
  TIME,
  TOGGLE,
} from '@matthahn/sally-fn/lib/inputTypes';

// Lib
import displayTimeZoneSpecificDate from '../../../../libs/displayTimeZoneSpecificDate';

// Components
import {Text} from '@matthahn/sally-ui';

class AttributeText extends Component {
  static propTypes = {
    attribute: PropTypes.object.isRequired,
    branch: PropTypes.object,
    empty: PropTypes.node,
    ignoreTimeZone: PropTypes.bool,
    preValue: PropTypes.string,
    postValue: PropTypes.string,
  };

  static defaultProps = {
    empty: '-',
    ignoreTimeZone: false,
    preValue: '',
    postValue: '',
  };

  static OPTIONS = {
    [DATE]: {postIcon: 'calendar'},
    [DATETIME]: {postIcon: 'calendar'},
    [NUMBER]: {},
    [SELECT]: {},
    [SWITCH]: {},
    [TEXT]: {},
    [TIME]: {postIcon: 'clockcircleo'},
    [TOGGLE]: {},
  };

  value = () => {
    const {
      attribute,
      branch,
      ignoreTimeZone,
      empty,
      preValue,
      postValue,
    } = this.props;
    const parsedValue =
      [DATETIME].includes(attribute.type) && !ignoreTimeZone
        ? displayTimeZoneSpecificDate({
            date: attribute.value(),
            type: (value) => ({
              format: () => attribute.reinit(value).display.format(),
            }),
            branch,
          })
        : attribute.display.format() || empty;
    return parsedValue === empty
      ? parsedValue
      : `${preValue}${parsedValue}${postValue}`;
  };

  render() {
    const {
      attribute,
      empty,
      children,
      branch,
      ignoreTimeZone,
      preValue,
      postValue,
      ...props
    } = this.props;
    return (
      <Text
        value={this.value()}
        {...this.constructor.OPTIONS[this.props.attribute.type]}
        {...props}
      >
        {children}
      </Text>
    );
  }
}

export default AttributeText;
