// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import DropDown from '@matthahn/sally-ui/lib/components/DropDown/DropDown';
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';
import SimpleMenu from '@matthahn/sally-ui/lib/components/SimpleMenu/SimpleMenu';

// date lib
import convertHoursIntoHourMinutes from '../../lib/convertHoursIntoHourMinutes.lib.date';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// local components
import AddBlock from './components/AddBlock';
import Container from './components/Container';
import Day from './components/Day';
import Hour from './components/Hour';
import Remove from './components/Remove';
import Row from './components/Row';

// local lib
import getHourOptions from './lib/getHourOptions.lib';
import convertIntervalToHours from './lib/convertIntervalToHours.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// util lib
import doIntervalsOverlap from '../../../util/lib/doIntervalsOverlap.lib.util';
import getIntervals from '../../../util/lib/getIntervals.lib.util';

class WorkingHoursIntervalEditor extends Component {
  static propTypes = {
    day: PropTypes.string.isRequired,
    minuteInterval: PropTypes.number,
    onChange: PropTypes.func,
    refresh: PropTypes.any,
    workingHours: PropTypes.array.isRequired,
  };

  static defaultProps = {
    minuteInterval: 60,
  };

  state = {
    endTime: null,
    endTimeDropDownOpen: false,
    intervals: [],
    showAddBlock: false,
    startTime: null,
    startTimeDropDownOpen: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh) this.init();
  }

  init = () => {
    const {workingHours} = this.props;
    const intervals = getIntervals(workingHours);
    this.setState({intervals});
  };

  removeInterval = (intervalIndex) => () => {
    const intervals = [...this.state.intervals].filter(
      (interval, index) => index !== intervalIndex
    );
    this.updateRules(intervals);
    this.setState({intervals});
  };

  showNewBlock = () => {
    if (this.state.showAddBlock) return;
    this.setState({
      endTime: null,
      showAddBlock: true,
      startTime: null,
    });
  };

  hideNewBlock = () => {
    if (!this.state.showAddBlock) return;
    this.setState({
      endTime: null,
      showAddBlock: false,
      startTime: null,
    });
  };

  saveBlock = () => {
    const {endTime, showAddBlock, startTime} = this.state;
    if (!showAddBlock) return;

    if (!startTime) return alert.warning('Select a start time');
    if (!endTime) return alert.warning('Select a end time');

    const numericStartTime = Number(startTime);
    const numericEndTime = Number(endTime);

    if (numericStartTime >= numericEndTime)
      return alert.warning('Invalid time range');

    const intervals = [
      ...this.state.intervals,
      [numericStartTime, numericEndTime],
    ];

    if (doIntervalsOverlap(intervals))
      return alert.warning('Time ranges are overlapping');

    this.updateRules(intervals);
    this.setState({intervals, showAddBlock: false});
  };

  openDropDown = (dropdown) => () => this.setState({[dropdown]: true});

  closeDropDown = (dropdown) => () => this.setState({[dropdown]: false});

  selectTime = (time) => (value) => this.setState({[time]: value});

  updateRules = (intervals) => {
    const {day, onChange} = this.props;
    const rules = convertIntervalToHours(intervals);
    onChange({[day]: {open: rules}});
  };

  render() {
    const {day, minuteInterval} = this.props;
    const {
      intervals,
      showAddBlock,
      startTime,
      endTime,
      startTimeDropDownOpen,
      endTimeDropDownOpen,
    } = this.state;
    const timeOptions = getHourOptions({minuteInterval});
    return (
      <CompactDataRow label={<Day>{day}</Day>}>
        <Container>
          {intervals.map(([startHour, endHour], index) => (
            <Row key={`${startHour}-${endHour}`}>
              <Hour>
                <Button theme="grey" size="small" block>
                  {convertHoursIntoHourMinutes(startHour)}
                </Button>
              </Hour>
              <div>-</div>
              <Hour>
                <Button theme="grey" size="small" block>
                  {convertHoursIntoHourMinutes(endHour)}
                </Button>
              </Hour>
              <Remove>
                <Icon onClick={this.removeInterval(index)} icon="delete" />
              </Remove>
            </Row>
          ))}
          {showAddBlock && (
            <Row>
              <Hour>
                <DropDown
                  onOpen={this.openDropDown('startTimeDropDownOpen')}
                  onClose={this.closeDropDown('startTimeDropDownOpen')}
                  trigger={
                    <Button theme="darkGrey" size="small" block>
                      {!startTime
                        ? '--:--'
                        : convertHoursIntoHourMinutes(startTime)}
                    </Button>
                  }
                  visible={startTimeDropDownOpen}
                  size={100}
                  block
                >
                  <SimpleMenu
                    height={200}
                    selected={[startTime]}
                    options={timeOptions}
                    onSelect={this.selectTime('startTime')}
                    noDataLabel="No time available"
                  />
                </DropDown>
              </Hour>
              <div>-</div>
              <Hour>
                <DropDown
                  onOpen={this.openDropDown('endTimeDropDownOpen')}
                  onClose={this.closeDropDown('endTimeDropDownOpen')}
                  trigger={
                    <Button theme="darkGrey" size="small" block>
                      {!endTime
                        ? '--:--'
                        : convertHoursIntoHourMinutes(endTime)}
                    </Button>
                  }
                  visible={endTimeDropDownOpen}
                  size={100}
                  block
                >
                  <SimpleMenu
                    height={200}
                    selected={[endTime]}
                    options={timeOptions}
                    onSelect={this.selectTime('endTime')}
                    noDataLabel="No time available"
                  />
                </DropDown>
              </Hour>
              <Remove>
                <Icon icon="delete" onClick={this.hideNewBlock} />
              </Remove>
            </Row>
          )}
          {!intervals.length && !showAddBlock && (
            <div>No working hours specified</div>
          )}
          {!showAddBlock ? (
            <AddBlock onClick={this.showNewBlock}>+ Add working block</AddBlock>
          ) : (
            <AddBlock onClick={this.saveBlock}>+ Save</AddBlock>
          )}
        </Container>
      </CompactDataRow>
    );
  }
}

export default WorkingHoursIntervalEditor;
