import {TOGGLE} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TOGGLE,
  attribute: 'app_reservations_allowed',
  label: {
    default: 'App Reservations Allowed',
    short: 'App Reservations Allowed',
  },
});
