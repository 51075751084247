// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder components
import SignChangeOrderContractModal from '../../components/SignChangeOrderContractModal/SignChangeOrderContractModal';

// changeOrder events
import changeOrderSignedEvent from '../../events/changeOrderSigned.event.changeOrder';
import showSignChangeOrderContractEvent from '../../events/showSignChangeOrderContract.event.changeOrder';

// contract api
import getContractByCsrIdApi from '@matthahn/sally-fn/lib/contract/api/getContractByCsrId.api.contract';
import uploadContractByCsrIdApi from '@matthahn/sally-fn/lib/contract/api/uploadContractByCsrId.api.contract';

// driver api
import getDriverByIdApi from '@matthahn/sally-fn/lib/driver/api/getByID.api.driver';

// email api
import sendEmailApi from '@matthahn/sally-fn/lib/email/api/send.api.email';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// file lib
import fileExtension from 'file-extension';

// lib
import blobToFile from '../../../libs/blobToFile';

// pdf api
import convertToPDFApi from '@matthahn/sally-fn/lib/pdf/api/convert.api.pdf';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// rental api
import getRentalByIdApi from '@matthahn/sally-fn/lib/rental/api/getByID.api.rental';

// rental documents
import contractFile from '@matthahn/sally-fn/lib/rental/documents/folders/contract.document.rental';

class SignChangeOrderContractContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.props.subscribe(showSignChangeOrderContractEvent.subscribe(this.show));
  }

  state = {
    changeOrder: null,
    driver: null,
    html: '',
    loading: false,
    rental: null,
    signatureRequest: null,
    signing: false,
    visible: false,
  };

  show = async ({changeOrder, signatureRequest}) => {
    if (!changeOrder || !signatureRequest) return;

    this.setState({
      visible: true,
      loading: true,
      changeOrder,
      signatureRequest,
    });

    try {
      const [driver, rental] = await Promise.all([
        getDriverByIdApi(changeOrder.driver),
        getRentalByIdApi(changeOrder.rental),
      ]);
      const html = await getContractByCsrIdApi(signatureRequest.id);
      this.setState({loading: false, driver, rental, html});
    } catch (error) {
      this.setState({loading: false, visible: false});
      alert.error('Error signing contract');
    }
  };

  hide = () => {
    const {loading, signing} = this.state;
    if (loading || signing) return;
    this.setState({visible: false});
  };

  signContract = async (html, {ignoreLoading = false} = {}) => {
    const {changeOrder, rental, signing, driver, signatureRequest} = this.state;
    if (!ignoreLoading && signing) return;
    this.setState({signing: true});
    try {
      const blob = await convertToPDFApi(html);
      const doc = blobToFile(blob);

      const formMeta = new FormData();

      formMeta.append('document_file', doc);

      await uploadContractByCsrIdApi(signatureRequest.id, formMeta);
      changeOrderSignedEvent.publish({changeOrder});
      const extension = fileExtension(doc.name);
      this.setState({signing: false, visible: false});
      alert.success('Contract generated successfully');
      try {
        await sendEmailApi({
          file: doc,
          driver_emails: driver.email,
          name: contractFile.fileName({
            rental,
            extension,
          }),
          extension,
        });
      } catch (sendEmailError) {
        // DO NOTHING
      }
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({signing: false});
    }
  };

  signingContractErrorHandler = (error) => {
    const {message} = parseError(error);
    alert.error(message);
  };

  render() {
    const {loading, visible, rental, driver, html, signing} = this.state;
    return (
      <SignChangeOrderContractModal
        driver={driver}
        html={html}
        loading={loading}
        onClose={this.hide}
        onExec={this.signContract}
        onExecError={this.signingContractErrorHandler}
        rental={rental}
        signing={signing}
        visible={visible}
      />
    );
  }
}

export default subscriptionHOC(SignChangeOrderContractContainer);
