import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
import {string} from '@matthahn/sally-fw/lib/type';

export default attribute({
  type: SELECT,
  attribute: 'action',
  api: string,
  label: {
    default: 'Action',
    short: 'Action',
  },
  options: ['closed', 'open'],
});
