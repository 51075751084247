// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class ContractGeneratorContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
  };

  state = {
    visible: false,
  };

  show = () => this.setState({visible: true});

  hide = () => {
    this.setState({visible: false});
  };

  render() {
    const {visible} = this.state;
    return (
      <Modal
        title="Contract Generator"
        visible={visible}
        onClose={this.hide}
        size="full"
      >
        {(Content) => (
          <Content padding="none">
            {/* <iframe
              title="Contract generator"
              // src="https://docs.google.com/document/d/e/2PACX-1vQbU8VdLML1K-rfow8exQvS1WiuvU_HF9QI29yjerFGQJszkV8H9rxVc3HhfqptQmZ5gmTP80DsOpUQ/pub?embedded=true"
              src="https://docs.google.com/document/d/1_reiGjelTX_6yEv2aWWPYxkftmPccnM8As6FYy7Cv0I/edit"
              style={{
                width: '100%',
                height: 'calc(100vh - 200px)',
                border: 'none',
                padding: '0px',
                margin: '0px',
              }}
            ></iframe> */}
          </Content>
        )}
      </Modal>
    );
  }
}

export default ContractGeneratorContainer;
