// reducedDeductible lib
import generateReducedDeductibleKey from '../../../lib/generateKey.lib.reducedDeductible';

const selectOption = ({options, onChange}) => (selectedOption) => {
  const option = [...options].find(
    (option) => generateReducedDeductibleKey(option) === selectedOption
  );
  onChange(option);
};

export default selectOption;
