import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';

// Redux Store
import store from './redux/store';

// App
import {App} from './components/app/App';

// Libs
import routerChangePrompt from './libs/routerChangePrompt';

render(
  <Provider store={store}>
    <Router getUserConfirmation={routerChangePrompt}>
      <Route component={App} />
    </Router>
  </Provider>,
  document.getElementById('root')
);
