import styled from 'styled-components';

const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
`;

export default Content;
