import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

class Spireon extends Component {
  static propTypes = {
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  content = () => {
    const {gas} = this.props;

    return gas.spireon_status === 'done' ? (
      <CheckBox value={true} onChange={() => {}} size="small" />
    ) : (
      <CheckBox value={false} onChange={() => {}} size="small" />
    );
  };

  render() {
    const {section, gas, equipment} = this.props;

    const content = this.content();

    return equipment ? (
      content
    ) : (
      <Section section={section} gas={gas}>
        {content}
      </Section>
    );
  }
}

export default Spireon;
