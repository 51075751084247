import {Component} from 'react';
import PropTypes from 'prop-types';

// Components
// import Section from '../Section/Section';
// import ImagePreview from '../ImagePreview/ImagePreview';

class Pictures extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    return null;
    // const {section, gas} = this.props;
    // return (
    //   <Section section={section} gas={gas}>
    //     <ImagePreview src={gas?.vehicle_image?.document_file} />
    //   </Section>
    // );
  }
}

export default Pictures;
