const DEFAULT_STATE = {
  disabled: false,
  generating: false,
  visible: false,
  hide: false,
  step: 1,
  done: 0,
  goingBack: false,
  dealership: null,
  minimized: false,
};

export default DEFAULT_STATE;
