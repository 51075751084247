export default (file, name, {isUrl = false} = {}) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  document.body.appendChild(a);
  //Create a DOMString representing the blob
  //and point the link element towards it
  const url = isUrl ? file : window.URL.createObjectURL(file);
  a.href = url;
  if (isUrl) a.target = '_blank';
  a.download = name;
  //programatically click the link to trigger the download
  a.click();
  //release the reference to the file by revoking the Object URL
  window.URL.revokeObjectURL(url);
};
