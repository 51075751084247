import React from 'react';
import PropTypes from 'prop-types';

// components
import {Modal, Table, Portal} from '@matthahn/sally-ui';

// local data
import columns from './columns';

// contract lib
import getContractVersion from '../../lib/getVersion.lib.contract';

const SelectContract = ({loading, visible, contracts, onContract, onClose}) => (
  <Modal
    visible={visible}
    onClose={onClose}
    title="Select contract version"
    portal={Portal.assetsContainer.secondary}
  >
    {(Content) => (
      <Content padding="none">
        <Table
          columns={columns}
          height={350}
          loading={loading}
          noBorder
          noRadius
          smallNoResultsLabel
        >
          {(TableRow) =>
            [
              ...contracts.map((contract) => (
                <TableRow key={contract.id} onClick={onContract(contract)}>
                  {(TableColumn) => [
                    <TableColumn key="name" span={2}>
                      {contract.name}
                    </TableColumn>,
                    <TableColumn key="version">
                      {getContractVersion(contract)}
                    </TableColumn>,
                  ]}
                </TableRow>
              )),
            ].filter((row) => !!row)
          }
        </Table>
      </Content>
    )}
  </Modal>
);

SelectContract.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  contracts: PropTypes.array,
  onContract: PropTypes.func,
  onClose: PropTypes.func,
};

export default SelectContract;
