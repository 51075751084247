import React from 'react';
import PropTypes from 'prop-types';

// app
import isProduction from '../../../app/lib/isProduction.app';

// colors
import {white, red} from '@matthahn/sally-ui/lib/libs/colors';

// components
import BarLoader from 'react-spinners/BarLoader';

// local components
import Badge from './components/Badge';
import Bar from './components/Bar';
import Container from './components/Container';

const OcrIndicatorButton = ({numberOfProcesses, onClick}) => (
  <Container production={isProduction()} onClick={onClick}>
    <i className="mdi mdi-ocr" />
    {!!numberOfProcesses && <Badge>{numberOfProcesses}</Badge>}
    {!!numberOfProcesses && (
      <Bar>
        <BarLoader
          width="100%"
          height={6}
          color={isProduction() ? red : white}
        />
      </Bar>
    )}
  </Container>
);

OcrIndicatorButton.propTypes = {
  numberOfFinishedUploads: PropTypes.number,
  numberOfProcesses: PropTypes.number,
  onClick: PropTypes.func,
};

export default OcrIndicatorButton;
