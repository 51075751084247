// branch components
import BranchAccidentInfoStep from '../../components/BranchAccidentInfoStep/BranchAccidentInfoStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class BranchAccidentInfoStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    accident_information: '',
    refresh: 0,
  };

  changedAttributes = [];

  componentDidMount() {
    // const {setStepChangeValidator, subscribe} = this.props;
    const {subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    // setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      accident_information: branch?.accident_information || '',
      refresh: new Date().getTime(),
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = ['accident_information'];
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  generateBranch = () => {
    const {branch} = this.props;
    const {accident_information} = this.state;
    return {
      ...branch,
      accident_information,
    };
  };

  continue = () => {
    const {onNext} = this.props;
    const branch = this.generateBranch();
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = () => {
    const {onBack} = this.props;
    const branch = this.generateBranch();
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  change = (accident_information) => () => {
    this.changedAttributes = ['accident_information'];
    this.setState({accident_information});
  };

  render() {
    const {Container, onNext} = this.props;
    const {...attributes} = this.state;
    return (
      <BranchAccidentInfoStep
        {...attributes}
        Container={Container}
        onBack={this.back}
        onChange={this.change}
        onNext={!!onNext ? this.continue : null}
      />
    );
  }
}

export default subscriptionHOC(BranchAccidentInfoStepContainer);
