// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch components
import BranchInsurancePolicyStep from '../../components/BranchInsurancePolicyStep/BranchInsurancePolicyStep';

// branch events
import branchSelectedEvent from '../../../branch/events/selected.event.branch';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// insurancePolicy attributes
import insuranceCompanyNameAttribute from '../../attributes/insurance_company_name.attribute.insurancePolicy';
import insuranceCompanyNumberAttribute from '../../attributes/insurance_company_number.attribute.insurancePolicy';
import policyEffectiveDateAttribute from '../../attributes/policy_effective_date.attribute.insurancePolicy';
import policyExpirationDateAttribute from '../../attributes/policy_expiration_date.attribute.insurancePolicy';
import policyNumberAttribute from '../../attributes/policy_number.attribute.insurancePolicy';

// insurancePolicy preparations
import createInsurancePolicyPrep from '../../preparation/create.preparation.insurancePolicy';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class BranchInsurancePolicyStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    insurance_company_name: insuranceCompanyNameAttribute(''),
    insurance_company_number: insuranceCompanyNumberAttribute(''),
    policy_effective_date: policyEffectiveDateAttribute(''),
    policy_expiration_date: policyExpirationDateAttribute(''),
    policy_number: policyNumberAttribute(''),
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      insurance_company_name: insuranceCompanyNameAttribute(
        branch?.vehicle_insurance_policy?.insurance_company_name || ''
      ),
      insurance_company_number: insuranceCompanyNumberAttribute(
        branch?.vehicle_insurance_policy?.insurance_company_number || ''
      ),
      policy_effective_date: policyEffectiveDateAttribute(
        branch?.vehicle_insurance_policy?.policy_effective_date || ''
      ),
      policy_expiration_date: policyExpirationDateAttribute(
        branch?.vehicle_insurance_policy?.policy_expiration_date || ''
      ),
      policy_number: policyNumberAttribute(
        branch?.vehicle_insurance_policy?.policy_number || ''
      ),
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = ['vehicle_insurance_policy'];
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      title: 'Confirm',
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  continue = async () => {
    const {onNext} = this.props;
    const branch = await this.generateBranch(true);
    if (!branch) return;
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = async () => {
    const {onBack} = this.props;
    const branch = await this.generateBranch(false);
    if (!branch) return;
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  generateBranch = async () => {
    const {branch} = this.props;
    try {
      const {...attributes} = this.state;
      const vehicle_insurance_policy = await createInsurancePolicyPrep({
        ...attributes,
      });
      return {
        ...branch,
        vehicle_insurance_policy: !!branch?.vehicle_insurance_policy
          ? {
              ...branch.vehicle_insurance_policy,
              ...vehicle_insurance_policy,
            }
          : {...vehicle_insurance_policy},
      };
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      return null;
    }
  };

  stepChangeValidator = async () => {
    const branch = await this.generateBranch();
    return {
      shouldContinue: !!branch,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = (value, key) => {
    this.changedAttributes = ['vehicle_insurance_policy'];
    this.setState({[key]: value});
  };

  render() {
    const {Container} = this.props;
    const {...attributes} = this.state;
    return (
      <BranchInsurancePolicyStep
        {...attributes}
        Container={Container}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(BranchInsurancePolicyStepContainer);
