const columns = [
  {
    key: 'quantity',
    label: '',
  },
  {
    key: 'name',
    label: 'Items',
    span: 4,
  },
  {
    key: 'price',
    label: 'Cost',
  },
];

export default columns;
