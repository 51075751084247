// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// legacy components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BranchRentalFeesEditorModal = ({
  attributes,
  onChange,
  onClose,
  onSave,
  rentalFees,
  saving,
  title,
  visible,
}) => (
  <Modal
    title={title}
    subtitle="Rental Fees Editor"
    visible={visible}
    onClose={onClose}
    buttonsRight={[{label: 'Save', loading: saving, onClick: onSave}]}
  >
    {(Content) => (
      <Content padding="none" noBorder>
        {attributes.map((attribute) => (
          <CompactDataRow
            key={attribute.attribute}
            label={attribute.label.default}
            wrapContent
            noPadding
          >
            <AttributeInput
              value={attribute}
              onChange={onChange}
              size="small"
              disabled={saving}
              flat
            />
          </CompactDataRow>
        ))}
      </Content>
    )}
  </Modal>
);

BranchRentalFeesEditorModal.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  rentalFees: PropTypes.object,
  saving: PropTypes.bool,
  title: PropTypes.node,
  visible: PropTypes.bool,
};

export default BranchRentalFeesEditorModal;
