// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import Icon from '@matthahn/sally-ui/lib/components/Icon/Icon';
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// layout components
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';
import CompactLabel from '../../../layout/components/CompactLabel/CompactLabel';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Attribute from './components/Attribute';
import States from './components/States';

// local lib
import getAttributeName from './lib/getAttributeName.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchRequirementsStep = ({
  attributes,
  Container,
  onBack,
  onChange,
  onNext,
}) => (
  <Fragment>
    <Container padding="none" noBorder>
      {attributes.map((attribute) => (
        <Fragment key={attribute.key}>
          <CompactLabel>{attribute.label}</CompactLabel>
          {attribute.states.map((state) => (
            <CompactDataRow key={state.key} label={state.label} noPadding>
              {!!state.attributes.length && (
                <States>
                  {state.attributes.map((stateAttribute) => (
                    <Attribute
                      key={stateAttribute}
                      onClick={onChange({
                        object: attribute.key,
                        state: state.key,
                        attribute: stateAttribute,
                      })}
                    >
                      {getAttributeName({
                        attributes: attribute.attributes,
                        attribute: stateAttribute,
                      })}
                      <Icon icon="close" />
                    </Attribute>
                  ))}
                </States>
              )}
              <Select
                value=""
                options={attribute.attributes}
                onChange={(selectedAttribute) =>
                  onChange({
                    object: attribute.key,
                    state: state.key,
                    attribute: selectedAttribute,
                  })()
                }
                size="small"
                flat
              />
            </CompactDataRow>
          ))}
        </Fragment>
      ))}
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchRequirementsStep.propTypes = {
  attributes: PropTypes.array,
  Container: PropTypes.any,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

export default BranchRequirementsStep;
