import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {set as setAct} from '../../redux/actions';

// Api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';
import listDealershipsApi from '@matthahn/sally-fn/lib/dealership/api/list.api.dealership';

// Components
import {FullScreenLoader} from '@matthahn/sally-ui';

// Error
import parseError from '../../../error/parseError';

// Lib
import {lib} from '@matthahn/sally-ui';
import orderByDate from '../../../libs/orderByDate';

// Alerts
const {alert} = lib;

class DealershipFetchContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    dispatch: PropTypes.func,
  };

  state = {
    inited: false,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {dispatch} = this.props;
    try {
      const {data: rawDealerships} = await api(listDealershipsApi, {
        ordering: '-created_at',
      });
      const dealerships = orderByDate(rawDealerships, 'created_at', 'desc');
      dispatch(setAct({dealerships}));
      this.setState({inited: true});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      alert.info('Refresh');
    }
  };

  render() {
    const {children} = this.props;
    const {inited} = this.state;
    return inited ? children : <FullScreenLoader />;
  }
}

export default connect()(DealershipFetchContainer);
