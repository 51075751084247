import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import {Icon} from '@matthahn/sally-ui';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {darkGrey, grey, white},
} = lib;

const Container = styled.div`
  width: 70px;
  height: 100%;
  border-style: none solid;
  border-width: 1px;
  border-color: ${({red}) => (red ? white : grey)};
  position: relative;
  color: ${({red}) => (red ? white : darkGrey)};
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;

const Search = ({red, onClick}) => (
  <Container red={red} onClick={onClick}>
    <Icon icon="search1" />
  </Container>
);

Search.propTypes = {
  red: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Search;
