// draft
import {EditorState, ContentState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class HtmlEditor extends Component {
  static propTypes = {
    html: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    refresh: PropTypes.any,
    toolbar: PropTypes.object,
  };

  state = {
    content: EditorState.createEmpty(),
  };

  componentDidMount() {
    const {html} = this.props;
    this.setState({content: this.fromHtml(html)});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh)
      this.setState({content: this.fromHtml(this.props.html)});
  }

  fromHtml = (html) => {
    const contentBlock = htmlToDraft(html);
    return !!contentBlock
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks)
        )
      : EditorState.createEmpty();
  };

  toHtml = (content) => draftToHtml(convertToRaw(content.getCurrentContent()));

  onChange = (content) => {
    const {loading, onChange} = this.props;
    if (loading) return;
    const html = this.toHtml(content);
    this.setState({content});
    onChange(html);
  };

  render() {
    const {toolbar} = this.props;
    const {content} = this.state;
    return (
      <Editor
        editorState={content}
        onEditorStateChange={this.onChange}
        toolbar={toolbar}
      />
    );
  }
}

export default HtmlEditor;
