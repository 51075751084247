import Dexie from 'dexie';

// Constants
import DATABASE_NAME from './constants/name.constant.database';

// Lib
import fieldsToModel from './lib/fieldsToModel.lib.database';

// Drivers
import driverFields from '../driver/database/fields.database.driver';
import driverName from '../driver/database/name.database.driver';

// Vehicles
import vehicleFields from '../vehicle/database/fields.database.vehicle';
import vehicleName from '../vehicle/database/name.database.vehicle';

const db = new Dexie(DATABASE_NAME);
db.version(1).stores({
  [driverName]: fieldsToModel(driverFields),
  [vehicleName]: fieldsToModel(vehicleFields),
});

export default db;
