import React from 'react';
import PropTypes from 'prop-types';

// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';

// local components
import Branch from './components/Branch';

const SelectBranchModal = ({branches, onClose, onSelect, visible}) => (
  <Modal visible={visible} onClose={onClose} title="Select Branch">
    {(Content) => (
      <Content padding="none" noBorder>
        {branches.map((branch) => (
          <Branch key={branch.id} onClick={onSelect(branch)}>
            {branch.name}
          </Branch>
        ))}
      </Content>
    )}
  </Modal>
);

SelectBranchModal.propTypes = {
  branches: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  visible: PropTypes.bool,
};

export default SelectBranchModal;
