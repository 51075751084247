import React from 'react';
import {v4} from 'uuid';

// Components
import RequiredSignature from '../components/RequiredSignature';

export default (data, {direct, required, onGeneration}) => (
  <RequiredSignature
    height="40px"
    key={v4()}
    direct={direct}
    required={required}
    onGeneration={onGeneration}
  />
);
