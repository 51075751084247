import styled from 'styled-components';

const FlowScroll = styled.div`
  width: 100%;
  max-height: calc(100vh - 300px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default FlowScroll;
