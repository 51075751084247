import styled from 'styled-components';

const Search = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export default Search;
