import React from 'react';
import PropTypes from 'prop-types';

// constants
import gasIcon from '@matthahn/sally-fn/lib/gas/constants/icon.const.gas';

// components
import {Modal} from '@matthahn/sally-ui';
import Loader from '../../../components/components/atoms/Loader/Loader';

// layout components
import ImageSlider from '../../../layout/components/ImageSlider/ImageSlider';

// local components
import ContentColumn from './components/ContentColumn';
import ImagesColumn from './components/ImagesColumn';
import Row from './components/Row';

const GasModal = ({
  loading,
  visible,
  branch,
  gas,
  children,
  onClose,
  images,
}) => (
  <Modal
    visible={visible}
    title={!!gas ? gas.vehicle.svid : 'Gas'}
    subtitle="GAS"
    size={!!images?.length ? 'full' : 'large'}
    icon={gasIcon}
    onClose={onClose}
  >
    {(Content) =>
      loading ? (
        <Content padding="none">
          <Loader />
        </Content>
      ) : (
        <Content padding="none" noBorder>
          {!!images?.length ? (
            <Row>
              <ContentColumn>{children}</ContentColumn>
              <ImagesColumn>
                <ImageSlider branch={branch} images={images} />
              </ImagesColumn>
            </Row>
          ) : (
            children
          )}
        </Content>
      )
    }
  </Modal>
);

GasModal.propTypes = {
  branch: PropTypes.any,
  children: PropTypes.node,
  gas: PropTypes.object,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  images: PropTypes.array,
};

export default GasModal;
