const DEFAULT_STATE = {
  branch: null,
  disabled: false,
  generating: false,
  hide: false,
  minimized: false,
  originalBranch: null,
  saving: false,
  step: 'branch',
  visible: false,
};

export default DEFAULT_STATE;
