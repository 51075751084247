// redux
import store from '../../redux/store';

const canUpdatePermissionsPermission = (
  {
    is_superuser = false,
    permission_control_permission = false,
  } = store.getState().authorization.userData
) => is_superuser || permission_control_permission;

export default canUpdatePermissionsPermission;
