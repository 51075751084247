import React, {Component} from 'react';
import PropTypes from 'prop-types';

// api lib
import everythingFromApi from '../../../libs/everythingFromApi';

// contract api
import list from '@matthahn/sally-fn/lib/document/api/getContracts.api.document';

// contract components
import SelectContract from '../../components/SelectContract/SelectContract';

// contract events
import contractVersionSelectedEvent from '../../events/contractVersionSelected.event.contract';
import selectContractVersionEvent from '../../events/selectContractVersion.event.contract';

// contract lib
import availableContracts from '@matthahn/sally-fn/lib/document/libs/availableContracts.lib.document';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

class SelectContractContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
    contracts: [],
  };

  componentDidMount() {
    this.props.subscribe(
      selectContractVersionEvent.subscribe(this.getContract)
    );
  }

  getContract = async ({subtype} = {}) => {
    this.setState({loading: true, visible: true});

    const query = {
      ordering: '-created_at',
    };

    try {
      const contracts = await everythingFromApi(list, query);
      this.setState({
        contracts: availableContracts(contracts, {subtype}),
        loading: false,
      });
    } catch (error) {
      this.setState({loading: false, visible: false, contracts: []});
    }
  };

  selectContract = (contract) => () => {
    if (this.state.loading) return;
    contractVersionSelectedEvent.publish({contract});
    this.setState({visible: false});
  };

  close = () => {
    const {loading} = this.state;
    if (loading) return;
    this.setState({visible: false});
  };

  render() {
    const {loading, visible, contracts} = this.state;
    return (
      <SelectContract
        loading={loading}
        visible={visible}
        contracts={contracts}
        onContract={this.selectContract}
        onClose={this.close}
      />
    );
  }
}

export default subscriptionHOC(SelectContractContainer);
