// Action constants
import {
  PAGE_CHANGE,
  SET_LATEST_CUSTOMER,
  SET_UNSAVED_CHANGES,
  UNSET_UNSAVED_CHANGES,
} from './constants';

export const changePage = (page) => ({
  type: PAGE_CHANGE,
  data: page,
});

export const latestCustomer = (customerID = null) => ({
  type: SET_LATEST_CUSTOMER,
  data: customerID,
});

export const setUnsavedChanges = () => ({type: SET_UNSAVED_CHANGES});

export const unsetUnsavedChanges = () => ({type: UNSET_UNSAVED_CHANGES});
