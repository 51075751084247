// local components
import Container from './components/Container';
import Input from './components/Input';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const ContractInputField = ({disabled, onChange, value}) => (
  <Container disabled={disabled}>
    {disabled ? (
      value
    ) : (
      <Input
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
        value={value}
      />
    )}
  </Container>
);

ContractInputField.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ContractInputField;
