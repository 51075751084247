import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// types
import formattedInteger from '@matthahn/sally-fw/lib/type/types/formattedInteger.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'garage_vehicle_capacity',
  display: formattedInteger,
  input: formattedInteger,
  api: number,
  label: {
    default: 'Garage Vehicle Capacity',
    short: 'Vehicle Capacity',
  },
});
