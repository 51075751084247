import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class TestDrive extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Brakes firm" noBorder>
          <CheckBox
            size="small"
            value={gas.test_drive_brakes}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Steering alignment">
          <CheckBox
            size="small"
            value={gas.test_drive_steering_alignment}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Suspension noise">
          <CheckBox
            size="small"
            value={gas.test_drive_suspension_noise}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Linear acceleration">
          <CheckBox
            size="small"
            value={gas.test_drive_linear_acceleration}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Notes on test drive results">
          {gas.test_drive_notes}
        </GasSectionRow>
      </Section>
    );
  }
}

export default TestDrive;
