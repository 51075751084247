// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// branch api
import createOperationsTimeRulesExceptionsApi from '@matthahn/sally-fn/lib/branch/api/createOperationsTimeRulesExceptions.api.branch';
import deleteOperationsTimeRulesExceptionApi from '@matthahn/sally-fn/lib/branch/api/deleteOperationsTimeRulesException.api.branch';

// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// date lib
import {isAfter, parseISO} from 'date-fns';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// legacy components
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// local data
import columns from './constants/columns.constants';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

// types
import dateTimeType from '@matthahn/sally-fw/lib/type/types/dateTime.type';

// workingHourException attributes
import actionAttribute from '../../../workingHourException/attributes/action.attribute.workingHourException';
import endDateAttribute from '../../../workingHourException/attributes/end_date.attribute.workingHourException';
import startDateAttribute from '../../../workingHourException/attributes/start_date.attribute.workingHourException';

// workingHourException preparations
import createWorkingHourExceptionPreparation from '../../../workingHourException/preparations/create.preparation.workingHourException';

class WorkingHoursExceptionsEditor extends Component {
  static propTypes = {
    exceptions: PropTypes.array,
    onChange: PropTypes.func,
    operationTimeRulesId: PropTypes.number,
    timeZone: PropTypes.string,
  };

  state = {
    action: actionAttribute(''),
    deleting: [],
    editRowVisible: false,
    end_date: endDateAttribute(''),
    saving: false,
    start_date: startDateAttribute(''),
  };

  change = (value, key) => {
    if (this.state.saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {exceptions, onChange, operationTimeRulesId} = this.props;
    const {start_date, end_date, action, saving} = this.state;

    if (saving) return;

    this.setState({saving: true});

    try {
      const exceptionBody = await createWorkingHourExceptionPreparation({
        action,
        end_date,
        start_date,
      });

      if (
        isAfter(
          parseISO(exceptionBody.start_date),
          parseISO(exceptionBody.end_date)
        )
      )
        throw new Error('Start date can not be after end date.');

      const exception = await createOperationsTimeRulesExceptionsApi({
        ...exceptionBody,
        ruleset: operationTimeRulesId,
      });

      onChange([...exceptions, exception]);
      this.setState({saving: false, editRowVisible: false});
    } catch (error) {
      this.setState({saving: false});
      const {message} = parseError(error);
      alert.error(message);
    }
  };

  showEditRow = () => {
    if (this.state.saving) return;
    this.setState({
      action: actionAttribute(''),
      editRowVisible: true,
      end_date: endDateAttribute(''),
      start_date: startDateAttribute(''),
    });
  };

  hideEditRow = () => {
    if (this.state.saving) return;
    this.setState({editRowVisible: false});
  };

  deleteException = (exception) => async () => {
    const {exceptions, onChange} = this.props;
    const {deleting} = this.state;

    if (deleting.includes(exception.id)) return;

    this.setState({deleting: [...deleting, exception.id]});

    try {
      await deleteOperationsTimeRulesExceptionApi(exception.id);
      const updatedExceptions = [...exceptions].filter(
        ({id}) => id !== exception?.id
      );
      onChange(updatedExceptions);
      this.setState({
        deleting: [...deleting].filter((id) => id !== exception.id),
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({
        deleting: [...deleting].filter((id) => id !== exception.id),
      });
    }
  };

  render() {
    const {exceptions, timeZone} = this.props;
    const {
      action,
      deleting,
      editRowVisible,
      end_date,
      saving,
      start_date,
    } = this.state;

    const endDate = end_date?.api?.format?.() || null;
    const startDate = start_date?.api?.format?.() || null;

    return (
      <Table
        columns={columns}
        infinite={false}
        noBorder
        noRadius
        smallNoResultsLabel
      >
        {(TableRow) =>
          [
            ...exceptions.map((exception) => (
              <TableRow
                key={exception.ide}
                actions={[
                  {
                    icon: 'delete',
                    color: 'black',
                    onClick: this.deleteException(exception),
                  },
                ]}
                loading={deleting.includes(exception.id)}
              >
                {(TableColumn) => [
                  <TableColumn key="start_date">
                    {dateTimeType(exception.start_date).format()}
                  </TableColumn>,
                  <TableColumn key="end_date">
                    {dateTimeType(exception.end_date).format()}
                  </TableColumn>,
                  <TableColumn key="action">{exception.action}</TableColumn>,
                ]}
              </TableRow>
            )),
            editRowVisible && (
              <TableRow key="edit" loading={saving}>
                {(TableColumn) => [
                  <TableColumn key="start_date">
                    <AttributeInput
                      value={start_date}
                      onChange={this.change}
                      size="small"
                      timeZone={timeZone}
                      disabledDates={!!endDate ? [{after: endDate}] : []}
                    />
                  </TableColumn>,
                  <TableColumn key="end_date">
                    <AttributeInput
                      value={end_date}
                      onChange={this.change}
                      size="small"
                      timeZone={timeZone}
                      disabledDates={!!startDate ? [{before: startDate}] : []}
                    />
                  </TableColumn>,
                  <TableColumn key="action">
                    <AttributeInput
                      value={action}
                      onChange={this.change}
                      size="small"
                      notFilterable
                    />
                  </TableColumn>,
                ]}
              </TableRow>
            ),
            <TableRow key="actions">
              {(TableColumn) => [
                <TableColumn key="empty" span={2}></TableColumn>,
                <TableColumn key="action">
                  {editRowVisible ? (
                    <Fragment>
                      <Button
                        theme="black"
                        icon="plus"
                        loading={saving}
                        size="small"
                        onClick={this.save}
                      >
                        Add
                      </Button>
                      <Button
                        theme="transparent"
                        color="darkGrey"
                        disabled={saving}
                        size="small"
                        onClick={this.hideEditRow}
                      >
                        Cancel
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      theme="grey"
                      icon="plus"
                      size="small"
                      onClick={this.showEditRow}
                    >
                      Add
                    </Button>
                  )}
                </TableColumn>,
              ]}
            </TableRow>,
          ].filter((row) => !!row)
        }
      </Table>
    );
  }
}

export default WorkingHoursExceptionsEditor;
