import {SELECT} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// timezone constants
import TIME_ZONES from '../../timeZone/constants/list.constant.timeZone';

export default attribute({
  type: SELECT,
  attribute: 'timezone_name',
  label: {
    default: 'Time Zone',
    short: 'Time Zone',
  },
  options: [...TIME_ZONES],
});
