import styled from 'styled-components';

// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const CompactLabel = styled.div`
  width: 100%;
  height: 24px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  background: ${grey};
  color: ${darkGrey};
  font-weight: 700;
  text-transform: uppercase;
`;

export default CompactLabel;
