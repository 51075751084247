import React from 'react';
import PropTypes from 'prop-types';

// components
import TableRow from '@matthahn/sally-ui/lib/components/Table/components/Row.Table';

const GasSectionRow = ({
  children = null,
  flat = false,
  noBorder = false,
  title = null,
}) => (
  <TableRow portions={4} noBorder={noBorder} noHover>
    {(TableColumn) => [
      <TableColumn key="title">{title}</TableColumn>,
      <TableColumn key="content" span={3} noPadding={flat}>
        {children}
      </TableColumn>,
    ]}
  </TableRow>
);

GasSectionRow.propTypes = {
  children: PropTypes.node,
  flat: PropTypes.bool,
  noBorder: PropTypes.bool,
  title: PropTypes.node,
};

export default GasSectionRow;
