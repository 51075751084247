// redux store
import store from '../../redux/store';

const getViolationOptions = ({
  selectedViolation = null,
  violations = store.getState().ticket.violations,
} = {}) => {
  const violationOptions = [...violations].map(({violation}) => ({
    value: violation,
    label: violation,
  }));
  return !!selectedViolation &&
    ![...violationOptions].find(({value}) => value === selectedViolation)
    ? [
        {value: selectedViolation, label: selectedViolation},
        ...violationOptions,
      ]
    : violationOptions;
};

export default getViolationOptions;
