import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const Branch = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border-top: 1px solid ${grey};

  &:hover {
    background: ${grey};
  }
`;

export default Branch;
