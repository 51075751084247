import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// components

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// tesla api
import uploadTeslaChargesApi from '@matthahn/sally-fn/lib/tesla/api/uploadCharges.api.tesla';

// tesla components
import UploadChargesModal from '../../components/UploadChargesModal/UploadChargesModal';

class UploadChargesContainer extends Component {
  static defaultProps = {
    children: PropTypes.func,
  };

  state = {
    loading: false,
    visible: false,
  };

  show = () => this.setState({visible: true});

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  upload = async ([file]) => {
    const {loading} = this.state;
    if (loading || !file) return;
    this.setState({loading: true});
    try {
      const fileName = 'tesla.csv';
      const formMeta = new FormData();
      formMeta.append('csv_file', file, fileName);
      await uploadTeslaChargesApi(formMeta);
      alert.success('Charges uploaded');
      this.setState({loading: false, visible: false});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {children} = this.props;
    const {loading, visible} = this.state;
    return (
      <Fragment>
        {children({showModal: this.show})}
        <UploadChargesModal
          loading={loading}
          visible={visible}
          onClose={this.hide}
          onUpload={this.upload}
        />
      </Fragment>
    );
  }
}

export default UploadChargesContainer;
