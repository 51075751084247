const generateBundle = ({bundles, docConfig}) => {
  const missingBundles = [...bundles].filter(
    (bundle) =>
      !docConfig.bundles.find(
        (existingBundle) => existingBundle.name === bundle.bundle
      )
  );

  const preparedBundles = [
    ...docConfig.bundles,
    ...[...missingBundles].map((missingBundle) => ({
      name: missingBundle.bundle,
      doc_types: [],
    })),
  ].map((bundle) => ({
    ...bundle,
    doc_types: [...bundle.doc_types].filter(
      (file) =>
        !!docConfig.doc_types.find((existingFile) => existingFile === file)
    ),
  }));

  return {...docConfig, bundles: preparedBundles};
};

export default generateBundle;
