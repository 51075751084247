import styled from 'styled-components';

const Processes = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;
`;

export default Processes;
