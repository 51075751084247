// Rental Rate
import currentRentalRate from '@matthahn/sally-fn/lib/rentalRate/libs/currentRentalRate.lib.rentalRate';

// Types
import {amount as amountType} from '@matthahn/sally-fn/lib/types';

// Weights
import mainWeight from '../weights/mainWeight';
import liabilityInsurancePayment from '../weights/liabilityInsurancePayment';

export const raw = ({
  rental: {rates = [], reduced_deductible = false} = {},
} = {}) =>
  (currentRentalRate(rates, reduced_deductible) * liabilityInsurancePayment) /
  mainWeight;

export default (args) => `$${amountType(raw(args)).format()}`;
