import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styling
import visibility from '../../../../styling/visibility.styling';

// Helper Components
import Content from './components/Content.Aside';

const Container = styled.div`
  width: 400px;
  height: 100vh;
  z-index: 10000;
  position: fixed;
  padding-top: 70px;
  top: 0px;
  right: 0px;
  ${({visible}) => visibility({visible, transform: 'translateX(100%)'})};
`;

class Aside extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  onClose = () => {
    const {visible, onClose} = this.props;
    if (!visible) return;
    onClose();
  };

  render() {
    const {children, visible} = this.props;
    return (
      <Container visible={visible}>
        <Content visible={visible} onClose={this.onClose}>
          {children}
        </Content>
      </Container>
    );
  }
}

export default Aside;
