// components
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import P from '@matthahn/sally-ui/lib/components/P/P';
import Table from '@matthahn/sally-ui/lib/components/Table/Table';

// layout components
import CardLoader from '../../../layout/components/CardLoader/CardLoader';

// local components
import Number from './components/Number';
import Total from './components/Total';

// local data
import columns from './data/columns.data';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// types
import formattedInteger from '@matthahn/sally-fw/lib/type/types/formattedInteger.type';

const TicketSummaryModal = ({
  dynamicColumns,
  loading,
  onClose,
  onFilter,
  summary,
  visible,
}) => (
  <Modal
    title="Tickets Summary"
    visible={visible}
    onClose={onClose}
    // size="large"
  >
    {(Content) =>
      loading ? (
        <Content padding="none">
          <CardLoader />
        </Content>
      ) : (
        <Content padding="none">
          <Table
            columns={columns(dynamicColumns)}
            infinite={false}
            noBorder
            noRadius
          >
            {(TableRow) => [
              ...summary.map((summaryOverview) => (
                <TableRow key={summaryOverview.state}>
                  {(TableCol) => [
                    <TableCol
                      key="state"
                      onClick={onFilter({
                        state: summaryOverview.state,
                        column: '',
                      })}
                    >
                      <P>{summaryOverview.state}</P>
                    </TableCol>,
                    ...dynamicColumns.map((column) => (
                      <TableCol
                        key={column.key}
                        onClick={onFilter({
                          state: summaryOverview.state,
                          column: column.key,
                        })}
                        align="center"
                      >
                        {!!summaryOverview.summary?.[column.key] ? (
                          <Number>
                            {formattedInteger(
                              summaryOverview.summary?.[column.key]
                            ).format()}
                          </Number>
                        ) : (
                          '-'
                        )}
                      </TableCol>
                    )),
                  ]}
                </TableRow>
              )),
              <TableRow key="total">
                {(TableCol) => [
                  <TableCol key="state"></TableCol>,
                  ...dynamicColumns.map((column) => (
                    <TableCol
                      key={column.key}
                      bold
                      align="center"
                      onClick={onFilter({
                        state: '',
                        column: column.key,
                      })}
                    >
                      {!!column.total ? (
                        <Total>{formattedInteger(column.total).format()}</Total>
                      ) : (
                        '-'
                      )}
                    </TableCol>
                  )),
                ]}
              </TableRow>,
            ]}
          </Table>
        </Content>
      )
    }
  </Modal>
);

TicketSummaryModal.propTypes = {
  dynamicColumns: PropTypes.array,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  summary: PropTypes.array,
  visible: PropTypes.bool,
};

export default TicketSummaryModal;
