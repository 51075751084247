import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {init} from '../../../redux/authorization/actions';

// auth
import SSOContainer from '../../../auth/containers/SSOContainer/SSOContainer';

// Components
import {
  Page,
  AlertSystem,
  NotificationSystem,
  FullScreenLoader,
  Confetti,
  RocketLauncher,
  Portal,
} from '@matthahn/sally-ui';
// import {SignIn} from '../../components/templates/SignIn';
import {Layout} from '../../components/templates/Layout';
import {Routes} from '../../app/Routes';

// Containers
// import {AlternativeSignInContainer} from '../../containers/organisms/AlternativeSignInContainer';
// import {PinSetupContainer} from '../../containers/organisms/PinSetupContainer';
// import {UserSwitchContainer} from '../../containers/organisms/UserSwitchContainer';
import Shortcuts from '../../../spotlight/containers/Shortcuts/Shortcuts';

// Auth
import TraditionalSignInPage, {
  route as routeTraditionalSignIn,
} from '../../../auth/pages/TraditionalSignInPage';

// Sockets
import socket from '@matthahn/sally-fw/lib/sockets/socket';

class App extends Component {
  static propTypes = {
    initialized: PropTypes.bool,
    inApp: PropTypes.bool,
    token: PropTypes.string,
    username: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    show: false,
  };

  componentDidMount() {
    this.props.dispatch(init());
    socket.connect(process.env.REACT_APP_SOCKETS_URL);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialized && this.props.initialized)
      setTimeout(() => this.setState({show: true}), 500);
  }

  renderContent() {
    // const {initialized, token, inApp} = this.props;
    const {initialized, token} = this.props;
    if (!initialized) return null;
    if (!token) {
      return (
        <Switch>
          <Route
            exact
            path={routeTraditionalSignIn()}
            component={TraditionalSignInPage}
          />
          <Route component={SSOContainer} />
        </Switch>
      );
    }
    return (
      <Layout>
        <Routes />
        {!!token && <Shortcuts />}
        {/* <AlternativeSignInContainer /> */}
        {/* <PinSetupContainer /> */}
        {/* <UserSwitchContainer /> */}
      </Layout>
    );
  }

  render() {
    const {show} = this.state;
    return (
      <Page>
        {this.renderContent()}
        <Portal container={Portal.assetsContainer.secondary} always>
          <RocketLauncher />
          <AlertSystem />
          <Confetti />
          <NotificationSystem />
          {!show && <FullScreenLoader fixed />}
        </Portal>
      </Page>
    );
  }
}

export {App};

export default connect((state) => ({...state.authorization}))(App);
