import styled from 'styled-components';

// Lib
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {grey},
} = lib;

const Row = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0px 15px;
  align-items: center;
  justify-content: space-between;
  border-style: none none solid none;
  border-width: 1px;
  border-color: ${grey};
`;

export default Row;
