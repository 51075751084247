// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// changeOrder api
import createChangeOrderApi from '@matthahn/sally-fn/lib/changeOrder/api/create.api.changeOrder.';

// changeOrder events
import changeOrderCreatedEvent from '../../../changeOrder/events/created.event.changeOrder';

// driver api
import getDriverByIdApi from '@matthahn/sally-fn/lib/driver/api/getByID.api.driver';

// driver routes
// import customerEditRoute from '../../../components/pages/CustomerEditPage/route';

// lib
import orderByDate from '@matthahn/sally-fw/lib/lib/orderByDate';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// mileagePackage lib
import getActiveMileagePackage from '../../../mileagePackage/lib/getActiveMileagePackage.lib.mileagePackage';
import shouldSelectMileagePackage from '../../../mileagePackage/lib/shouldSelectMileagePackage.lib.mileagePackage';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// react router
import {withRouter} from 'react-router-dom';

// reducedDeductible lib
import getActiveReducedDeductible from '../../../reducedDeductible/lib/getActive.lib.reducedDeductible';

// rental actions
import {open as launchRental} from '../../../components/containers/organisms/LaunchRentalFlowContainer/actions';

// rental api
import getRentalByIdApi from '@matthahn/sally-fn/lib/rental/api/getByID.api.rental';
import updateRentalApi from '@matthahn/sally-fn/lib/rental/api/update.api.rental';

// rental components
import AppRentalLaunchModal from '../../components/AppRentalLaunchModal/AppRentalLaunchModal';

// rental events
import showAppLaunchFlowEvent from '../../events/showAppLaunchFlow.event.rental';

// rentalRate attributes
import {rate as rateAttr} from '@matthahn/sally-fn/lib/rentalRate/attributes';

// rentalRate lib
import {getRentalRateObject as getCurrentRate} from '@matthahn/sally-fn/lib/rentalRate/libs/currentRentalRate.lib.rentalRate';
import computeRates from '@matthahn/sally-fn/lib/rentalRate/libs/compute.lib.rentalRate';
import getLowestRate from '../../../rentalRate/lib/getLowestRate.lib.rentalRate';
import isRateBelowThreshold from '../../../rentalRate/lib/isRateBelowThreshold.lib.rentalRate';

// rentalRate permissions
import rentalRateOverridePermission from '../../../rentalRate/permissions/override.permission.rentalRate';

// rentalRate services
import getSuggestedRentalRateService from '../../../rentalRate/services/getSuggestedRentalRate.service.rentalRate';

// turnover actions
import {updateRental as updateRentalAction} from '../../../components/containers/templates/TurnoverContainer/actions';

// vehicle api
import getVehicleByIdApi from '@matthahn/sally-fn/lib/vehicle/api/getByID.api.vehicle';

class AppRentalLaunchContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    subscribe: PropTypes.func,
    userId: PropTypes.number,
  };

  static DEFAULT_STATE = {
    currentMileagePackage: null,
    currentRate: null,
    currentReducedDeductible: null,
    driver: null,
    loading: false,
    mileagePackage: null,
    rate: rateAttr(''),
    reducedDeductible: null,
    rental: null,
    saving: false,
    vehicle: null,
    visible: false,
  };

  state = {
    ...this.constructor.DEFAULT_STATE,
  };

  componentWillMount() {
    this.props.subscribe(showAppLaunchFlowEvent.subscribe(this.show));
  }

  show = async ({rentalId}) => {
    this.setState({
      ...this.constructor.DEFAULT_STATE,
      loading: true,
      visible: true,
    });

    try {
      const [rental] = await Promise.all([getRentalByIdApi(rentalId)]);
      const [driver, vehicle, suggestedRate] = await Promise.all([
        getDriverByIdApi(rental?.drivers?.[0]?.driver?.id),
        getVehicleByIdApi(rental?.vehicle?.id),
        getSuggestedRentalRateService({rentalId}),
      ]);

      const rates = computeRates({
        rental,
        rates: orderByDate([...rental.rates], 'effective_date', 'desc'),
      });

      const currentRate = getCurrentRate(rates);
      const currentReducedDeductible = getActiveReducedDeductible(rental);
      const currentMileagePackage = getActiveMileagePackage(rental);

      this.setState({
        currentMileagePackage,
        currentRate,
        currentReducedDeductible,
        driver,
        loading: false,
        rental,
        suggestedRate,
        vehicle,
      });
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({visible: false});
    }
  };

  hide = () => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    const {loading, saving} = this.state;
    if (loading || saving) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {dispatch} = this.props;
    const {
      loading,
      mileagePackage,
      rate,
      reducedDeductible,
      rental,
      saving,
      suggestedRate,
      vehicle,
    } = this.state;

    if (saving || loading) return;

    const rateDisplayValue = rate.input.format();
    const new_rental_rate = rate.api.format();
    const new_deductible = !!reducedDeductible
      ? reducedDeductible?.deductible_amount
      : null;
    const new_deductible_rate = !!reducedDeductible
      ? reducedDeductible?.rate
      : null;
    const new_mileage_package_rate = !!mileagePackage
      ? mileagePackage.rate
      : null;
    const new_alloted_mileage = !!mileagePackage
      ? mileagePackage.alloted_mileage
      : null;

    // const requireMileagePackage = shouldSelectMileagePackage({
    //   vehicle: rental.vehicle,
    // });

    // const shouldCreateChangeOrder = !(
    //   rateDisplayValue === '' &&
    //   !reducedDeductible &&
    //   (!requireMileagePackage || (requireMileagePackage && !mileagePackage))
    // );

    const shouldCreateChangeOrder =
      rateDisplayValue !== '' || !!reducedDeductible || !!mileagePackage;

    // if (requireMileagePackage && !mileagePackage)
    //   return alert.info('Select a mileage package.');

    if (
      !!suggestedRate &&
      !rentalRateOverridePermission() &&
      isRateBelowThreshold({
        rate: new_rental_rate,
        suggestedRate,
      })
    )
      return alert.warning(
        `You do not have permission to set the rate bellow $${getLowestRate({
          suggestedRate,
        })}.`
      );

    this.setState({saving: true});

    try {
      const updatedRental = await updateRentalApi(rental.id, {
        checked_by_ops: true,
      });
      if (shouldCreateChangeOrder)
        await this.createChangeOrder({
          new_rental_rate,
          rental,
          new_deductible,
          new_deductible_rate,
          new_mileage_package_rate,
          new_alloted_mileage,
        });
      this.setState({saving: false, visible: false});
      dispatch(updateRentalAction(vehicle.id, updatedRental));
      alert.success('Rental saved');
      this.launchRental({rental: updatedRental, vehicle});
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({saving: false});
    }
  };

  createChangeOrder = async ({
    new_rental_rate,
    rental,
    new_deductible,
    new_deductible_rate,
    new_mileage_package_rate,
    new_alloted_mileage,
  }) => {
    const changeOrderBody = Object.entries({
      new_rental_rate,
      rental: rental.id,
      new_deductible,
      new_deductible_rate,
      new_mileage_package_rate,
      new_alloted_mileage,
    }).reduce(
      (combined, [key, value]) =>
        value === null ? combined : {...combined, [key]: value},
      {}
    );
    const createdChangeOrder = await createChangeOrderApi(changeOrderBody);
    changeOrderCreatedEvent.publish({changeOrder: createdChangeOrder});
  };

  launchRental = ({rental, vehicle}) =>
    this.props.dispatch(launchRental({...rental, vehicle: {...vehicle}}));

  runMvr = () => {
    return null;
    // const {history} = this.props;
    // const {driver, loading, saving} = this.state;
    // if (loading || saving) return;
    // const hasMvr = !!driver?.mvr_report_date;
    // return hasMvr
    //   ? null
    //   : () => {
    //       history.push(customerEditRoute(driver.id));
    //       this.setState({visible: false});
    //     };
  };

  render() {
    const {
      currentMileagePackage,
      currentRate,
      currentReducedDeductible,
      driver,
      loading,
      mileagePackage,
      rate,
      reducedDeductible,
      rental,
      saving,
      suggestedRate,
      vehicle,
      visible,
    } = this.state;
    return (
      <AppRentalLaunchModal
        currentMileagePackage={currentMileagePackage}
        currentRate={currentRate}
        currentReducedDeductible={currentReducedDeductible}
        driver={driver}
        loading={loading}
        mileagePackage={mileagePackage}
        onChange={this.change}
        onClose={this.hide}
        onMvr={this.runMvr()}
        onSave={this.save}
        rate={rate}
        reducedDeductible={reducedDeductible}
        rental={rental}
        saving={saving}
        suggestedRate={suggestedRate}
        vehicle={vehicle}
        visible={visible}
        showMileagePackage={shouldSelectMileagePackage({
          vehicle,
        })}
      />
    );
  }
}

export default withRouter(
  subscriptionHOC(
    connect((state) => ({userId: state.authorization.userID}))(
      AppRentalLaunchContainer
    )
  )
);
