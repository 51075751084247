// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// dealership components
import DealershipGeneralInfoStep from '../../components/DealershipGeneralInfoStep/DealershipGeneralInfoStep';

// dealership preparations
import generalDealershipInfoPreparation from '../../preparations/generalInfo.preparation.dealership';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local lib
import generateAttributes from './lib/generateAttributes.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

class DealershipGeneralInfoStepContainer extends Component {
  static propTypes = {
    canEditName: PropTypes.bool,
    dealership: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    ...generateAttributes(),
    generated: false,
  };

  componentDidMount() {
    this.initialize();
  }

  initialize = (dealership = this.props.dealership) => {
    this.setState({
      ...generateAttributes(dealership),
      generated: true,
    });
  };

  continue = async () => {
    const {onNext} = this.props;
    const dealership = await this.generateDealership(true);
    if (!dealership) return;
    onNext({dealership});
  };

  back = async () => {
    const {onBack} = this.props;
    const dealership = await this.generateDealership(false);
    if (!dealership) return;
    onBack({dealership});
  };

  generateDealership = async (validate = false) => {
    const {dealership} = this.props;
    try {
      const {generated, ...attributes} = this.state;
      const preparedDealership = await generalDealershipInfoPreparation({
        validate,
        ...attributes,
      });
      return {...dealership, ...preparedDealership};
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      return null;
    }
  };

  change = (value, key) => {
    this.setState({[key]: value});
  };

  render() {
    const {Container, canEditName} = this.props;
    const {...attributes} = this.state;

    return (
      <DealershipGeneralInfoStep
        {...attributes}
        canEditName={canEditName}
        Container={Container}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(DealershipGeneralInfoStepContainer);
