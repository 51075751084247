import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {ChangePasswordModal} from '../../../components/molecules/ChangePasswordModal';

// Functions
import {auth} from '@matthahn/sally-fn';
import {lib} from '@matthahn/sally-ui';

// Api
const {
  api: {passwordChange},
} = auth;

// Alerts
const {alert} = lib;

// Constants
const DEFAULT_STATE = {
  loading: false,
  username: '',
  oldPassword: '',
  newPassword: '',
  repeatedPassword: '',
};

class ChangePasswordContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    username: PropTypes.string,
  };

  state = {...DEFAULT_STATE};

  onChange = (value, key) => {
    if (this.state.loading) return;
    this.setState({[key]: value});
  };

  hide = () => {
    if (this.state.loading) return;
    this.props.onHide();
  };

  changePassword = async () => {
    const {
      loading,
      username,
      oldPassword,
      newPassword,
      repeatedPassword,
    } = this.state;
    if (loading) return;

    if (this.props.username !== username) {
      alert.info('Insert your valid username');
      return;
    }

    if (!oldPassword.trim().length)
      return alert.info('Insert the old password');
    if (!newPassword.trim().length) return alert.info('Insert a new password');
    if (newPassword !== repeatedPassword)
      return alert.info('New passwords must match');

    this.setState({loading: true});

    try {
      await passwordChange(oldPassword, newPassword, repeatedPassword);
      alert.success('Password successfully changed');
      this.setState({...DEFAULT_STATE});
      this.props.onHide();
    } catch (error) {
      this.setState({loading: false});
      if (error.fields && error.fields.includes('old_password')) {
        alert.error('Insert your valid password');
        return;
      }
      if (error.fields && error.fields.includes('new_password2')) {
        alert.error(error.origin.new_password2.join(' '));
        return;
      }
      alert.error(error.message);
    }
  };

  render() {
    const {visible} = this.props;
    return (
      <ChangePasswordModal
        visible={visible}
        onHide={this.hide}
        onSave={this.changePassword}
        onChange={this.onChange}
        {...this.state}
      />
    );
  }
}

export default ChangePasswordContainer;
