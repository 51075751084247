import styled from 'styled-components';

// colors
import {grey, white, red, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Section = styled.div`
  height: 30px;
  background: ${({selected}) => (selected ? red : white)};
  border-style: solid;
  border-width: 2px;
  border-color: ${({selected}) => (selected ? red : grey)};
  color: ${({selected}) => (selected ? white : darkGrey)};
  display: flex;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;

export default Section;
