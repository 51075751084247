// components
import Select from '@matthahn/sally-ui/lib/components/Select/Select';

// mileagePackage lib
import generateMileagePackageKey from '../../lib/generateMileagePackageKey.lib.mileagePackage';
import generateMileagePackageOptions from '../../lib/generateMileagePackageOptions.lib.mileagePackage';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// redux
import {connect} from 'react-redux';

class SelectMileagePackage extends Component {
  static propTypes = {
    branch: PropTypes.number,
    dealership: PropTypes.number,
    disabled: PropTypes.bool,
    mileagePackage: PropTypes.object,
    mileagePackages: PropTypes.array,
    onChange: PropTypes.func,
    selectProps: PropTypes.object,
  };

  static defaultProps = {
    selectProps: {},
  };

  change = (mileageKey) => {
    const {disabled, onChange} = this.props;
    if (disabled) return;
    const [rate, alloted_mileage] = mileageKey.split('-');
    onChange({
      rate,
      alloted_mileage,
    });
  };

  render() {
    const {
      branch,
      dealership,
      disabled,
      mileagePackage,
      mileagePackages,
      selectProps,
    } = this.props;

    return (
      <Select
        disabled={disabled}
        notFilterable
        onChange={this.change}
        options={generateMileagePackageOptions({
          mileagePackages,
          dealership,
          branch,
        })}
        value={generateMileagePackageKey(mileagePackage)}
        dropdownOptions={{size: 400}}
        {...selectProps}
      >
        Mileage Package
      </Select>
    );
  }
}

export default connect((state) => ({
  mileagePackages: state.mileagePackage.mileagePackages,
}))(SelectMileagePackage);
