import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// types
import fourDecimalAmount from '@matthahn/sally-fw/lib/type/types/fourDecimalAmount.type';
import formattedFourDecimalNumber from '@matthahn/sally-fw/lib/type/types/formattedFourDecimalNumber.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'tax_rate',
  display: fourDecimalAmount,
  input: formattedFourDecimalNumber,
  api: number,
  label: {
    default: 'Tax Rate',
    short: 'Tax Rate',
  },
});
