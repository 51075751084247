// branch components
import BranchBundlesStep from '../../components/BranchBundlesStep/BranchBundlesStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// driver documents
import driverFileBundles from '../../../driver/documents/bundles/list.bundle.document.driver';
import driverFolders from '@matthahn/sally-fn/lib/driver/documents/documents';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local lib
import generateBundle from './lib/generateBundle.lib';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// rental documents
import rentalFileBundles from '../../../rental/documents/bundles/list.bundle.document.rental';
import rentalFolders from '@matthahn/sally-fn/lib/rental/documents/documents';

// vehicle documents
import vehicleFileBundles from '../../../vehicle/documents/bundles/list.bundle.document.vehicle';
import vehicleFolders from '@matthahn/sally-fn/lib/vehicle/documents/documents';

class BranchBundlesStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    driver_doc_config: null,
    rental_doc_config: null,
    vehicle_doc_config: null,
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      driver_doc_config: generateBundle({
        docConfig: branch.driver_doc_config,
        bundles: driverFileBundles,
      }),
      rental_doc_config: generateBundle({
        docConfig: branch.rental_doc_config,
        bundles: rentalFileBundles,
      }),
      vehicle_doc_config: generateBundle({
        docConfig: branch.vehicle_doc_config,
        bundles: vehicleFileBundles,
      }),
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = Object.keys(this.state);
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  generateBranch = () => {
    const {branch} = this.props;
    const {...attributes} = this.state;
    return {
      ...branch,
      ...attributes,
    };
  };

  continue = () => {
    const {onNext} = this.props;
    const branch = this.generateBranch();
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = () => {
    const {onBack} = this.props;
    const branch = this.generateBranch();
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  stepChangeValidator = async () => {
    const branch = await this.generateBranch();
    return {
      shouldContinue: true,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = ({fileType, objectType, bundleName}) => () => {
    if (!this.changedAttributes.includes(objectType))
      this.changedAttributes = [...this.changedAttributes, objectType];
    const updatedFiles = {
      ...this.state[objectType],
      bundles: [...this.state[objectType].bundles].map((bundle) =>
        bundle.name === bundleName
          ? {
              ...bundle,
              doc_types: bundle.doc_types.includes(fileType)
                ? [...bundle.doc_types].filter(
                    (existingFileType) => existingFileType !== fileType
                  )
                : [...bundle.doc_types, fileType],
            }
          : bundle
      ),
    };
    this.setState({[objectType]: updatedFiles});
  };

  folders = () => {
    const {
      driver_doc_config,
      vehicle_doc_config,
      rental_doc_config,
    } = this.state;
    return !!driver_doc_config && !!vehicle_doc_config && !!rental_doc_config
      ? [
          {
            key: 'driver_doc_config',
            label: 'Driver Bundles',
            bundles: [...driver_doc_config.bundles],
            availableBundles: [...driverFileBundles],
            availableFiles: [...driverFolders].filter((availableFile) =>
              driver_doc_config.doc_types.includes(availableFile.type)
            ),
          },
          {
            key: 'vehicle_doc_config',
            label: 'Vehicle Bundles',
            bundles: [...vehicle_doc_config.bundles],
            availableBundles: [...vehicleFileBundles],
            availableFiles: [...vehicleFolders].filter((availableFile) =>
              vehicle_doc_config.doc_types.includes(availableFile.type)
            ),
          },
          {
            key: 'rental_doc_config',
            label: 'Rental Bundles',
            bundles: [...rental_doc_config.bundles],
            availableBundles: [...rentalFileBundles],
            availableFiles: [...rentalFolders].filter((availableFile) =>
              rental_doc_config.doc_types.includes(availableFile.type)
            ),
          },
        ]
      : [];
  };

  render() {
    const {Container} = this.props;
    return (
      <BranchBundlesStep
        Container={Container}
        folders={this.folders()}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(BranchBundlesStepContainer);
