// layout components
import ModalLoader from '../../../layout/components/ModalLoader/ModalLoader';

// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// local components
import Branch from './components/Branch';
import Content from './components/Content';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BranchSelectStep = ({branches, Container, loading, onNext}) =>
  loading ? (
    <Container padding="none">
      <ModalLoader />
    </Container>
  ) : (
    <Container>
      <Content>
        {branches
          .sort(sortByString({key: 'name', direction: 'asc'}))
          .map((branch) => (
            <Branch onClick={onNext(branch)} key={branch.id}>
              {branch.name}
            </Branch>
          ))}
      </Content>
    </Container>
  );

BranchSelectStep.propTypes = {
  branch: PropTypes.object,
  Container: PropTypes.any,
  loading: PropTypes.bool,
  onNext: PropTypes.func,
};

export default BranchSelectStep;
