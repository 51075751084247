import React from 'react';
import PropTypes from 'prop-types';

// chicagoTicket containers
import UploadChicagoTicketsContainer from '../../../../../chicagoTicket/containers/UploadChicagoTicketsContainer/UploadChicagoTicketsContainer';

// Components
import Button from './Button.Profile';
import Column from './Column.Profile';
import Container from './Container.Profile';

// tesla containers
import UploadChargesContainer from '../../../../../tesla/containers/UploadChargesContainer/UploadChargesContainer';

const Actions = ({actions = []}) => (
  <Container>
    <Column>
      <UploadChargesContainer>
        {({showModal}) => (
          <Button icon="upload" onClick={showModal}>
            Upload tesla charges
          </Button>
        )}
      </UploadChargesContainer>
    </Column>
    <Column>
      <UploadChicagoTicketsContainer>
        {({showModal}) => (
          <Button icon="upload" onClick={showModal}>
            Upload Chicago Tickets
          </Button>
        )}
      </UploadChicagoTicketsContainer>
    </Column>
    {actions.map(({key, label, ...action}) => (
      <Column key={key}>
        <Button {...action}>{label}</Button>
      </Column>
    ))}
  </Container>
);

Actions.propTypes = {
  actions: PropTypes.array,
};

export default Actions;
