// Constants
import DEDUCTIBLE_REGULAR from '@matthahn/sally-fn/lib/rental/constants/deductibleRegular.consts.rental';
import DEDUCTIBLE_REDUCED from '@matthahn/sally-fn/lib/rental/constants/deductibleReduced.consts.rental';

// Types
import {amount as amountType} from '@matthahn/sally-fn/lib/types';

export default ({rental: {reduced_deductible = false}} = {}) =>
  `$${amountType(
    reduced_deductible ? DEDUCTIBLE_REDUCED : DEDUCTIBLE_REGULAR
  ).format()}`;
