import styled from 'styled-components';

const Bar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
`;

export default Bar;
