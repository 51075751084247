// Lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// Redux
import store from '../../redux/store';

export default (branch) =>
  !branch
    ? null
    : [...store.getState().branch.branches].find(
        ({id}) => `${id}` === `${fkOrId(branch)}`
      ) || null;
