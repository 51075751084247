import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Modal, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../components/components/atoms/AttributeInput/AttributeInput';

// lib
import getTimeZoneFromBranch from '../../../libs/getTimeZoneFromBranch';

const ReleaseVehicleFromHoldModal = ({
  date_etc,
  hold_type,
  loading,
  onChange,
  onClose,
  onResolve,
  onSave,
  reason,
  saving,
  vehicle,
  visible,
}) => (
  <Modal
    visible={visible}
    icon="warning"
    title={vehicle?.svid || '-'}
    onClose={onClose}
    buttonsLeft={[
      {
        label: 'Release',
        loading: loading,
        disabled: saving,
        onClick: onResolve,
        theme: 'grey',
      },
    ]}
    buttonsRight={[
      {
        label: 'Save',
        loading: saving,
        disabled: loading,
        onClick: onSave,
      },
    ]}
    size="small"
  >
    {(Content) => (
      <Content>
        <Row margin>
          <Column>
            <AttributeInput
              value={hold_type}
              onChange={onChange}
              disabled={loading || saving}
              notFilterable
            >
              {hold_type.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row margin>
          <Column>
            <AttributeInput
              value={date_etc}
              onChange={onChange}
              disabled={loading}
              timeZone={getTimeZoneFromBranch({
                branch: vehicle?.sally_branch,
              })}
            >
              {date_etc.label.default}
            </AttributeInput>
          </Column>
        </Row>
        <Row>
          <Column>
            <AttributeInput
              value={reason}
              onChange={onChange}
              disabled={loading || saving}
            >
              {reason.label.default}
            </AttributeInput>
          </Column>
        </Row>
      </Content>
    )}
  </Modal>
);

ReleaseVehicleFromHoldModal.propTypes = {
  date_etc: PropTypes.object,
  hold_type: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onResolve: PropTypes.func,
  onSave: PropTypes.func,
  reason: PropTypes.object,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
};

export default ReleaseVehicleFromHoldModal;
