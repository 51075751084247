const BreakException = {};

const filterUntil = ({
  list = [],
  match = () => true,
  stopWhen = () => false,
} = []) => {
  let filteredList = [];

  try {
    [...list].forEach((item) => {
      if (match(item)) filteredList = [...filteredList, item];
      if (stopWhen({item, list: [...filteredList]})) throw BreakException;
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  return filteredList;
};

export default filterUntil;
