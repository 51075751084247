// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import CompactLabel from '../../../layout/components/CompactLabel/CompactLabel';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';
import FlowScroll from '../../../layout/components/FlowScroll/FlowScroll';
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Grid from './components/Grid';
import Section from './components/Section';

// local lib
import getBundleName from './lib/getBundleName.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const BranchBundlesStep = ({Container, folders, onBack, onChange, onNext}) => (
  <Fragment>
    <Container padding="none">
      <FlowScroll>
        {folders.map((folder) => (
          <Fragment key={folder.key}>
            <CompactLabel>{folder.label}</CompactLabel>
            {folder.bundles.map((bundle) => (
              <CompactDataRow
                key={bundle.name}
                label={getBundleName({
                  bundles: folder.availableBundles,
                  bundle: bundle.name,
                })}
                noPadding
              >
                <Grid>
                  {folder.availableFiles.map((availableFile) => (
                    <Section
                      key={availableFile.type}
                      selected={bundle.doc_types.includes(availableFile.type)}
                      onClick={onChange({
                        fileType: availableFile.type,
                        objectType: folder.key,
                        bundleName: bundle.name,
                      })}
                    >
                      {availableFile.defaultFileName}
                    </Section>
                  ))}
                </Grid>
              </CompactDataRow>
            ))}
          </Fragment>
        ))}
      </FlowScroll>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack}>
            Back
          </Button>
        }
        right={
          <Button size="small" theme="grey" onClick={onNext}>
            Next
          </Button>
        }
      />
    </Container>
  </Fragment>
);

BranchBundlesStep.propTypes = {
  Container: PropTypes.any,
  folders: PropTypes.array,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onNext: PropTypes.func,
};

export default BranchBundlesStep;
