// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// ticket api
import getTicketSummaryApi from '@matthahn/sally-fn/lib/ticket/api/getSummary.api.ticket';

// ticket components
import TicketSummaryModal from '../../components/TicketSummaryModal/TicketSummaryModal';

// ticket events
import * as ticketCreatedEvent from '@matthahn/sally-fn/lib/ticket/events/created.event.ticket';
import * as ticketDeletedEvent from '@matthahn/sally-fn/lib/ticket/events/deleted.event.ticket';
import * as ticketUpdatedEvent from '@matthahn/sally-fn/lib/ticket/events/updated.event.ticket';
import filterTicketsEvent from '../../events/filter.event.ticket';
import multipleTicketsUpdatedEvent from '../../events/multipleUpdated.event.ticket';
import showTicketSummaryEvent from '../../events/showSummary.event.ticket';
import singleTicketCreatedEvent from '../../events/created.event.ticket';

// ticket redux actions
import {set as setAction} from '../../redux/actions';

class TicketSummaryContainer extends Component {
  static propTypes = {
    branches: PropTypes.array,
    dispatch: PropTypes.func,
    loadingSummary: PropTypes.bool,
    subscribe: PropTypes.func,
    summary: PropTypes.object,
  };

  state = {
    visible: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.props.subscribe(
      showTicketSummaryEvent.subscribe(this.show),
      multipleTicketsUpdatedEvent.subscribe(this.loadSummary),
      ticketCreatedEvent.sub(this.loadSummary),
      ticketDeletedEvent.sub(this.loadSummary),
      ticketUpdatedEvent.sub(this.loadSummary),
      singleTicketCreatedEvent.subscribe(this.loadSummary)
    );
    this.loadSummary();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadSummary = async () => {
    const {dispatch} = this.props;
    dispatch(setAction({loadingSummary: true}));

    try {
      const summary = await getTicketSummaryApi();
      dispatch(setAction({loadingSummary: false, summary}));
    } catch (error) {
      dispatch(setAction({loadingSummary: false}));
    }
  };

  show = () => {
    this.setState({visible: true});
  };

  hide = () => {
    this.setState({visible: false});
  };

  filter = (filterSet) => () => {
    filterTicketsEvent.publish(filterSet);
    this.setState({visible: false});
  };

  parsedSummary = () => {
    const {loadingSummary, summary} = this.props;
    const states = Object.keys(summary || {});
    return loadingSummary || !summary
      ? []
      : [...states]
          .reduce((combined, state) => {
            const summaryObject = summary?.[state];
            return !!summaryObject
              ? [...combined, {state, summary: {...summaryObject}}]
              : combined;
          }, [])
          .sort(
            (a, b) =>
              b.summary.not_charged_tickets - a.summary.not_charged_tickets
          );
  };

  dynamicColumns = () => {
    const {loadingSummary, summary} = this.props;
    return loadingSummary || !summary
      ? []
      : [
          ...Object.keys(Object.values(summary || {})?.[0] || {}).map(
            (key) => ({
              key,
              label: key.split('_').join(' '),
            })
          ),
        ].map((column) => ({
          ...column,
          align: 'center',
          total: Object.values(summary).reduce(
            (combined, summaryItem) => combined + summaryItem[column.key],
            0
          ),
        }));
  };

  render() {
    const {loadingSummary} = this.props;
    const {visible} = this.state;
    return (
      <TicketSummaryModal
        dynamicColumns={this.dynamicColumns()}
        loading={loadingSummary}
        onClose={this.hide}
        onFilter={this.filter}
        summary={this.parsedSummary()}
        visible={visible}
      />
    );
  }
}

export default connect((state) => ({
  branches: state.branch.branches,
  loadingSummary: state.ticket.loadingSummary,
  summary: state.ticket.summary,
}))(subscriptionHOC(TicketSummaryContainer));
