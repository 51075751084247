import styled from 'styled-components';

// Colors
import {lib} from '@matthahn/sally-ui';
const {
  colors: {yellow},
} = lib;

const SignedSignature = styled.img`
  width: 100%;
  display: block;
  background: ${yellow};
`;

export default SignedSignature;
