import styled from 'styled-components';

// Colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

const BranchContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grey};
  padding: 15px;
`;

export default BranchContainer;
