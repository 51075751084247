import offboard from './types/offboard.type.gas';
import onboard from './types/onboard.type.gas';
import onsite from './types/onsite.type.gas';

const types = [offboard, onboard, onsite];

export const getTypeBasedOnState = (state) =>
  [...types].find((t) => t.state === state);

export const getType = (type) => [...types].find((t) => t.type === type);

export default types;
