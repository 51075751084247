import styled from 'styled-components';

// colors
import {grey, darkGrey} from '@matthahn/sally-ui/lib/libs/colors';

const Attribute = styled.div`
  background: ${grey};
  padding: 5px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > i {
    color: ${darkGrey};
  }
`;

export default Attribute;
