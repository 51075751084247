import {TEXT} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

export default attribute({
  type: TEXT,
  attribute: 'gas_slack_channel',
  label: {
    default: 'GAS slack channel',
    short: 'GAS',
  },
});
