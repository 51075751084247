const getIntervals = (data) =>
  data.reduce((acc, curr, idx, arr) => {
    if (idx === 0 || curr !== arr[idx - 1] + 1) {
      acc.push([curr]);
    } else {
      acc[acc.length - 1][1] = curr;
    }
    return acc;
  }, []);

export default getIntervals;
