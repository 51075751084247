// lib
import sortByString from '@matthahn/sally-fw/lib/lib/sortByString';

// local components
import Branch from './components/Branch';
import Content from './components/Content';
import Title from './components/Title';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

const BranchSelectStep = ({branches, Container, onNext}) => (
  <Container padding="none">
    <Container noBorder>
      <Title>Select branch</Title>
      <Content>
        {branches
          .sort(sortByString({key: 'name', direction: 'asc'}))
          .map((branch) => (
            <Branch onClick={onNext(branch)} key={branch.id}>
              {branch.name}
            </Branch>
          ))}
      </Content>
    </Container>
  </Container>
);

BranchSelectStep.propTypes = {
  branch: PropTypes.object,
  Container: PropTypes.any,
  onNext: PropTypes.func,
};

export default BranchSelectStep;
