// alert
import alert from '@matthahn/sally-ui/lib/libs/alert';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local lib
import generateInitialState from './lib/generateInitialState.lib';

// paymentGroup api
import createPaymentGroupApi from '@matthahn/sally-fn/lib/paymentGroup/api/create.api.paymentGroup';

// paymentGroup components
import AddPaymentGroupModal from '../../components/AddPaymentGroupModal/AddPaymentGroupModal';

// paymentGroup events
import showAddNewPaymentGroupEvent from '../../events/showAddNewPaymentGroup.event.paymentGroup';

// paymentGroup preparations
import createPaymentGroupPreparation from '@matthahn/sally-fn/lib/paymentGroup/preparation/create.preparation.paymentGroup';

// paymentGroup routes
import routePaymentGroup from '../../pages/PaymentGroupPage/route';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// router
import {withRouter} from 'react-router-dom';

class AddPaymentGroupContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    ...generateInitialState(),
  };

  componentDidMount() {
    this.props.subscribe(showAddNewPaymentGroupEvent.subscribe(this.show));
  }

  show = () => {
    const {visible, loading} = this.state;
    if (visible || loading) return;
    this.setState({...generateInitialState(), visible: true});
  };

  hide = () => {
    if (this.state.loading) return;
    this.setState({visible: false});
  };

  change = (value, key) => {
    const {visible, loading} = this.state;
    if (!visible || loading) return;
    this.setState({[key]: value});
  };

  save = async () => {
    const {history} = this.props;
    const {visible, loading, ...attributes} = this.state;

    if (!visible || loading) return;

    this.setState({loading: true});

    try {
      const preparedPaymentGroup = await createPaymentGroupPreparation(
        attributes
      );
      const paymentGroup = await createPaymentGroupApi(preparedPaymentGroup);
      this.setState({visible: false, loading: false});
      history.push(routePaymentGroup(paymentGroup.id));
    } catch (error) {
      const {message} = parseError(error);
      alert.error(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {
      loading,
      name,
      owner_email,
      owner_first_name,
      owner_last_name,
      owner_phone_number,
      visible,
    } = this.state;
    return (
      <AddPaymentGroupModal
        loading={loading}
        name={name}
        onChange={this.change}
        onClose={this.hide}
        onSave={this.save}
        owner_email={owner_email}
        owner_first_name={owner_first_name}
        owner_last_name={owner_last_name}
        owner_phone_number={owner_phone_number}
        visible={visible}
      />
    );
  }
}

export default withRouter(subscriptionHOC(AddPaymentGroupContainer));
