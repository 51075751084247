// attribute lib
import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'summons_number',
      'violation_datetime',
      'due_datetime',
      'license_plate',
      'fine_amount',
      'payment_link',
      'state',
      'violation',
    ],
  });
