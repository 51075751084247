// lib
import fillArrayWithNumbers from '../../../../libs/fillArrayWithNumbers';

const convertIntervalToHours = (intervals) =>
  [...intervals]
    .map(([start, end]) => {
      const startHour = Math.ceil(start);
      const endHour = Math.floor(end);
      return [start, ...fillArrayWithNumbers(startHour, endHour), end];
    })
    .flat()
    .reduce(
      (hours, hour) => (hours.includes(hour) ? hours : [...hours, hour]),
      []
    )
    .sort((a, b) => a - b);

export default convertIntervalToHours;
