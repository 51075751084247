import jwt from 'jsonwebtoken';
import {auth} from '@matthahn/sally-fn';

// Store
import store from '../store';

// Action constants
import {SET_TOKEN} from './constants';

// Events
import {event as authEvent} from '@matthahn/sally-fn/lib/auth';

// Constants
import authKey from '../../constants/authKey.constants';

// Libraries
import * as storage from '../../libs/localStorage';
import roles from './roles';

// Sockets
import socket from '@matthahn/sally-fw/lib/sockets/socket';

// Storage
import appTokensStorage from '../../auth/storage/appTokens.storage.auth';

const {
  token: authToken,
  api: {refreshToken, getUserInfo},
} = auth;

const checkToken = (token) => async (dispatch) => {
  try {
    const oldData = jwt.decode(token);
    const appTokens = appTokensStorage.get() || '{}';
    const tokens = JSON.parse(appTokens);
    if (
      oldData.exp &&
      oldData.username &&
      oldData.exp * 1000 > new Date().getTime()
    ) {
      const userData = await getUserInfo(oldData.user_id, token);
      dispatch(signIn(token, userData, tokens));
      return;
    }
    const response = await refreshToken(token);
    const userData = await getUserInfo(oldData.user_id, response.token);
    dispatch(signIn(response.token, userData, tokens));
  } catch (error) {
    dispatch(signOut(false));
  }
};

export const init = () => (dispatch) => {
  const token = storage.get(authKey);
  if (!token)
    return dispatch({
      type: SET_TOKEN,
      data: {
        token: null,
        tokens: {},
        username: '',
        userID: 0,
        first_name: '',
        last_name: '',
        pin: null,
        roles: roles(),
        userData: {},
      },
    });
  dispatch(checkToken(token));
};

export const signIn = (token, userData, tokens = {}) => (
  dispatch,
  getState
) => {
  const auth = getState().authorization;

  try {
    const data = jwt.decode(token);
    data.token = token;
    storage.add(authKey, token);
    appTokensStorage.add(JSON.stringify(tokens));
    authToken.set(token, tokens);
    if (!auth.token) socket.authorize(token);
    const users = !![...auth.users].find((u) => u.user_id === data.user_id)
      ? [...auth.users]
      : [...auth.users, {...data, userData, tokens}];
    dispatch({
      type: SET_TOKEN,
      data: {
        inApp: true,
        token,
        tokens,
        userID: data.user_id || 0,
        username: data.username,
        first_name: data.first_name || '',
        last_name: data.last_name || '',
        pin: data.pin || null,
        roles: roles(data),
        users,
        userData,
      },
    });
  } catch (error) {
    dispatch(signOut());
  }
};

export const changeBranch = (branchId) => (dispatch, getState) => {
  const {userData} = getState().authorization;

  const newUserData = {
    ...userData,
    sally_branch: branchId * 1,
  };

  dispatch({
    type: SET_TOKEN,
    data: {
      userData: newUserData,
    },
  });
};

export const removeStaleUsers = () => (dispatch, getState) => {
  const {users, userID} = getState().authorization;
  const newUsers = [...users].filter((user) => {
    if (user.user_id === userID) return true;
    try {
      const oldData = jwt.decode(user.token);
      return (
        oldData.exp &&
        oldData.username &&
        oldData.exp * 1000 > new Date().getTime()
      );
    } catch (error) {
      return false;
    }
  });
  dispatch({type: SET_TOKEN, data: {users: newUsers}});
};

export const switchUser = (user) => ({
  type: SET_TOKEN,
  data: {
    token: user.token,
    tokens: user.tokens,
    userID: user.user_id,
    username: user.username,
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    roles: roles(user),
    userData: user.userData,
  },
});

export const pinChange = (pin, token) => (dispatch, getState) => {
  const {users, userID} = getState().authorization;
  const newUsers = [...users].map((user) =>
    user.user_id === userID ? {...user, pin} : user
  );
  try {
    storage.add(authKey, token);
    authToken.set(token);
    dispatch({
      type: SET_TOKEN,
      data: {
        token,
        pin,
        users: newUsers,
      },
    });
  } catch (error) {
    dispatch(signOut());
  }
};

export const signOut = (inApp = true) => (dispatch, getState) => {
  const {userID, users} = getState().authorization;
  storage.remove(authKey);
  authToken.remove();
  socket.deauthorize();
  const newUsers = [...users].filter((u) => u.user_id !== userID);
  dispatch({
    type: SET_TOKEN,
    data: {
      inApp,
      token: null,
      tokens: {},
      username: '',
      userID: 0,
      first_name: '',
      last_name: '',
      pin: null,
      roles: roles(),
      users: newUsers,
      userData: {},
    },
  });
};

authEvent.sub(() => {
  store.dispatch(signOut());
});
