// branch components
import BranchDocumentsStep from '../../components/BranchDocumentsStep/BranchDocumentsStep';

// branch events
import branchSelectedEvent from '../../events/selected.event.branch';

// driver documents
import driverFolders from '@matthahn/sally-fn/lib/driver/documents/documents';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// local lib
import generateDocumentObject from './lib/generateDocumentObject.lib';

// notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// rental documents
import rentalFolders from '@matthahn/sally-fn/lib/rental/documents/documents';

// vehicle documents
import vehicleFolders from '@matthahn/sally-fn/lib/vehicle/documents/documents';

class BranchDocumentsStepContainer extends Component {
  static propTypes = {
    branch: PropTypes.object,
    Container: PropTypes.any,
    goingBack: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onNext: PropTypes.func,
    setStepChangeValidator: PropTypes.func,
    subscribe: PropTypes.func,
    visible: PropTypes.bool,
  };

  state = {
    driver_doc_config: generateDocumentObject(),
    rental_doc_config: generateDocumentObject(),
    vehicle_doc_config: generateDocumentObject(),
  };

  changedAttributes = [];

  componentDidMount() {
    const {setStepChangeValidator, subscribe} = this.props;
    this.initialize();
    subscribe(branchSelectedEvent.subscribe(this.branchSelected));
    setStepChangeValidator(this.stepChangeValidator);
  }

  initialize = (branch = this.props.branch) => {
    this.setState({
      driver_doc_config: branch?.driver_doc_config || generateDocumentObject(),
      rental_doc_config: branch?.rental_doc_config || generateDocumentObject(),
      vehicle_doc_config:
        branch?.vehicle_doc_config || generateDocumentObject(),
    });
  };

  copySettings = (branch) => {
    this.changedAttributes = Object.keys(this.state);
    this.initialize(branch);
  };

  branchSelected = ({branch}) => {
    notify({
      id: 'changeBranch',
      title: 'Confirm',
      icon: undefined,
      content:
        'Are you sure you want to copy settings a different branch? This will undo this whole section.',
      primary: {
        label: 'Cancel',
        onClick: () => null,
      },
      secondary: {
        label: 'Continue',
        onClick: () => this.copySettings(branch),
      },
    });
  };

  generateBranch = () => {
    const {branch} = this.props;
    const {...attributes} = this.state;
    return {
      ...branch,
      ...attributes,
    };
  };

  continue = () => {
    const {onNext} = this.props;
    const branch = this.generateBranch();
    onNext({branch, changedAttributes: this.changedAttributes});
  };

  back = () => {
    const {onBack} = this.props;
    const branch = this.generateBranch();
    onBack({branch, changedAttributes: this.changedAttributes});
  };

  stepChangeValidator = async () => {
    const branch = await this.generateBranch();
    return {
      shouldContinue: true,
      data: {branch, changedAttributes: this.changedAttributes},
    };
  };

  change = ({fileType, objectType}) => () => {
    if (!this.changedAttributes.includes(objectType))
      this.changedAttributes = [...this.changedAttributes, objectType];
    const updatedFiles = {
      ...this.state[objectType],
      doc_types: this.state[objectType].doc_types.includes(fileType)
        ? this.state[objectType].doc_types.filter(
            (selectedFileType) => selectedFileType !== fileType
          )
        : [...this.state[objectType].doc_types, fileType],
      bundles: this.state[objectType].doc_types.includes(fileType)
        ? [...this.state[objectType].bundles].map((bundle) => ({
            ...bundle,
            doc_types: [...bundle.doc_types].filter(
              (selectedFileType) => selectedFileType !== fileType
            ),
          }))
        : this.state[objectType].bundles,
    };
    this.setState({[objectType]: updatedFiles});
  };

  folders = () => {
    const {
      driver_doc_config,
      vehicle_doc_config,
      rental_doc_config,
    } = this.state;
    return [
      {
        key: 'driver_doc_config',
        label: 'Driver Documents',
        availableFiles: [...driverFolders],
        selectedFiles: [...driver_doc_config.doc_types],
      },
      {
        key: 'vehicle_doc_config',
        label: 'Vehicle Documents',
        availableFiles: [...vehicleFolders],
        selectedFiles: [...vehicle_doc_config.doc_types],
      },
      {
        key: 'rental_doc_config',
        label: 'Rental Documents',
        availableFiles: [...rentalFolders],
        selectedFiles: [...rental_doc_config.doc_types],
      },
    ];
  };

  render() {
    const {Container} = this.props;
    return (
      <BranchDocumentsStep
        Container={Container}
        folders={this.folders()}
        onBack={this.back}
        onChange={this.change}
        onNext={this.continue}
      />
    );
  }
}

export default subscriptionHOC(BranchDocumentsStepContainer);
