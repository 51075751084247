import React, {Component} from 'react';
import PropTypes from 'prop-types';

// gas components
import Section from '../Section/Section';

// types
import {commaSeperatedNumber} from '@matthahn/sally-fn/lib/types';

class Mileage extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;
    return (
      <Section section={section} gas={gas}>
        {!!gas.mileage ? commaSeperatedNumber(gas.mileage).format() : '-'}
      </Section>
    );
  }
}

export default Mileage;
