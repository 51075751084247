// branch api
import updateBranchApi from '@matthahn/sally-fn/lib/branch/api/update.api.branch';

// branch events
import branchUpdatedEvent from '../events/updated.event.branch';

// branch lib
import didBranchAddressChange from '../lib/didAddressChange.lib.branch';
import generateLocationObject from '../lib/generateLocationObject.lib.branch';

// insurancePolicy api
import createInsurancePolicyApi from '@matthahn/sally-fn/lib/insurancePolicy/api/create.api.insurancePolicy';
import updateInsurancePolicyApi from '@matthahn/sally-fn/lib/insurancePolicy/api/update.api.insurancePolicy';

// map api
import geocodeApi from '@matthahn/sally-fn/lib/map/api/geocode.api.map';

// map constants
import getGoogleMapApiKey from '../../map/constants/googleMapApiKey.constant.map';

// map lib
import getLocation from '../../map/lib/getLocation.lib.map';
import prepareAddress from '../../map/lib/prepareAddress.lib.map';

const saveChangedBranchAttributesService = async ({
  originalBranch,
  updatedBranch,
  changedAttributes = [],
}) => {
  const branchWithUpdatedAttributes = [...changedAttributes].reduce(
    (combined, attribute) => ({
      ...combined,
      [attribute]: updatedBranch[attribute],
    }),
    {}
  );

  const {vehicle_insurance_policy, ...branch} =
    branchWithUpdatedAttributes || {};

  const shouldUpdateBranch = !!branchWithUpdatedAttributes;
  const shouldUpdateBranchAddress = didBranchAddressChange(
    originalBranch,
    updatedBranch
  );
  const shouldUpdateInsurancePolicy = !!vehicle_insurance_policy;

  if (
    !shouldUpdateBranch &&
    !shouldUpdateBranchAddress &&
    !shouldUpdateInsurancePolicy
  )
    return originalBranch;

  if (shouldUpdateBranchAddress) {
    const geoLocation = await geocodeApi({
      key: getGoogleMapApiKey(),
      address: prepareAddress({
        address: branch.street_address1,
        city: branch.city,
        zip: branch.zip,
        state: branch.state,
      }),
    });
    branch.gps_location = generateLocationObject(getLocation(geoLocation));
  }

  const savedBranch = await (shouldUpdateBranch
    ? updateBranchApi(originalBranch.id, branch)
    : {...originalBranch, ...branch});

  const saveInsurancePolicy = () =>
    !shouldUpdateInsurancePolicy
      ? originalBranch.vehicle_insurance_policy
      : !!vehicle_insurance_policy?.id
      ? updateInsurancePolicyApi(
          vehicle_insurance_policy.id,
          vehicle_insurance_policy
        )
      : createInsurancePolicyApi({
          ...vehicle_insurance_policy,
          sally_branch: savedBranch.id,
        });

  const savedInsurancePolicy = await saveInsurancePolicy();

  const compiledBranch = {
    ...savedBranch,
    operationstimerule: originalBranch.operationstimerule,
    vehicle_insurance_policy: savedInsurancePolicy,
  };

  branchUpdatedEvent.publish(compiledBranch);

  return compiledBranch;
};

export default saveChangedBranchAttributesService;
