// components
import Button3D from '@matthahn/sally-ui/lib/components/Button3D/Button3D';
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// layout components
import StepFooter from '../../../layout/components/StepFooter/StepFooter';

// local components
import Content from './components/Content';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Fragment} from 'react';

const DealershipSaveStep = ({Container, onBack, onSave, loading}) => (
  <Fragment>
    <Container padding="none">
      <Content>
        <Button3D loading={loading} onClick={onSave}>
          Save
        </Button3D>
      </Content>
    </Container>
    <Container>
      <StepFooter
        left={
          <Button size="small" theme="grey" onClick={onBack} disabled={loading}>
            Back
          </Button>
        }
      />
    </Container>
  </Fragment>
);

DealershipSaveStep.propTypes = {
  Container: PropTypes.any,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onSave: PropTypes.func,
};

export default DealershipSaveStep;
