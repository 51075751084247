// payment lib
import parseTypeName from './parseTypeName.lib.payment';

const parseCreditTypes = (creditTypes) =>
  [...creditTypes].map((type) => ({
    value: type,
    label: parseTypeName(type),
  }));

export default parseCreditTypes;
