// components
import {FullScreenLoader} from '@matthahn/sally-ui';

// driver api
import getMvrViolationChoicesApi from '@matthahn/sally-fn/lib/driver/api/getMvrViolationChoices.api.driver';

// driver redux actions
import {set as setAction} from '../../../components/containers/templates/CustomerEditContainer/actions';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

class MvrViolationLoaderContainer extends Component {
  static propTypes = {
    children: PropTypes.node,
    loadingMvrViolationChoices: PropTypes.bool,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadMvrViolationChoices();
  }

  loadMvrViolationChoices = async () => {
    const {dispatch} = this.props;

    this.setState({loading: true});
    dispatch(setAction({loadingMvrViolationChoices: true}));

    try {
      const mvrViolationChoices = await getMvrViolationChoicesApi();
      dispatch(
        setAction({loadingMvrViolationChoices: false, mvrViolationChoices})
      );
      this.setState({loading: false});
    } catch (error) {
      dispatch(setAction({loadingMvrViolationChoices: false}));
      this.setState({loading: false});
    }
  };

  render() {
    const {children} = this.props;
    const {loading} = this.state;
    return !loading ? children : <FullScreenLoader />;
  }
}

export default connect((state) => ({
  loadingMvrViolationChoices: state.customer.loadingMvrViolationChoices,
}))(MvrViolationLoaderContainer);
