import styled from 'styled-components';

// Libs
import {lib} from '@matthahn/sally-ui';

// Colors
const {
  colors: {white, red},
} = lib;

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  background: ${({live}) => (live ? white : red)};
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
`;

export default Container;
