import React from 'react';
import PropTypes from 'prop-types';

// local components
import Container from './components/Container';
import Content from './components/Content';
import Heading from './components/Heading';
import Icon from './components/Icon';
import Processes from './components/Processes';
import Row from './components/Row';

// helpers
const statusIcon = ({status}) => {
  const statuses = {
    processing: 'loading mdi-spin',
    completed: 'check',
    failed: 'close',
  };
  return statuses?.[status] || statuses.processing;
};

const OcrIndicatorList = ({visible, processing, onClose}) => (
  <Container visible={visible}>
    <Content visible={visible} onClose={onClose}>
      <Heading>
        Image reading progress
        <i className="mdi mdi-close" onClick={onClose} />
      </Heading>
      <Processes>
        {!processing?.length && (
          <Row>
            <Icon>
              <i className="mdi mdi-close" />
            </Icon>
            No image reading in progress
          </Row>
        )}
        {processing.map((process) => (
          <Row key={`${process.fileName}${process.date}`}>
            <Icon status={process.status}>
              <i
                className={`mdi mdi-${statusIcon({status: process.status})}`}
              />
            </Icon>
            {process.fileName}
          </Row>
        ))}
      </Processes>
    </Content>
  </Container>
);

OcrIndicatorList.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  processing: PropTypes.array,
};

export default OcrIndicatorList;
