// api query
import {isNull, greaterThan} from '../../../api/queries/queries';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import {Component} from 'react';

// redux
import {connect} from 'react-redux';

// ticket actions
import {set as setAction} from '../../redux/actions';

// ticket api
import listTicketsApi from '@matthahn/sally-fn/lib/ticket/api/list.api.ticket';

// ticket events
import multipleTicketsUpdatedEvent from '../../events/multipleUpdated.event.ticket';
import * as ticketCreatedEvent from '@matthahn/sally-fn/lib/ticket/events/created.event.ticket';
import * as ticketDeletedEvent from '@matthahn/sally-fn/lib/ticket/events/deleted.event.ticket';
import * as ticketUpdatedEvent from '@matthahn/sally-fn/lib/ticket/events/updated.event.ticket';

class NoOfImportantTicketsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    subscribe: PropTypes.func,
  };

  componentDidMount() {
    this.loadTickets();
    this.props.subscribe(
      multipleTicketsUpdatedEvent.subscribe(this.loadTickets),
      ticketCreatedEvent.sub(this.loadTickets),
      ticketDeletedEvent.sub(this.loadTickets),
      ticketUpdatedEvent.sub(this.loadTickets)
    );
  }

  loadTickets = async () => {
    const {dispatch} = this.props;
    try {
      const {count: noOfImportantTickets} = await listTicketsApi({
        limit: 1,
        assigned_to_company: false,
        [isNull('driver')]: true,
        [isNull('charge')]: true,
        [greaterThan('amount_due')]: '0',
      });
      dispatch(setAction({noOfImportantTickets}));
    } catch (error) {
      // do nothing
    }
  };

  render() {
    return null;
  }
}

export default subscriptionHOC(connect()(NoOfImportantTicketsContainer));
