import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// Components
import {Range} from '@matthahn/sally-ui';
// import ImagePreview from '../ImagePreview/ImagePreview';
import RangeWrap from './components/RangeWrap';
import SignedSignatureContainer from './components/SignedSignatureContainer';
import SignedSignature from './components/SignedSignature';

// gas components
import Section from '../Section/Section';
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class Launch extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  change = () => {};

  render() {
    const {section, gas, vehicle} = this.props;

    return (
      <Section gas={gas} section={section} flat>
        <GasSectionRow title="License plate" noBorder>
          {vehicle.plate}
        </GasSectionRow>
        <GasSectionRow title="Fuel Amount">
          <RangeWrap>
            <Range
              min={0}
              max={100}
              treshold={500}
              step={25}
              value={gas.fuel_amount * 1}
              onChange={this.change}
              disabled
            />
          </RangeWrap>
        </GasSectionRow>
        <GasSectionRow title="Driver Notes">
          {gas.user_notes || '-'}
        </GasSectionRow>
        <GasSectionRow title="Signature">
          <SignedSignatureContainer>
            <SignedSignature src={gas.signature} />
          </SignedSignatureContainer>
        </GasSectionRow>
      </Section>
    );
  }
}

export default withRouter(connect()(Launch));
