import styled from 'styled-components';

// colors
import {grey} from '@matthahn/sally-ui/lib/libs/colors';

// const
const SIZE = '65%';

const FileContainer = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
  height: 100%;
  border-left: 1px solid ${grey};
  position: relative;
`;

export default FileContainer;
