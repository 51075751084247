import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// changeOrder containers
import ChangeOrderActionsContainer from '../../../../changeOrder/containers/ChangeOrderActionsContainer/ChangeOrderActionsContainer';
import SignChangeOrderContractContainer from '../../../../changeOrder/containers/SignChangeOrderContractContainer/SignChangeOrderContractContainer';

// dealership containers
import DealershipFetchContainer from '../../../../dealership/containers/DealershipFetchContainer/DealershipFetchContainer';

// Components
import {NavigationContainer} from '../../templates/NavigationContainer';

// Containers
import {DocBundleManagerContainer} from '../../../containers/organisms/DocBundleManagerContainer';
import NewAccidentFlowContainer from '../../../../accident/containers/NewAccidentFlowContainer/NewAccidentFlowContainer';
import UsersFetchContainer from '../../../../user/containers/UsersFetchContainer/UsersFetchContainer';
import BranchFetchContainer from '../../../../branch/containers/BranchFetchContainer/BranchFetchContainer';
import SpotlightContainer from '../../../../spotlight/containers/SpotlightContainer/SpotlightContainer';
import NumberOfTollsSync from '../../../../toll/containers/NumberOfTollsSync/NumberOfTollsSync';

// branch containers
import BranchEditorContainer from '../../../../branch/containers/BranchEditorContainer/BranchEditorContainer';
import BranchGeneratorContainer from '../../../../branch/containers/BranchGeneratorContainer/BranchGeneratorContainer';
import BranchListenerContainer from '../../../../branch/containers/BranchListenerContainer/BranchListenerContainer';
import RentalFeesEditorContainer from '../../../../branch/containers/RentalFeesEditorContainer/RentalFeesEditorContainer';
import SelectBranchContainer from '../../../../branch/containers/SelectBranchContainer/SelectBranchContainer';
import WorkingHoursEditorContainer from '../../../../branch/containers/WorkingHoursEditorContainer/WorkingHoursEditorContainer';

// changeOrder containers
import CreateChangeOrderContainer from '../../../../changeOrder/containers/CreateChangeOrderContainer/CreateChangeOrderContainer';

// contract container
import ContractGeneratorContainer from '../../../../contract/containers/ContractGeneratorContainer/ContractGeneratorContainer';
import SelectContractContainer from '../../../../contract/containers/SelectContractContainer/SelectContractContainer';

// dealership containers
import DealershipEditorContainer from '../../../../dealership/containers/DealershipEditorContainer/DealershipEditorContainer';
import DealershipGeneratorContainer from '../../../../dealership/containers/DealershipGeneratorContainer/DealershipGeneratorContainer';
import DealershipListenerContainer from '../../../../dealership/containers/DealershipListenerContainer/DealershipListenerContainer';

// driver container
import MvrViolationLoaderContainer from '../../../../driver/containers/MvrViolationLoaderContainer/MvrViolationLoaderContainer';
import PickDriverContainer from '../../../../driver/containers/PickDriverContainer/PickDriverContainer';
import SelectDriverContainer from '../../../../driver/containers/SelectDriverContainer/SelectDriverContainer';

// gas containers
import GasContainer from '../../../../gas/containers/GasContainer/GasContainer';

// mileagePackage containers
import MileagePackagesFetchContainer from '../../../../mileagePackage/containers/MileagePackagesFetchContainer/MileagePackagesFetchContainer';

// payment containers
import PaymentTypesFetchContainer from '../../../../payment/containers/PaymentTypesFetchContainer/PaymentTypesFetchContainer';

// paymentGroup containers
import AddPaymentGroupContainer from '../../../../paymentGroup/containers/AddPaymentGroupContainer/AddPaymentGroupContainer';

// paymentPlan containers
import PaymentPlanEditorContainer from '../../../../paymentPlan/containers/PaymentPlanEditorContainer/PaymentPlanEditorContainer';

// permission containers
import PermissionsContainer from '../../../../permission/containers/PermissionsContainer/PermissionsContainer';

// reducedDeductible containers
import ReducedDeductibleOptionsFetchContainer from '../../../../reducedDeductible/containers/ReducedDeductibleOptionsFetchContainer/ReducedDeductibleOptionsFetchContainer';

// rental containers
import AppRentalLaunchContainer from '../../../../rental/containers/AppRentalLaunchContainer/AppRentalLaunchContainer';
import RepoRentalContainer from '../../../../rental/containers/RepoRentalContainer/RepoRentalContainer';

// samson containers
import SamsonTicketContainer from '../../../../samson/containers/SamsonTicketContainer/SamsonTicketContainer';

// termsOfService containers
import LatestTosContainer from '../../../../termsOfService/containers/LatestTosContainer/LatestTosContainer';

// ticket containers
import CreateTicketContainer from '../../../../ticket/containers/CreateTicketContainer/CreateTicketContainer';
import NoOfImportantTicketsContainer from '../../../../ticket/containers/NoOfImportantTicketsContainer/NoOfImportantTicketsContainer';
import TicketRequirementsLoaderContainer from '../../../../ticket/containers/TicketRequirementsLoaderContainer/TicketRequirementsLoaderContainer';
import TicketSummaryContainer from '../../../../ticket/containers/TicketSummaryContainer/TicketSummaryContainer';

// vehicle containers
import VehicleTypeFetchContainer from '../../../../vehicle/containers/VehicleTypeFetchContainer/VehicleTypeFetchContainer';
import VehicleEventTrackerContainer from '../../../../vehicle/containers/VehicleEventTrackerContainer/VehicleEventTrackerContainer';

// vehicleHold containers
import PutVehicleOnHoldContainer from '../../../../vehicleHold/containers/PutVehicleOnHoldContainer/PutVehicleOnHoldContainer';
import ReleaseVehicleFromHoldContainer from '../../../../vehicleHold/containers/ReleaseVehicleFromHoldContainer/ReleaseVehicleFromHoldContainer';

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  padding-top: 120px;
`;

const Layout = ({children}) => (
  <BranchFetchContainer>
    <DealershipFetchContainer>
      <PaymentTypesFetchContainer>
        <VehicleTypeFetchContainer>
          <ReducedDeductibleOptionsFetchContainer>
            <MileagePackagesFetchContainer>
              <TicketRequirementsLoaderContainer>
                <MvrViolationLoaderContainer>
                  <LatestTosContainer>
                    <Container>
                      <NavigationContainer />
                      <Content>{children}</Content>
                      <NewAccidentFlowContainer />
                      <DocBundleManagerContainer />
                      <UsersFetchContainer />
                      <NumberOfTollsSync />
                      <PickDriverContainer />
                      <GasContainer />
                      <PutVehicleOnHoldContainer />
                      <ReleaseVehicleFromHoldContainer />
                      <VehicleEventTrackerContainer />
                      <AddPaymentGroupContainer />
                      <CreateChangeOrderContainer />
                      <SamsonTicketContainer />
                      <RepoRentalContainer />
                      <SelectContractContainer />
                      <BranchGeneratorContainer />
                      <BranchEditorContainer />
                      <DealershipGeneratorContainer />
                      <DealershipEditorContainer />
                      <ContractGeneratorContainer />
                      <PermissionsContainer />
                      <AppRentalLaunchContainer />
                      <SelectBranchContainer />
                      <RentalFeesEditorContainer />
                      <WorkingHoursEditorContainer />
                      <BranchListenerContainer />
                      <ChangeOrderActionsContainer />
                      <SignChangeOrderContractContainer />
                      <DealershipListenerContainer />
                      <TicketSummaryContainer />
                      <SpotlightContainer />
                      <NoOfImportantTicketsContainer />
                      <PaymentPlanEditorContainer />
                      <CreateTicketContainer />
                      <SelectDriverContainer />
                    </Container>
                  </LatestTosContainer>
                </MvrViolationLoaderContainer>
              </TicketRequirementsLoaderContainer>
            </MileagePackagesFetchContainer>
          </ReducedDeductibleOptionsFetchContainer>
        </VehicleTypeFetchContainer>
      </PaymentTypesFetchContainer>
    </DealershipFetchContainer>
  </BranchFetchContainer>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
