import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import {getGoogleDoc} from '@matthahn/sally-fn/lib/document/api';

// Components
import {GoogleDoc} from '../../../components/molecules/GoogleDoc';

// Libs
import {lib} from '@matthahn/sally-ui';
import sallyError from '../../../../libs/sallyError';

// Parser
import * as parser from './parser';

// Helpers
const {alert} = lib;

class GoogleDocContainer extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    docID: PropTypes.string,
    data: PropTypes.object,
    html: PropTypes.string,
  };

  static defaultProps = {
    data: {},
  };

  state = {
    loading: false,
    html: null,
  };

  componentDidMount() {
    const {html} = this.props;
    this.mounted = true;
    if (!!html) this.setState({html});
    else this.getDoc();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getDoc = async () => {
    const {docID} = this.props;

    if (!this.mounted) return;
    this.setState({loading: true});

    try {
      const html = await getGoogleDoc(docID);
      if (!this.mounted) return;
      this.setState({loading: false, html: `${html}`});
    } catch (error) {
      const {message} = sallyError(error);
      alert.error(message);
      if (!this.mounted) return;
      this.setState({loading: false});
    }
  };

  render() {
    const {docID, data, loading, ...props} = this.props;
    const {loading: stateLoading, html} = this.state;
    return (
      <GoogleDoc
        loading={loading || stateLoading}
        html={html}
        data={data}
        parser={parser}
        {...props}
      />
    );
  }
}

export default GoogleDocContainer;
