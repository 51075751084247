import prepare from '@matthahn/sally-fw/lib/attribute/prepare.attribute';

export default (attributes) =>
  prepare(attributes, {
    field: 'api',
    required: [
      'policy_effective_date',
      'policy_expiration_date',
      'policy_number',
    ],
  });
