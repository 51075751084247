// Rental Rate
import currentRentalRate from '@matthahn/sally-fn/lib/rentalRate/libs/currentRentalRate.lib.rentalRate';

// Types
import {amount as amountType} from '@matthahn/sally-fn/lib/types';

// Parsers
import {raw as rentalVehiclePayment} from './rentalVehiclePayment';
import {raw as rentalVehicleSalesTax} from './rentalVehicleSalesTax';
import {raw as rentalPassengerCarSalesTax} from './rentalPassengerCarSalesTax';
import {raw as rentalMaintenancePayment} from './rentalMaintenancePayment';
import {raw as rentalMaintenanceSalesTax} from './rentalMaintenanceSalesTax';
import {raw as rentalLiabilityInsurancePayment} from './rentalLiabilityInsurancePayment';
import {raw as rentalRegistrationAndLicensingPayment} from './rentalRegistrationAndLicensingPayment';

export const raw = ({
  rental: {rates = [], reduced_deductible = false} = {},
} = {}) =>
  currentRentalRate(rates, reduced_deductible) -
  (rentalVehiclePayment({rental: {rates, reduced_deductible}}) +
    rentalVehicleSalesTax({rental: {rates, reduced_deductible}}) +
    rentalPassengerCarSalesTax({rental: {rates, reduced_deductible}}) +
    rentalMaintenancePayment({rental: {rates, reduced_deductible}}) +
    rentalMaintenanceSalesTax({rental: {rates, reduced_deductible}}) +
    rentalLiabilityInsurancePayment({rental: {rates, reduced_deductible}}) +
    rentalRegistrationAndLicensingPayment({
      rental: {rates, reduced_deductible},
    }));

export default (args) => `$${amountType(raw(args)).format()}`;
