// lib
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';

// redux
import store from '../../redux/store';

const shouldSelectMileagePackage = ({
  mileagePackages = store.getState().mileagePackage.mileagePackages,
  vehicle,
}) =>
  (!!vehicle?.sally_dealership &&
    !![...mileagePackages].find(
      ({sally_dealership}) =>
        sally_dealership === fkOrId(vehicle?.sally_dealership)
    )) ||
  (!!vehicle?.sally_branch &&
    !![...mileagePackages].find(
      ({sally_branch}) => sally_branch === fkOrId(vehicle?.sally_branch)
    ));

export default shouldSelectMileagePackage;
