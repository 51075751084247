// permission lib
import isPermission from './isPermission.lib.permission';
import permissionType from './permissionType.lib.permission';

const generateAvailablePermissions = (permissionLabels) =>
  Object.entries(permissionLabels).reduce(
    (combined, [permissionKey, permissionMeta]) =>
      isPermission({permissionKey, permissionMeta})
        ? [
            ...combined,
            {
              permission: permissionKey,
              label: permissionMeta?.help_text,
              type: permissionType({permissionKey}),
            },
          ]
        : combined,
    []
  );

export default generateAvailablePermissions;
