// document components
import OcrIndicatorButton from '../../components/OcrIndicatorButton/OcrIndicatorButton';
import OcrIndicatorList from '../../components/OcrIndicatorList/OcrIndicatorList';

// document events
import {sub as fileUploadStartedEvent} from '@matthahn/sally-fn/lib/document/events/uploadStarted.event.document';
import {sub as fileUploadFailedEvent} from '@matthahn/sally-fn/lib/document/events/uploadFailed.event.document';

// document sockets
import ocrCompletedSocket from '../../sockets/ocrCompleted.event.document';
import ocrFailedSocket from '../../sockets/ocrFailed.event.document';

// event HOCs
import subscriptionHOC from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, Fragment} from 'react';

class OcrIndicatorContainer extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
  };

  state = {
    visible: false,
    processing: [],
  };

  componentDidMount() {
    this.props.subscribe(
      fileUploadStartedEvent(this.fileUploadStarted),
      fileUploadFailedEvent(this.fileUploadFailed),
      ocrCompletedSocket.subscribe(this.ocrCompleted),
      ocrFailedSocket.subscribe(this.ocrFailed)
    );
    this.refreshInterval = setInterval(this.removeOldEntries, 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  refreshInterval = null;

  removeOldEntries = () => {
    const {processing} = this.state;
    const cutOffTime = new Date().getTime() - 1000 * 60 * 10;
    const updatedProcessing = [...processing].map((process) =>
      process.status === 'processing' && process.date <= cutOffTime
        ? {...process, status: 'failed'}
        : process
    );
    this.setState({processing: updatedProcessing});
  };

  fileUploadStarted = ({ocr, fileName}) => {
    if (!ocr) return;
    const {processing} = this.state;
    this.setState({
      visible: true,
      processing: [
        ...processing,
        {
          fileName,
          date: new Date().getTime(),
          status: 'processing',
        },
      ],
    });
  };

  fileUploadFailed = ({ocr, fileName}) => {
    if (!ocr) return;
    const {processing} = [...this.state.processing].reduce(
      (combined, current) =>
        !combined.updated &&
        current.fileName === fileName &&
        current.status === 'processing'
          ? {
              updated: true,
              processing: [
                ...combined.processing,
                {...current, status: 'failed'},
              ],
            }
          : {...combined, processing: [...combined.processing, current]},
      {processing: [], updated: false}
    );
    this.setState({processing});
  };

  ocrFailed = (document) => {
    const {processing} = [...this.state.processing].reduce(
      (combined, current) =>
        !combined.updated &&
        current.fileName === document?.name &&
        current.status === 'processing'
          ? {
              updated: true,
              processing: [
                ...combined.processing,
                {...current, status: 'failed'},
              ],
            }
          : {...combined, processing: [...combined.processing, current]},
      {processing: [], updated: false}
    );
    this.setState({processing});
  };

  ocrCompleted = (document) => {
    const {processing} = [...this.state.processing].reduce(
      (combined, current) =>
        !combined.updated &&
        current.fileName === document?.name &&
        current.status === 'processing'
          ? {
              updated: true,
              processing: [
                ...combined.processing,
                {...current, status: 'completed'},
              ],
            }
          : {...combined, processing: [...combined.processing, current]},
      {processing: [], updated: false}
    );
    this.setState({processing});
  };

  showModal = () => this.setState({visible: true});

  hideModal = () => this.setState({visible: false});

  numberOfProcesses = () =>
    [...this.state.processing].filter(({status}) => status === 'processing')
      .length;

  render() {
    const {visible, processing} = this.state;
    return (
      <Fragment>
        <OcrIndicatorButton
          numberOfProcesses={this.numberOfProcesses()}
          onClick={this.showModal}
        />
        <OcrIndicatorList
          visible={visible}
          processing={processing}
          onClose={this.hideModal}
        />
      </Fragment>
    );
  }
}

export default subscriptionHOC(OcrIndicatorContainer);
