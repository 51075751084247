const columns = [
  {
    key: 'start_date',
    label: 'Start Date',
  },
  {
    key: 'end_date',
    label: 'End Date',
  },
  {
    key: 'action',
    label: 'Action',
  },
];

export default columns;
