// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';
import Frame from '@matthahn/sally-ui/lib/components/Frame/Frame';
import Modal from '@matthahn/sally-ui/lib/components/Modal/Modal';
import Uploader from '@matthahn/sally-ui/lib/components/Uploader/Uploader';

// layout components
import {AttributeInput} from '../../../components/components/atoms/AttributeInput';
import CompactDataRow from '../../../layout/components/CompactDataRow/CompactDataRow';

// local components
import Action from './components/Action';
import ActionsRow from './components/ActionsRow';
import Block from './components/Block';
import ClearFile from './components/ClearFile';
import Container from './components/Container';
import FileContainer from './components/FileContainer';

// react
import React from 'react';

// propTypes
import PropTypes from 'prop-types';

const CreateTicketModal = ({
  addNewAfterSaving,
  due_datetime,
  file,
  fine_amount,
  license_plate,
  onChange,
  onClose,
  onSave,
  onUpload,
  payment_link,
  paymentLinkOptions,
  penalty_amount,
  processing_fee,
  saving,
  state,
  summons_number,
  violation_datetime,
  violation,
  violationOptions,
  visible,
}) => (
  <Modal
    visible={visible}
    title="New Ticket"
    onClose={onClose}
    size="full"
    headerActions={[{icon: 'upload', disabled: saving, onClick: onUpload}]}
  >
    {(Content) => (
      <Content padding="none">
        <Container>
          <Block>
            <CompactDataRow
              label={`${summons_number.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={summons_number}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${state.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={state}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${violation_datetime.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={violation_datetime}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${due_datetime.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={due_datetime}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${license_plate.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={license_plate}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${fine_amount.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={fine_amount}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${processing_fee.label.default}`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={processing_fee}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${penalty_amount.label.default}`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={penalty_amount}
                onChange={onChange}
                size="small"
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${violation.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={violation}
                onChange={onChange}
                size="small"
                options={violationOptions}
                flat
              />
            </CompactDataRow>
            <CompactDataRow
              label={`${payment_link.label.default} *`}
              wrapContent
              noPadding
            >
              <AttributeInput
                value={payment_link}
                onChange={onChange}
                size="small"
                options={paymentLinkOptions}
                flat
              />
            </CompactDataRow>
            <Content>
              <ActionsRow>
                <Action>
                  <Button
                    loading={saving && !addNewAfterSaving}
                    disabled={saving && addNewAfterSaving}
                    theme="darkGrey"
                    onClick={onSave({addNewAfterSaving: false})}
                    block
                  >
                    Save
                  </Button>
                </Action>
                <Action>
                  <Button
                    loading={saving && addNewAfterSaving}
                    disabled={saving && !addNewAfterSaving}
                    theme="black"
                    onClick={onSave({addNewAfterSaving: true})}
                    block
                  >
                    Save and new
                  </Button>
                </Action>
              </ActionsRow>
            </Content>
          </Block>
          <FileContainer>
            {!!file ? (
              <Frame src={file.preview} />
            ) : (
              <Uploader
                loading={saving}
                onChange={([value]) => onChange(value, 'file')}
              />
            )}
            {!!file && !saving && (
              <ClearFile>
                <Button
                  size="small"
                  theme="grey"
                  onClick={() => onChange(null, 'file')}
                >
                  Clear
                </Button>
              </ClearFile>
            )}
          </FileContainer>
        </Container>
      </Content>
    )}
  </Modal>
);

CreateTicketModal.propTypes = {
  addNewAfterSaving: PropTypes.bool,
  due_datetime: PropTypes.object,
  fine_amount: PropTypes.object,
  license_plate: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onUpload: PropTypes.func,
  payment_link: PropTypes.object,
  paymentLinkOptions: PropTypes.array,
  penalty_amount: PropTypes.object,
  processing_fee: PropTypes.object,
  saving: PropTypes.bool,
  state: PropTypes.object,
  summons_number: PropTypes.object,
  violation_datetime: PropTypes.object,
  violation: PropTypes.object,
  violationOptions: PropTypes.array,
  visible: PropTypes.bool,
};

export default CreateTicketModal;
