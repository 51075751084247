// Constants
import {REDUX_SET, REDUX_RESET} from './constants';

const set = (data) => ({type: REDUX_SET, data});

const reset = () => ({type: REDUX_RESET});

export const open = (rental, onComplete = () => {}) => (dispatch) => {
  dispatch(set({rental, onComplete}));
};

export const close = () => (dispatch) => dispatch(reset());
