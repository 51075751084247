// components
import Select from '@matthahn/sally-ui/lib/components/Select/Select';
import Text from '@matthahn/sally-ui/lib/components/Text/Text';

// local handlers
import selectOptionHandler from './handlers/selectOption.handler';

// local lib
import convertToOptions from './lib/convertToOptions.lib';

// propTypes
import PropTypes from 'prop-types';

// react
import React from 'react';

// reducedDeductible lib
import generateReducedDeductibleKey from '../../lib/generateKey.lib.reducedDeductible';

// redux
import {connect} from 'react-redux';

// types
import {amount as amountType} from '@matthahn/sally-fn/lib/types';

const ReducedDeductibleOptionSelect = ({
  disabled,
  label = 'Damage Waiver',
  onChange,
  options,
  readOnly = false,
  reducedDeductible,
  selectProps = {},
}) =>
  readOnly ? (
    <Text
      value={
        !!reducedDeductible
          ? `Deductible: $${amountType(
              reducedDeductible?.deductible_amount || 0
            ).format()} Rate: $${amountType(
              reducedDeductible?.rate || 0
            ).format()}`
          : '-'
      }
      {...selectProps}
    >
      {label}
    </Text>
  ) : (
    <Select
      disabled={disabled || readOnly}
      notFilterable
      onChange={selectOptionHandler({options, onChange})}
      options={convertToOptions(options)}
      value={generateReducedDeductibleKey(reducedDeductible)}
      {...selectProps}
    >
      {label}
    </Select>
  );

ReducedDeductibleOptionSelect.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  reducedDeductible: PropTypes.object,
  selectProps: PropTypes.object,
};

export default connect((state) => ({
  options: state.reducedDeductible.reducedDeductibleOptions,
}))(ReducedDeductibleOptionSelect);
