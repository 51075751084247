import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox} from '@matthahn/sally-ui';
import Section from '../Section/Section';

// gas components
import GasSectionRow from '../GasSectionRow/GasSectionRow';

class ShortTires extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  render() {
    const {section, gas} = this.props;

    return (
      <Section section={section} gas={gas} flat>
        <GasSectionRow title="Tread Depth OK" noBorder>
          <CheckBox
            size="small"
            value={gas.tires_tread_depth}
            onChange={() => {}}
          />
        </GasSectionRow>
        <GasSectionRow title="Spare Tires and Tools Present">
          <CheckBox
            size="small"
            value={gas.spare_tires_and_tools}
            onChange={() => {}}
          />
        </GasSectionRow>
      </Section>
    );
  }
}

export default ShortTires;
