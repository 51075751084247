import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

// columns
import columns from './columns';

// components
import {Modal, Table} from '@matthahn/sally-ui';
import TableRow from '@matthahn/sally-ui/lib/components/Table/components/Row.Table';
import Loader from '../../../components/components/atoms/Loader/Loader';

// libs
import displayTimeZoneSpecificDate from '../../../libs/displayTimeZoneSpecificDate';

// local components
import Bold from './components/Bold';
import Charged from './components/Charged';

// service lib
import getItemName from '../../lib/getName.lib.item';
import itemPrice from '../../lib/price.lib.item';
import isItemTaxed from '../../lib/isTaxed.lib.item';

// types
import {dateTime, amount} from '@matthahn/sally-fn/lib/types';

const SamsonTicketModal = ({
  discounts,
  downloading,
  items,
  loading,
  mechanic,
  onClose,
  onDownload,
  ticket,
  vehicle,
  visible,
}) => (
  <Modal
    title={loading ? 'Loading Samson ticket' : `Ticket #${ticket?.id}`}
    visible={visible}
    onClose={onClose}
    headerActions={
      !loading && !!ticket?.invoice_url
        ? [
            {
              icon: 'download',
              loading: downloading,
              onClick: onDownload,
              tooltip: 'Download invoice',
            },
          ]
        : []
    }
  >
    {(Content) =>
      loading ? (
        <Content padding="none">
          <Loader />
        </Content>
      ) : (
        <Fragment>
          <Content padding="none" noBorder>
            <TableRow portions={4}>
              {(TableColumn) => [
                <TableColumn key="key">Check in</TableColumn>,
                <TableColumn key="value" span={3}>
                  {displayTimeZoneSpecificDate({
                    date: ticket.created_at,
                    type: dateTime,
                    branch: vehicle?.sally_branch,
                  })}{' '}
                  by {ticket.created_by}
                </TableColumn>,
              ]}
            </TableRow>
            <TableRow portions={4}>
              {(TableColumn) => [
                <TableColumn key="key">Check out</TableColumn>,
                <TableColumn key="value" span={3}>
                  {!!ticket.approved_at
                    ? `${displayTimeZoneSpecificDate({
                        date: ticket.approved_at,
                        type: dateTime,
                        branch: vehicle?.sally_branch,
                      })} by ${ticket.approved_by || '-'}`
                    : '-'}
                </TableColumn>,
              ]}
            </TableRow>
            <TableRow portions={4}>
              {(TableColumn) => [
                <TableColumn key="key">Mileage</TableColumn>,
                <TableColumn key="value" span={3}>
                  {amount(ticket.mileage).format()}
                </TableColumn>,
              ]}
            </TableRow>
            <TableRow portions={4}>
              {(TableColumn) => [
                <TableColumn key="key">Mechanic</TableColumn>,
                <TableColumn key="value" span={3}>
                  {mechanic?.display_name || '-'}
                </TableColumn>,
              ]}
            </TableRow>
            <TableRow portions={4}>
              {(TableColumn) => [
                <TableColumn key="key">Notes</TableColumn>,
                <TableColumn key="value" span={3}>
                  {ticket.notes || '-'}
                </TableColumn>,
              ]}
            </TableRow>
          </Content>
          <Content padding="none">
            <Table columns={columns} noBorder noRadius smallNoResultsLabel>
              {(TableRow) =>
                [
                  ...items
                    .map((item) => (
                      <TableRow key={item.id}>
                        {(TableCol) => [
                          <TableCol key="quantity">{item.quantity}</TableCol>,
                          <TableCol key="name" span={4}>
                            {getItemName(item)}
                          </TableCol>,
                          <TableCol key="cost">
                            {isItemTaxed(item) && 'T '}$
                            {amount(itemPrice(item)).format()}
                          </TableCol>,
                        ]}
                      </TableRow>
                    ))
                    .flat(),
                  ...discounts
                    .map((item) => (
                      <TableRow key={item.id}>
                        {(TableCol) => [
                          <TableCol key="quantity">{item.quantity}</TableCol>,
                          <TableCol key="name" span={4}>
                            {getItemName(item)}
                          </TableCol>,
                          <TableCol key="cost">
                            $-{amount(itemPrice(item)).format()}
                          </TableCol>,
                        ]}
                      </TableRow>
                    ))
                    .flat(),
                  !!ticket?.ticket_cost_summary?.total_tax && (
                    <TableRow key="tax">
                      {(TableCol) =>
                        [
                          <TableCol key="name" span={4}></TableCol>,
                          <TableCol key="total" align="right">
                            TAX
                          </TableCol>,
                          <TableCol key="cost">
                            $
                            {amount(
                              ticket?.ticket_cost_summary?.total_tax || 0
                            ).format()}
                          </TableCol>,
                        ].filter((row) => !!row)
                      }
                    </TableRow>
                  ),
                  <TableRow key="total">
                    {(TableCol) => [
                      <TableCol key="name" span={4}></TableCol>,
                      <TableCol key="total" align="right">
                        <Bold>TOTAL</Bold>
                      </TableCol>,
                      <TableCol key="cost">
                        <Bold>
                          $
                          {amount(
                            ticket?.ticket_cost_summary?.total_amount || 0
                          ).format()}
                        </Bold>
                      </TableCol>,
                    ]}
                  </TableRow>,
                  !!ticket?.payment_method && (
                    <TableRow key="charged">
                      {(TableCol) => [
                        <TableCol key="total" span={5} align="right">
                          Payment method
                        </TableCol>,
                        <TableCol key="cost">
                          {ticket?.payment_method}
                        </TableCol>,
                      ]}
                    </TableRow>
                  ),
                  !!ticket?.external_charges?.length && (
                    <TableRow key="charged">
                      {(TableCol) => [
                        <TableCol key="total" span={5} align="right" />,
                        <TableCol key="cost">
                          <Charged>Charged</Charged>
                        </TableCol>,
                      ]}
                    </TableRow>
                  ),
                ].filter((row) => !!row)
              }
            </Table>
          </Content>
        </Fragment>
      )
    }
  </Modal>
);

SamsonTicketModal.propTypes = {
  discounts: PropTypes.array,
  downloading: PropTypes.bool,
  items: PropTypes.array,
  loading: PropTypes.bool,
  mechanic: PropTypes.object,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  ticket: PropTypes.object,
  vehicle: PropTypes.object,
  visible: PropTypes.bool,
};

export default SamsonTicketModal;
