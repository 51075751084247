import styled from 'styled-components';

// constants
const SIZE = '50%';

const ImagesColumn = styled.div`
  width: ${SIZE};
  min-width: ${SIZE};
  max-width: ${SIZE};
`;

export default ImagesColumn;
