import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {CheckBox, Range} from '@matthahn/sally-ui';
// import DamageImages from '../DamageImages/DamageImages';
// import ImagePreview from '../ImagePreview/ImagePreview';
// import Container from './components/Container';
// import Fuel from './components/Fuel';
// import FuelWrap from './components/FuelWrap';
import RangeWrap from './components/RangeWrap';
import SignedSignatureContainer from './components/SignedSignatureContainer';
import SignedSignature from './components/SignedSignature';

// Components

// gas components
import Section from '../Section/Section';
import GasSectionRow from '../GasSectionRow/GasSectionRow';

// types
import {commaSeperatedNumber} from '@matthahn/sally-fn/lib/types';

class Offboard extends Component {
  static propTypes = {
    section: PropTypes.object,
    vehicle: PropTypes.object,
    gas: PropTypes.object,
  };

  change = () => {};

  render() {
    const {gas, vehicle, section} = this.props;

    return (
      <Section gas={gas} section={section} flat>
        <GasSectionRow title="License plate" noBorder>
          {vehicle.plate}
        </GasSectionRow>
        <GasSectionRow title="Mileage">
          {!!gas.mileage ? commaSeperatedNumber(gas.mileage).format() : '-'}
        </GasSectionRow>
        <GasSectionRow title="Fuel Amount">
          <RangeWrap>
            <Range
              min={0}
              max={100}
              treshold={500}
              step={25}
              value={gas.fuel_amount * 1}
              onChange={this.change}
              disabled
            />
          </RangeWrap>
        </GasSectionRow>
        <GasSectionRow title="Verify front & rear cameras are in vehicle">
          <CheckBox size="small" value={gas.cameras} onChange={() => {}} />
        </GasSectionRow>
        <GasSectionRow title="Personal items & EZ-Pass reminder">
          <CheckBox size="small" value={gas.ezpass} onChange={() => {}} />
        </GasSectionRow>
        <GasSectionRow title="Damage Description">
          {gas.damage_description || '-'}
        </GasSectionRow>
        <GasSectionRow title="Driver Notes">
          {gas.user_notes || '-'}
        </GasSectionRow>
        <GasSectionRow title="Signature">
          <SignedSignatureContainer>
            <SignedSignature src={gas.signature} />
          </SignedSignatureContainer>
        </GasSectionRow>
        {/* <DamageImages vehicle={vehicle} gas={gas} /> */}
      </Section>
    );
  }
}

export default Offboard;
